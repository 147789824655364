import React, { useState, useRef } from 'react';
import { useTypedSelector } from '@hooks/use_typed_selector';
import { useActions } from '@hooks/use_actions';
import { IgnoreActionType } from '@state/types';
import { IgnoreTypeEnum, TriggerOriginEnum } from '@shared/enums';
import Caret from '@assets/icons/caret.svg';
import Cross from '@assets/icons/cross.svg';
import './ignore_list.scss';
import { getSessionStorage, setSessionStorage } from '@utils/storage';
import SimpleText from '@ui/simple_text/simple_text';
import Ignore from '@assets/icons/ignore.svg';
// eslint-disable-next-line
const globalIgnoreEntries = getSessionStorage('global_ignore_entries', true);
const sortIgnoreList = (ignoreEntries) => {
    return ignoreEntries.sort((a, b) => a.word.toLowerCase() > b.word.toLowerCase() ? 1 : -1);
};
const IgnoreList = () => {
    const ignoreInput = useRef(null);
    const [ignoredEntries, setIgnoredEntries] = useState(globalIgnoreEntries);
    const [ignoreWord, setIgnoreWord] = useState('');
    const [active, setActive] = useState(false);
    const { addIgnore, removeIgnored, } = useActions();
    const toggleActive = () => {
        setActive(!active);
    };
    const handleAddIgnore = () => {
        if (ignoreWord.length > 0) {
            addIgnore(TriggerOriginEnum.LIST, IgnoreTypeEnum.WORD, ignoreWord, 0, '');
            setIgnoreWord('');
        }
    };
    const addIgnoreEntry = (ignoreType, word, hitId, startIdx = 0, nodeKey = '') => {
        if (hitId.length === 0) {
            const ignoredEntryExists = ignoredEntries ? ignoredEntries.findIndex((ignoredEntry) => {
                if (ignoredEntry.word === word) {
                    return ignoredEntry;
                }
            }) : -1;
            if (ignoredEntryExists < 0) {
                const tempIgnoredEntries = ignoredEntries ?
                    [...ignoredEntries, { ignoreType, word, hitId, startIdx, nodeKey }] :
                    [{ ignoreType, word, hitId, startIdx, nodeKey }];
                setIgnoredEntries(sortIgnoreList(tempIgnoredEntries));
                setSessionStorage('global_ignore_entries', tempIgnoredEntries, true);
            }
        }
        else {
            const ignoredEntryExists = ignoredEntries ? ignoredEntries.findIndex((ignoredEntry) => {
                if (ignoredEntry.word === word && ignoredEntry.hitId === hitId) {
                    return ignoredEntry;
                }
            }) : -1;
            if (ignoredEntryExists < 0) {
                const tempIgnoredEntries = ignoredEntries ?
                    [...ignoredEntries, { ignoreType, word, hitId, startIdx, nodeKey }] :
                    [{ ignoreType, word, hitId, startIdx, nodeKey }];
                setIgnoredEntries(sortIgnoreList(tempIgnoredEntries));
            }
        }
    };
    const handleRemoveIgnore = (ignoredEntry) => {
        removeIgnored(TriggerOriginEnum.LIST, ignoredEntry.ignoreType, ignoredEntry.word, ignoredEntry.hitId);
    };
    const removeIgnore = (ignoreType, word) => {
        const tempIgnoredEntries = ignoredEntries;
        const toRemoveIndex = tempIgnoredEntries.findIndex(tempIgnoredEntry => {
            return tempIgnoredEntry.ignoreType === ignoreType && tempIgnoredEntry.word === word;
        });
        tempIgnoredEntries.splice(toRemoveIndex, 1);
        setIgnoredEntries(sortIgnoreList(tempIgnoredEntries));
        setSessionStorage('global_ignore_entries', tempIgnoredEntries, true);
    };
    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            handleAddIgnore();
            setIgnoreWord('');
        }
        else {
            setIgnoreWord(event.target.value);
        }
    };
    const { pendingIgnore, typeIgnore, dataIgnore } = useTypedSelector((store) => store.ignore);
    if (pendingIgnore && dataIgnore) {
        switch (typeIgnore) {
            case IgnoreActionType.ADD:
                addIgnoreEntry(dataIgnore.ignoreType, dataIgnore.word, '', dataIgnore.startIdx, dataIgnore.nodeKey);
                break;
            case IgnoreActionType.REMOVE:
                if (ignoredEntries) {
                    removeIgnore(dataIgnore.ignoreType, dataIgnore.word);
                }
                break;
        }
    }
    return (React.createElement("div", { className: "ignore_list w-full xl:block xss:flex xss:flex-col\n\t\t\t\txss:px-4 md:px-0 xss:items-center xss:mb-8 lg:mb-0" },
        React.createElement("button", { id: "ignored_list_toggle_button", onClick: toggleActive, className: "flex flex-row button base custom w-full xl:max-w-full xss:max-w-[90%]\n\t\t\t\t\txss:mt-0 lg:mt-4 xl:mt-0 xl:px-10 xss:px-4 xss:text-base lg:text-sm text-left border-0" },
            React.createElement("div", { className: "symbol ignore xss:ml-0 xl:ml-[-30px]" },
                React.createElement(Ignore, null)),
            "Nicht markierte Begriffe",
            React.createElement("div", { className: "symbol caret ml-auto xss:mt-3 xss:block xl:block lg:hidden" }, active ? React.createElement(Caret, { className: "open" }) : React.createElement(Caret, { className: "closed" }))),
        React.createElement("div", { id: "ignored_list", 
            // eslint-disable-next-line
            className: 'mt-2 ignored_list xl:w-full xl:max-w-full xss:w-full xss:max-w-[90%] ' + (active ? 'block' : 'hidden') },
            React.createElement("div", { className: "xss:hidden xl:block" },
                React.createElement(SimpleText, { text: "Hilfestellung:\n          Hier finden Sie alle Begriffe, die Sie f\u00FCr die \u00DCberpr\u00FCfung von Finnit ausgeblendet haben.<br/>\n          Au\u00DFerdem k\u00F6nnen Sie hier jederzeit Ihre Begriffe wieder f\u00FCr die \u00DCberpr\u00FCfung freigeben oder weitere \n\t\t\t\t\tBegriffe Ihrer Wahl hinzuf\u00FCgen.<br/>" })),
            React.createElement("input", { name: "ignoreWord", value: ignoreWord, ref: ignoreInput, onClick: () => ignoreInput.current && ignoreInput.current.focus(), type: "text", onKeyUp: handleKeyUp, className: "ignore_word_input max-w-full p-2 w-full mb-4", placeholder: "Begriff eingeben" }),
            React.createElement("button", { onClick: handleAddIgnore, className: "button base w-full max-w-full mb-4" }, "Begriff hinzuf\u00FCgen"),
            React.createElement("hr", { className: "mb-4" }),
            React.createElement("ul", { className: "cursor-pointer" }, ignoredEntries && ignoredEntries.length > 0 ?
                ignoredEntries.map((ignoredEntry, index) => {
                    return React.createElement("li", { key: index, onClick: () => handleRemoveIgnore(ignoredEntry), className: "ignored_word flex flex-row mb-2" },
                        React.createElement("span", null, ignoredEntry.word),
                        ignoredEntry.hitId && ignoredEntry.hitId.length > 0 ?
                            React.createElement("span", { className: "ml-auto" },
                                "Index:\u00A0",
                                ignoredEntry.startIdx) : '',
                        React.createElement("div", { 
                            // eslint-disable-next-line
                            className: 'symbol cross mt-auto mb-auto' + (ignoredEntry.hitId && ignoredEntry.hitId.length > 0 ? ' ml-4' : ' ml-auto') },
                            React.createElement(Cross, null)));
                })
                : React.createElement("span", { className: "ignore_list_empty cursor-default" }, "Noch keine ignorierten Begriffe")))));
};
export default IgnoreList;
