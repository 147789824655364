import { isEmptyObject } from '@utils/is_empty_object';
import { stringHash } from '@utils/string_hash';
import { $isHeadingNode, $isQuoteNode } from '@lexical/rich-text';
import { $isParagraphNode } from 'lexical';
import { changedNodesBuild, } from './changed_nodes_build';
import { nodeSubtreeBuild } from './node_subtree_build';
function handleParagraphQuoteHeading(node, childrenHashes) {
    const blocks = [];
    const changedNodes = [];
    const changedSubtree = {};
    const childKey = node.getKey();
    const childContent = node.getTextContent();
    if (childContent.replace(/\n/g, '').length > 0) {
        const childContentHash = stringHash(childContent);
        if (childrenHashes && childrenHashes[childKey]) {
            if (childrenHashes[childKey] !== childContentHash) {
                changedNodes.push(node);
                blocks.push({ text: childContent, blockKey: childKey });
                childrenHashes[childKey] = childContentHash;
                changedSubtree[childKey] = nodeSubtreeBuild(node);
            }
        }
        else {
            changedNodes.push(node);
            blocks.push({ text: childContent, blockKey: childKey });
            childrenHashes[childKey] = childContentHash;
            changedSubtree[childKey] = nodeSubtreeBuild(node);
        }
    }
    return {
        changedNodes,
        changedSubtree,
        blocks,
        childrenHashes,
    };
}
export function finnitAnalyzationPrepare(root, childrenHashes) {
    const blocks = [];
    const changedNodes = [];
    let changedSubtree = {};
    let changedNodeBuild;
    root.getChildren().forEach((child) => {
        if ($isParagraphNode(child) || $isQuoteNode(child) || $isHeadingNode(child)) {
            changedNodeBuild = handleParagraphQuoteHeading(child, childrenHashes);
        }
        else {
            changedNodeBuild = changedNodesBuild(child, childrenHashes);
        }
        if (changedNodeBuild.changedNodes.length > 0)
            changedNodes.push(...changedNodeBuild.changedNodes);
        if (changedNodeBuild.blocks.length > 0)
            blocks.push(...changedNodeBuild.blocks);
        if (!isEmptyObject(changedNodeBuild.changedSubtree)) {
            changedSubtree = {
                ...changedSubtree,
                ...changedNodeBuild.changedSubtree,
            };
        }
        if (!isEmptyObject(changedNodeBuild.childrenHashes)) {
            childrenHashes = {
                ...childrenHashes,
                ...changedNodeBuild.childrenHashes,
            };
        }
    });
    return {
        childrenHashes,
        changedNodes,
        changedSubtree,
        blocks,
    };
}
