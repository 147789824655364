import { ToolbarActionType } from '@state/types';
const initialState = {
    toolbarAction: ToolbarActionType.FINISHED,
};
const toolbarReducer = (state = initialState, action = { type: ToolbarActionType.UNDO }) => {
    switch (action.type) {
        case ToolbarActionType.UNDO:
        case ToolbarActionType.REDO:
        case ToolbarActionType.COPY:
        case ToolbarActionType.CLEAR:
        case ToolbarActionType.MIC:
        case ToolbarActionType.FINISHED:
            return { toolbarAction: action.type };
        default:
            return state;
    }
};
export default toolbarReducer;
