export function nodeSubtreeBuild(node) {
    let startIdx = 0;
    let endIdx = 0;
    const subtree = [];
    if (node.getAllTextNodes) {
        node.getAllTextNodes().map((textNode) => {
            const textContentSize = textNode.getTextContentSize();
            endIdx += textContentSize;
            subtree.push({
                startIdx,
                endIdx,
                key: textNode.getKey(),
            });
            startIdx += textContentSize;
        });
    }
    return subtree;
}
