import React from 'react';
import parse from 'html-react-parser';
import './simple_text.scss';
const SimpleText = (props) => {
    const additionalClassNames = (props.quotes ? ' quotes' : '') + (props.bold ? ' font-semibold' : '');
    if (props.color && props.color.length > 0) {
        return (React.createElement("p", { style: { color: props.color }, className: 'xss:text-[15px] simple_text' + additionalClassNames }, parse(props.text)));
    }
    else {
        return (React.createElement("p", { className: 'xss:text-[15px] simple_text' + additionalClassNames }, parse(props.text)));
    }
};
export default SimpleText;
