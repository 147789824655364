import { DeviceTypeEnum } from '@shared/enums';
function useCurrentDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return DeviceTypeEnum.TABLET;
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return DeviceTypeEnum.MOBILE;
    }
    return DeviceTypeEnum.DESKTOP;
}
export { useCurrentDeviceType };
