import React, { useState } from 'react';
import { useTypedSelector } from '@hooks/use_typed_selector';
import { useActions } from '@hooks/use_actions';
import HitDetail from '@ui/hit_detail/hit_detail';
import HitList from '@ui/hit_list/hit_list';
import FinnitChecksUpsideDown from '@assets/icons/finnit_checks_upsidedown.svg';
import FinnitLogo from '@assets/logo.svg';
import Box, { BoxMode } from '@ui/box/box';
import './left_sidebar.scss';
import Dots from '@ui/loading/dots/dots';
import NestedSpinner from '@ui/loading/nested_spinner/nested_spinner';
import { useCurrentDimensions } from '@utils/dimensions';
import { config } from '@config/config';
import { scrollToElement, scrollToElementIfNeeded } from '@utils/scroll_to';
import { setSessionStorage } from '@utils/storage';
import { filterHits } from '@utils/block_hit';
import { HitDetailTypeEnum, SidebarTypeEnum, TriggerOriginEnum, HitFilterEnum } from '@shared/enums';
import { ToolbarActionType } from '@state/types';
import { getDOMNodeByKey } from '../editor/utils/get_dom_node_by_key';
const LeftSidebar = () => {
    const [analyzerRequested, setAnalyzerRequested] = useState(false);
    const [visibleHitsLength, setVisibleHitsLength] = useState(0);
    const [hitDetail, setHitDetail] = useState();
    const [hitDetailIndex, setHitDetailIndex] = useState(0);
    const [hitDetailId, setHitDetailId] = useState('');
    const [hits, setHits] = useState();
    const [visibleHits, setVisibleHits] = useState();
    const [onceLoaded, setOnceLoaded] = useState(false);
    const [hideHitDetail, setHideHitDetail] = useState(false);
    const dimensions = useCurrentDimensions();
    const { triggerDemoText, toggleSidebar, updateHits, doneHits, } = useActions();
    // eslint-disable-next-line
    const handleDemoClick = (event) => {
        event.stopPropagation();
        triggerDemoText();
    };
    const recalculateHits = (hitsForRecalculate) => {
        if (hitDetail) {
            const calculatedIndex = hitsForRecalculate.findIndex((dataHit) => dataHit.block_key === hitDetail.block_key && dataHit.start_idx === hitDetail.start_idx);
            setHitDetailId(hitDetail.hit_id);
            setHitDetailIndex(calculatedIndex === -1 ? hitDetailIndex + calculatedIndex : calculatedIndex);
        }
    };
    const recalculateHitDetailIndex = (dataHit, recalculatedVisibleHits) => {
        if (recalculatedVisibleHits && recalculatedVisibleHits.length > 0) {
            let currentIndex = 0;
            recalculatedVisibleHits.forEach((recalculatedVisibleHit) => {
                if (recalculatedVisibleHit.block_key === dataHit.block_key &&
                    recalculatedVisibleHit.start_idx === dataHit.start_idx &&
                    dataHit.ignore !== true) {
                    setHitDetailIndex(currentIndex);
                }
                if (recalculatedVisibleHit.ignore !== true) {
                    currentIndex += 1;
                }
            });
        }
        else if (hits && hits.length > 0) {
            let currentIndex = 0;
            hits.forEach((hit) => {
                if (hit.block_key === dataHit.block_key &&
                    hit.start_idx === dataHit.start_idx &&
                    dataHit.ignore !== true) {
                    setHitDetailIndex(currentIndex);
                }
                if (hit.ignore !== true) {
                    currentIndex += 1;
                }
            });
        }
        else {
            setHitDetailIndex(0);
        }
    };
    const recalculateHitsLength = (hitsForLength) => {
        return filterHits(hitsForLength, HitFilterEnum.IGNORE_NEW).length;
    };
    const recalculateVisibleHits = (hitsToFilter) => {
        return filterHits(hitsToFilter, HitFilterEnum.IGNORE_NEW);
    };
    const { toolbarAction } = useTypedSelector((store) => store.toolbar);
    if (toolbarAction && toolbarAction != ToolbarActionType.FINISHED) {
        switch (toolbarAction) {
            case ToolbarActionType.UNDO:
                break;
            case ToolbarActionType.REDO:
                break;
            case ToolbarActionType.COPY:
                break;
            case ToolbarActionType.CLEAR:
                updateHits([]);
                setVisibleHitsLength(0);
                setHits([]);
                setHitDetail(null);
                setHitDetailIndex(0);
                setHitDetailId('');
                setVisibleHits([]);
                break;
        }
    }
    if (visibleHits && visibleHitsLength > 0) {
        const length = recalculateHitsLength(visibleHits);
        if (length === 0) {
            setHits([]);
            setHitDetail(null);
            setHitDetailIndex(0);
            setHitDetailId('');
        }
        setVisibleHitsLength(length);
    }
    const { pendingHitDetail, typeHitDetail, dataHitDetail, triggerHitDetail } = useTypedSelector((store) => store.hitDetail);
    if (pendingHitDetail) {
        if (dataHitDetail && JSON.stringify(dataHitDetail) != JSON.stringify(hitDetail)) {
            setHitDetail(dataHitDetail);
            recalculateHitDetailIndex(dataHitDetail);
            setHitDetailId(dataHitDetail.hit_id);
        }
        else if (typeHitDetail === HitDetailTypeEnum.CURRENT) {
            document.querySelectorAll('.lexical-holylight.active')?.forEach((holylightActive) => {
                holylightActive.classList.remove('active');
            });
            if (hits) {
                let newHitWasSet = false;
                let lastHit;
                let lastIndex = 0;
                for (const [currentIndex, currentHit] of hits.entries()) {
                    if (currentHit.ignore !== true && currentIndex !== hitDetailIndex) {
                        lastHit = currentHit;
                        lastIndex = currentIndex;
                    }
                    if (currentHit.ignore !== true && currentIndex >= hitDetailIndex && currentHit !== hitDetail) {
                        setHitDetail(currentHit);
                        recalculateHitDetailIndex(currentHit);
                        setHitDetailId(currentHit.hit_id);
                        setSessionStorage('active_hit', currentHit.hit_id);
                        newHitWasSet = true;
                        break;
                    }
                }
                if (!newHitWasSet && lastHit) {
                    setHitDetail(lastHit);
                    setHitDetailIndex(lastIndex);
                    setHitDetailId(lastHit.hit_id);
                }
                setVisibleHits(recalculateVisibleHits(hits));
            }
        }
    }
    const { requestedHits, dataHits } = useTypedSelector((store) => store.hits);
    if (requestedHits) {
        if (dataHits && dataHits.length > 0 && JSON.stringify(dataHits) != JSON.stringify(hits)) {
            setHits(dataHits);
            recalculateHits(dataHits);
            const recalculatedVisibleHits = recalculateVisibleHits(dataHits);
            setVisibleHits(recalculatedVisibleHits);
            setVisibleHitsLength(recalculateVisibleHits.length);
            for (const [currentIndex, currentHit] of recalculatedVisibleHits.entries()) {
                if (currentHit.ignore !== true && currentIndex >= hitDetailIndex) {
                    setHitDetail(currentHit);
                    recalculateHitDetailIndex(currentHit, recalculatedVisibleHits);
                    setHitDetailId(currentHit.hit_id);
                    const element = getDOMNodeByKey(currentHit.block_key);
                    if (triggerHitDetail === TriggerOriginEnum.EDITOR) {
                        scrollToElementIfNeeded('element', element);
                    }
                    else {
                        scrollToElement('element', element);
                    }
                    if (element) {
                        setTimeout(() => {
                            const holylightElement = element?.querySelector('.lexical-holylight');
                            holylightElement?.classList.add('active', 'first');
                        }, 0);
                    }
                    setSessionStorage('active_hit', currentHit.hit_id);
                    break;
                }
            }
        }
        else if (dataHits !== null &&
            dataHits.length === 0 &&
            JSON.stringify(dataHits) != JSON.stringify(hits)) {
            setHits([]);
            setHitDetail(null);
            setHitDetailIndex(0);
            setHitDetailId('');
            setVisibleHitsLength(0);
            setVisibleHits([]);
        }
        doneHits();
    }
    const { loading } = useTypedSelector((store) => store.analyzer);
    let analyzeInfo = React.createElement("div", { className: "flex flex-col" },
        React.createElement("div", { className: 'flex flex-row ' + (hideHitDetail === true ? 'hidden' : 'block') },
            "Scannen",
            React.createElement(Dots, null)),
        React.createElement(Box, { mode: BoxMode.LOADING }),
        React.createElement("div", { id: "left_sidebar_info", className: "left_info flex flex-col" },
            React.createElement("p", { className: "mt-10 mb-4 leading-7" },
                React.createElement("span", { className: "font-bold" }, "Der \u00DCberpr\u00FCfungsvorgang ist gestartet"),
                React.createElement("br", null),
                React.createElement("br", null),
                "Finnit pr\u00FCft Ihre Stellenanzeige nun auf nicht-genderkonforme und falsch-gegenderte Begriffe."),
            React.createElement(NestedSpinner, null)));
    if (loading) {
        if (!onceLoaded) {
            setOnceLoaded(true);
        }
        setAnalyzerRequested(true);
    }
    else if (analyzerRequested) {
        if (visibleHits && visibleHitsLength > 0 && hitDetail && hitDetail.word) {
            analyzeInfo = React.createElement(React.Fragment, null,
                "Treffer ",
                hitDetailIndex + 1,
                " von ",
                visibleHitsLength);
        }
        else {
            analyzeInfo = React.createElement("div", { className: "flex flex-col" },
                "Keine Treffer gefunden",
                React.createElement("br", null),
                React.createElement(Box, { mode: BoxMode.CORRECT }));
        }
    }
    else {
        analyzeInfo = React.createElement("div", { className: "lg:flex lg:flex-col xss:hidden" },
            "\u00A0",
            React.createElement("br", null),
            React.createElement(Box, { mode: BoxMode.CORRECT }));
    }
    const heightSmartphone = dimensions.width < config.WIDTH_BREAKPOINT_PHONE ? 'h-[8rem]' : 'h-[10rem]';
    const heightTablet = dimensions.height > config.HEIGHT_BREAKPOINT_TABLET ? 'h-[16rem]' : heightSmartphone;
    const height = dimensions.height > config.HEIGHT_BREAKPOINT ? 'h-[18rem]' : heightTablet;
    if (dimensions.width < 1024) {
        setHideHitDetail(true);
    }
    else {
        setHideHitDetail(false);
    }
    const leftSidebarElement = document.getElementById('left_sidebar');
    if (leftSidebarElement && dimensions.width < 1024) {
        if (onceLoaded && visibleHitsLength === 0 && !loading) {
            if (!leftSidebarElement.classList.contains('hide')) {
                leftSidebarElement.classList.toggle('hide');
            }
        }
        else if (onceLoaded && visibleHitsLength > 0) {
            if (leftSidebarElement.classList.contains('hide')) {
                leftSidebarElement.classList.toggle('hide');
            }
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: () => toggleSidebar(SidebarTypeEnum.LEFT), className: "click_panel subtitle xss:block lg:hidden" }),
        React.createElement("div", { className: "left_sidebar lg:block xss:flex xss:flex-col fixed 3xl:ml-20\n\t\t\t\t2xl:ml-6 xl:ml-5 lg:ml-6 lg:w-[288px] md:max-w-[96%] md:w-[96%] m:max-w-[95%]\n\t\t\t\tm:w-[95%] s:max-w-[94%] s:w-[94%] xs:w-[93%] xs:max-w-[93%] xss:w-[91%] xss:max-w-[91%]" },
            React.createElement("a", { href: "/" },
                React.createElement(FinnitLogo, { className: "logo mt-8 xss:hidden lg:block" })),
            React.createElement("div", { className: 'left_sidebar subtitle ' + (hideHitDetail === true ? '' : 'mt-6') }, hideHitDetail && !loading ? '' : analyzeInfo),
            loading ? '' :
                // eslint-disable-next-line
                React.createElement("div", { className: (onceLoaded && hideHitDetail === true ? 'hidden' : 'block') + ' lg:bg-default left_sidebar_box xss:bg-white lg:bg-transparent pt-4 ' + height }, onceLoaded ?
                    React.createElement("div", { className: "left_detail" }, hitDetail && hitDetail.word && hideHitDetail === false ?
                        React.createElement(HitDetail, { hit: hitDetail }) : '') :
                    React.createElement("div", { id: "left_sidebar_info", className: "left_info flex flex-col" },
                        React.createElement("p", { id: "left_sidebar_info_text", className: "mt-1 mb-4 leading-7" },
                            "Willkommen bei Finnit!",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "Klicken Sie in den Editor um Ihre Stellenanzeige zu formulieren.",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "Finnit liest Ihre Stellenanzeige in Echtzeit und bietet Ihnen Individuelle Vorschl\u00E4ge an um Ihren Text Genderkonfom zu gestalten.",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "Keine Stellenanzeige parat? Alternativ k\u00F6nnen Sie mit einem Demotext loslegen und Finnit kennenlernen. Mit dem Button unten k\u00F6nnen Sie diesen in den Editor einbetten."),
                        React.createElement("a", { id: "left_sidebar_info_button", className: "button base mb-2 w-full", onClick: handleDemoClick },
                            React.createElement("span", null, "Mit Demotext loslegen")),
                        React.createElement("div", { className: "symbol checks_upside_down xss:hidden 2xl:block" },
                            React.createElement(FinnitChecksUpsideDown, { className: "ml-9 mt-5 w-44 h-44" })))),
            !loading && visibleHits && visibleHitsLength > 0 ?
                /* eslint-disable-next-line */
                React.createElement("div", { className: 'left_sidebar_box xss:bg-white lg:bg-transparent mb-2 ' + (dimensions.height > config.HEIGHT_BREAKPOINT ? 'h-[26rem]' : dimensions.height > config.HEIGHT_BREAKPOINT_TABLET ? 'h-[20rem]' : 'h-[18rem]') },
                    React.createElement("div", { className: 'left_sidebar subtitle ' + (dimensions.height > config.HEIGHT_BREAKPOINT_TABLET ? 'mt-4' : 'mt-2') }, visibleHits && visibleHitsLength > 0 ? React.createElement("div", { className: "box" },
                        "Gefundene Begriffe: ",
                        visibleHitsLength) : ''),
                    React.createElement("div", { className: 'left_sidebar_box xss:bg-white lg:bg-transparent overflow-hidden mt-2 ' + (dimensions.height > config.HEIGHT_BREAKPOINT ? 'h-full' : 'h-[20rem]') },
                        React.createElement("div", { className: "left_action" }, visibleHits && visibleHitsLength > 0 ?
                            React.createElement(HitList, { hits: visibleHits, activeId: hitDetailId }) : ''))) : '')));
};
export default LeftSidebar;
