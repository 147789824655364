import React from 'react';
import { useActions } from '@hooks/use_actions';
import './replacement.scss';
import { PreparationTypeEnum, TriggerOriginEnum, TooltipTriggerEnum } from '@shared/enums';
const Replacement = (props) => {
    const { triggerPreparation, toggleTooltip } = useActions();
    const handleClick = () => {
        toggleTooltip(TooltipTriggerEnum.CLOSE, {
            nodeKey: props.nodeKey,
            domElement: props.domElement,
            hit: props.hit,
        });
        triggerPreparation(PreparationTypeEnum.REPLACE, {
            tooltip: props.tooltip ? TriggerOriginEnum.TOOLTIP : TriggerOriginEnum.DETAIL,
            replacementId: props.replacementId,
            replacementString: props.replacementString,
            nodeKey: props.nodeKey,
            blockKey: props.hit.block_key,
            wordId: props.hit.word_id,
            hitId: props.hit.hit_id,
        });
    };
    const additionalClassNames = (props.tooltip ? ' in_tooltip' : '') +
        (props.additionalClassname ? ' ' + props.additionalClassname : '');
    return (React.createElement("li", { contentEditable: false, onClick: handleClick, className: 'replacement' + additionalClassNames },
        React.createElement("span", { className: "ml-6" }, props.replacementString.length > 40 && !props.tooltip
            ? props.replacementString.substring(0, 40) + '...' : props.replacementString)));
};
export default Replacement;
