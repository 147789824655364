import React, { useRef, useState } from 'react';
import { useTypedSelector } from '@hooks/use_typed_selector';
import { useActions } from '@hooks/use_actions';
import './hit.scss';
import { HitDetailTypeEnum, PreparationTypeEnum } from '@shared/enums';
import { MountAnimation } from '@ui/mount_animation/mount_animation';
import { useCurrentDimensions } from '@utils/dimensions';
const Hit = (props) => {
    const dimensions = useCurrentDimensions();
    const [isVisible, setIsVisible] = useState(true);
    const { triggerReplacement, finishPreparation, addIgnore } = useActions();
    const { pendingPrepare, typePrepare, dataPrepare } = useTypedSelector((store) => store.prepare);
    if (pendingPrepare && dataPrepare) {
        if (props.hit.hit_id === dataPrepare.hitId) {
            setIsVisible(!isVisible);
            setTimeout(() => {
                if (typePrepare === PreparationTypeEnum.REPLACE) {
                    triggerReplacement(dataPrepare.tooltip, {
                        replacementId: dataPrepare.replacementId,
                        replacementString: dataPrepare.replacementString,
                        nodeKey: dataPrepare.nodeKey,
                        blockKey: dataPrepare.blockKey,
                        wordId: dataPrepare.wordId,
                        hitId: dataPrepare.hitId,
                    });
                }
                else {
                    addIgnore(dataPrepare.triggerOriginEnum, dataPrepare.ignoreTypeEnum, dataPrepare.hit.word, dataPrepare.hit.word_id, props.hit.hit_id, props.hit.start_idx, dataPrepare.nodeKey);
                }
            }, 50);
            finishPreparation();
        }
    }
    // eslint-disable-next-line
    const hitRef = useRef(null);
    const { requestHitDetail } = useActions();
    const handleClick = () => {
        if (props.hit.ignore !== true) {
            requestHitDetail(HitDetailTypeEnum.SPECIFIC, props.hit);
        }
    };
    const isActive = props.hit.hit_id == props.activeId ? true : false;
    return (React.createElement(React.Fragment, null,
        React.createElement(MountAnimation, { timeout: 500, unmountExit: true, classNames: 'transition-translate-x', isVisible: isVisible },
            React.createElement("li", { id: props.hit.hit_id, ref: hitRef, 
                // eslint-disable-next-line
                className: 'hit_detail_box mb-2' + (dimensions.width < 1024 ? ' mobile' : '') },
                React.createElement("span", { onClick: handleClick, className: 'hit_detail_text hit_detail_' + props.hit.level + (isActive ? ' active' : '') }, props.hit.word)))));
};
export default Hit;
