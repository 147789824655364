import { PrepareActionType } from '@state/types';
import { PreparationTypeEnum } from '@shared/enums';
const initialState = {
    pendingPrepare: false,
};
const prepareReducer = (state = initialState, action = {
    type: PrepareActionType.PENDING,
    trigger: PreparationTypeEnum.REPLACE,
}) => {
    switch (action.type) {
        case PrepareActionType.PENDING:
            return { pendingPrepare: true, typePrepare: action.trigger, dataPrepare: action.payload };
        case PrepareActionType.FINISHED:
            return { pendingPrepare: false };
        case PrepareActionType.ERROR:
            return { pendingPrepare: false };
        default:
            return state;
    }
};
export default prepareReducer;
