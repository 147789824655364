import { ThemeActionType } from '@state/types';
import { ThemeEnum } from '@shared/enums';
const initialState = {
    triggeredTheme: false,
};
const themeReducer = (state = initialState, action = {
    type: ThemeActionType.TOGGLE,
    payload: ThemeEnum.STANDARD,
}) => {
    switch (action.type) {
        case ThemeActionType.TOGGLE:
            return { triggeredTheme: true, typeTheme: action.payload };
        case ThemeActionType.TOGGLED:
            return { triggeredTheme: false };
        default:
            return state;
    }
};
export default themeReducer;
