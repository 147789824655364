import { DemoActionType } from '@state/types';
const initialState = {
    triggerDemo: false,
};
const demoReducer = (state = initialState, action = { type: DemoActionType.TRIGGERED }) => {
    switch (action.type) {
        case DemoActionType.TRIGGER:
            return { triggerDemo: true };
        case DemoActionType.TRIGGERED:
            return { triggerDemo: false };
        default:
            return state;
    }
};
export default demoReducer;
