import { TooltipActionType } from '@state/types';
import { TooltipTriggerEnum } from '@shared/enums';
const initialState = {
    triggeredTooltip: false,
    mobileTooltip: false,
};
const tooltipReducer = (state = initialState, action = {
    type: TooltipActionType.TOGGLE,
    payload: { trigger: TooltipTriggerEnum.OPEN, data: { nodeKey: '' }, mobile: false, touch: false },
}) => {
    switch (action.type) {
        case TooltipActionType.TOGGLE:
            return {
                triggeredTooltip: true,
                typeTooltipTrigger: action.payload.trigger,
                dataTooltip: action.payload.data,
                mobileTooltip: action.payload.mobile,
                touchTooltip: action.payload.touch
            };
        case TooltipActionType.TOGGLED:
            return { triggeredTooltip: false };
        default:
            return state;
    }
};
export default tooltipReducer;
