import { HitsActionType } from '@state/types';
const initialState = {
    requestedHits: false,
    errorHits: null,
    dataHits: null,
};
const hitsReducer = (state = initialState, action = { type: HitsActionType.UPDATED, payload: null }) => {
    switch (action.type) {
        case HitsActionType.UPDATED:
            return { requestedHits: true, errorHits: null, dataHits: action.payload };
        case HitsActionType.DONE:
            return { requestedHits: false, errorHits: null, dataHits: null };
        case HitsActionType.ERROR:
            return { requestedHits: false, errorHits: action.payload, dataHits: null };
        default:
            return state;
    }
};
export default hitsReducer;
