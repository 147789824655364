import React from 'react';
import IgnoreList from '@component/analyzer/ignore_list/ignore_list';
import FinnitNavCross from '@assets/icons/finnit_nav_cross.svg';
import { useActions } from '@hooks/use_actions';
import './right_sidebar.scss';
import { SidebarTypeEnum, ThemeEnum } from '@shared/enums';
import { getLocalStorage } from '@utils/storage';
const RightSidebar = () => {
    const { toggleTheme, toggleSidebar } = useActions();
    const themeMode = getLocalStorage('theme_mode');
    const newThemeMode = themeMode === ThemeEnum.STANDARD ? ThemeEnum.HIGH_CONTRAST : ThemeEnum.STANDARD;
    return (React.createElement("div", { className: "right_sidebar lg:bg-transparent xss:bg-white fixed\n\t\t\txl:mt-28 3xl:ml-[-52px] 2xl:ml-2 xl:ml-1 xl:w-[288px] lg:max-w-xs xss:w-full xss:h-full" },
        React.createElement("div", { className: "CROSS-ICON justify-end lg:hidden xss:flex pr-4 pt-4 mb-20", onClick: () => toggleSidebar(SidebarTypeEnum.RIGHT) },
            React.createElement("div", { className: "symbol finnit_nav_cross cursor-pointer" },
                React.createElement(FinnitNavCross, { className: "w-6 h-6" }))),
        React.createElement(IgnoreList, null),
        React.createElement("div", { className: "xss:flex xss:flex-col items-center justify-between md:px-0 px-4 lg:hidden" },
            React.createElement("div", { className: "lg:hidden xss:flex text-center lg:w-[288px] xss:w-full max-w-[90%]" },
                React.createElement("a", { onClick: () => toggleTheme(newThemeMode), 
                    // eslint-disable-next-line
                    className: 'px-4 text-left h-14 leading-[56px] w-full cursor-pointer theme_mode ' + (themeMode && themeMode === ThemeEnum.HIGH_CONTRAST ? ThemeEnum.STANDARD : ThemeEnum.HIGH_CONTRAST) }, themeMode && themeMode === 'high_contrast' ? 'Standard-Modus' : 'Hochkontrast-Modus')),
            React.createElement("nav", { className: "xss:w-full max-w-[90%] mt-8" },
                React.createElement("ul", { className: "DESKTOP-MENU xss:block w-full" },
                    React.createElement("li", { className: "mb-6" },
                        React.createElement("a", { className: "base-color text-xl", href: "/" }, "Homepage")),
                    React.createElement("li", { className: "mb-6" },
                        React.createElement("a", { className: "base-color text-xl", href: "/contact" }, "Kontakt")),
                    React.createElement("li", { className: "mb-6" },
                        React.createElement("a", { className: "base-color text-xl", href: "/imprint" }, "Impressum")),
                    React.createElement("li", null,
                        React.createElement("a", { className: "base-color text-xl", href: "/data_privacy" }, "Datenschutz")))))));
};
export default RightSidebar;
