function createFingerprint() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const txt = 'https://zammad.com';
    if (ctx) {
        ctx.textBaseline = 'top';
        ctx.font = '12px \'Arial\'';
        ctx.textBaseline = 'alphabetic';
        ctx.fillStyle = '#f60';
        ctx.fillRect(125, 1, 62, 20);
        ctx.fillStyle = '#069';
        ctx.fillText(txt, 2, 15);
        ctx.fillStyle = 'rgba(100, 200, 0, 0.7)';
        ctx.fillText(txt, 4, 17);
    }
    return canvas.toDataURL();
}
export { createFingerprint };
