import React from 'react';
import { HitLevel } from '@shared/interfaces/analyzer';
import './hit_detail.scss';
import SimpleText from '@ui/simple_text/simple_text';
import HitLevelBox from '@ui/hit_level_box/hit_level_box';
import { useCurrentDimensions } from '@utils/dimensions';
import { config } from '@config/config';
const HitDetail = (props) => {
    const dimensions = useCurrentDimensions();
    switch (props.hit.level) {
        case HitLevel.POSITIVE:
            return (React.createElement("div", { className: "hit_detail" },
                React.createElement("p", { className: "hit_level_detail_word mb-4" },
                    React.createElement("span", { className: "hit_level_detail positive" },
                        props.hit.word,
                        " ")),
                React.createElement(HitLevelBox, { hit: props.hit, level: HitLevel.POSITIVE }),
                React.createElement(SimpleText, { text: "Super!" })));
        case HitLevel.WRONG_GENDERED:
            return (React.createElement("div", { className: "hit_detail" },
                React.createElement("p", { className: "hit_level_detail_word mb-4" },
                    React.createElement("span", { className: "hit_level_detail wrong" },
                        props.hit.word,
                        " ")),
                React.createElement(HitLevelBox, { hit: props.hit, level: HitLevel.WRONG_GENDERED }),
                React.createElement(SimpleText, { text: "Achtung:<br />Das ist falsch gegendert." })));
        case HitLevel.CORRECT_GENDERED:
            return (React.createElement("div", { className: "hit_detail" },
                React.createElement("p", { className: "hit_level_detail_word mb-4" },
                    React.createElement("span", { className: "hit_level_detail correct" },
                        props.hit.word,
                        " ")),
                React.createElement(HitLevelBox, { hit: props.hit, level: HitLevel.CORRECT_GENDERED }),
                React.createElement(SimpleText, { text: "Super!<br />Diesen Begriff kannst du so gendern." })));
        case HitLevel.NOT_GENDERED:
        case HitLevel.NOT_GENDERED_COMPOUND:
            // const lemmaAndWordStart: LemmaAndWordStart = findLemmaAndWordStart(props.hit.word, props.hit.lemma);
            // let not_gendered_display_el;
            // if (lemmaAndWordStart.lemmaStart === lemmaAndWordStart.wordStart) {
            //   not_gendered_display_el = <span className="hit_level_detail not">{lemmaAndWordStart.word}</span>;
            // } else if (lemmaAndWordStart.lemmaStart !== lemmaAndWordStart.wordStart &&
            //   lemmaAndWordStart.lemmaStart === 0 && lemmaAndWordStart.wordStart > 0
            // ) {
            //   not_gendered_display_el = <>
            //     <span className="hit_level_detail not">{lemmaAndWordStart.lemma}</span>
            //     <span className="hit_level_detail not_rest">{lemmaAndWordStart.word}</span>
            //   </>;
            // } else if (lemmaAndWordStart.lemmaStart !== lemmaAndWordStart.wordStart &&
            //   lemmaAndWordStart.lemmaStart > 0 && lemmaAndWordStart.wordStart === 0
            // ) {
            //   not_gendered_display_el = <>
            //     <span className="hit_level_detail not_rest">{lemmaAndWordStart.word}</span>
            //     <span className="hit_level_detail not">{lemmaAndWordStart.lemma}</span>
            //   </>;
            // }
            return (React.createElement("div", { className: "hit_detail" },
                React.createElement("p", { className: 'hit_level_detail_word ' + (dimensions.height > config.HEIGHT_BREAKPOINT_TABLET ? 'mb-4' : 'mb-2') },
                    React.createElement("span", { className: "hit_level_detail not_rest" }, props.hit.word)),
                React.createElement(HitLevelBox, { hit: props.hit, level: HitLevel.NOT_GENDERED })));
        default:
            return (React.createElement(React.Fragment, null));
    }
};
export default HitDetail;
