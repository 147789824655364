export var HitLevel;
(function (HitLevel) {
    HitLevel["NEW"] = "new";
    HitLevel["NOT_GENDERED"] = "not";
    HitLevel["NOT_GENDERED_COMPOUND"] = "not_compound";
    HitLevel["WRONG_GENDERED"] = "wrong";
    HitLevel["CORRECT_GENDERED"] = "correct";
    HitLevel["POSITIVE"] = "positive";
    HitLevel["IGNORED_NOT_GENDERED"] = "ignored_not";
})(HitLevel || (HitLevel = {}));
