import React from 'react';
import FinnitChecksUpsideDown from '@assets/icons/finnit_checks_upsidedown.svg';
import FinnitNodes from '@assets/icons/nodes.svg';
import FinnitStar from '@assets/icons/star.svg';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './office_mini.scss';
const OfficeMini = () => {
    return (React.createElement("article", { className: "content office_mini flex lg:flex-row xss:flex-col justify-center xss:mb-4" },
        React.createElement("div", { className: "left flex lg:max-wm-sm xss:max-w-full xss:justify-center \n\t\t\t\tlg:justify-start flex-row 3xl:ml-96 2xl:ml-48 xl:ml-24 lg:ml-24 md:ml-0 xss:ml-0 xss:pt-10" },
            React.createElement("div", { className: "symbol star ml-[-180px] mr-[90px] mt-9 md:hidden 2xl:block xss:block" },
                React.createElement(FinnitStar, null)),
            React.createElement("div", { className: "finnit_square s:w-[360px] s:h-[540px] xs:w-[300px] \n\t\t\t\t\txs:h-[450px] xss:w-[260px] xss:h-[390px] xs:ml-8 xss:ml-4" }),
            React.createElement("div", { className: "image s:w-[360px] s:h-[540px] xs:w-[300px] xs:h-[450px] \n\t\t\t\t\ts:ml-[-312px] xs:ml-[-262px] xss:ml-[-242px] xss:w-[260px] xss:h-[390px] s:mt-12 xs:mt-10 xss:mt-6" },
                React.createElement("img", { className: "s:w-[360px] s:h-[540px] xs:w-[300px] xs:h-[450px] xss:w-[260px] xss:h-[390px]", src: '/public/assets/img/office_eins.jpg' })),
            React.createElement("div", { className: "symbol checks_upside_down mt-auto xl:ml-[-97px] \n\t\t\t\t\tlg:ml-[-177px] m:ml-[-127px] sm:ml-[-177px] s:ml-[-237px] \n\t\t\t\t\ts:block xss:hidden mb-[-40px]" },
                React.createElement(FinnitChecksUpsideDown, null)),
            React.createElement("div", { className: "symbol two_points xl:ml-[-57px] mt-36 lg:ml-[-57px] \n\t\t\t\t\tmd:ml-[-107px] sm:ml-[-57px] s:ml-[-17px] s:block xss:hidden" },
                React.createElement(FinnitTwoPoints, null))),
        React.createElement("div", { className: "right flex-col lg:justify-start xl:max-w-[372px] lg:max-w-xs \n\t\t\t\txss:max-w-full xss:justify-center flex lg:p-0 3xl:ml-60 2xl:ml-48 xl:ml-40 lg:ml-20 xss:p-8" },
            React.createElement("div", { className: "symbol nodes mb-8" },
                React.createElement(FinnitNodes, null)),
            React.createElement(SimpleTitle, { sub: true, light: true, text: 'Überzeugen Sie Ihre Zielgruppe von sich!' }),
            React.createElement(SimpleText, { text: 'Um die besten Talente für sich zu gewinnen, gilt es bereits mit der Wortwahl in den \
          Stellenausschreibungen zu überzeugen. Mangelnde Qualität der Texte führt zum einen zu deutlich weniger \
          Bewerbungen sowie zum anderen zu Bewerbungen außerhalb der gewünschten Zielgruppe.' }))));
};
export default OfficeMini;
