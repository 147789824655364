import { TextInfoActionType } from '@state/types';
const initialState = {
    errorTextInfo: null,
    dataTextInfo: null,
};
const textInfoReducer = (state = initialState, action = { type: TextInfoActionType.UPDATED, payload: null }) => {
    switch (action.type) {
        case TextInfoActionType.UPDATED:
            return { errorTextInfo: null, dataTextInfo: action.payload };
        case TextInfoActionType.ERROR:
            return { errorTextInfo: action.payload, dataTextInfo: null };
        default:
            return state;
    }
};
export default textInfoReducer;
