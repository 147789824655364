import React from 'react';
import { Route, Switch, useLocation } from 'wouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import FooterLogo from '@ui/footer/footer_logo';
import FooterNavigation from '@ui/footer/footer_navigation';
import FooterCopyright from '@ui/footer/footer_copyright';
import '@ui/footer/footer.scss';
import Analyzer from '@component/analyzer/analyzer';
import Contact from '@component/contact/contact';
import DataPrivacyPage from '@static/data_privacy_page/data_privacy_page';
import GenderNeutralityPage from '@static/gender_neutrality_page/gender_neutrality_page';
import ImprintPage from '@static/imprint_page/imprint_page';
import LandingPage from '@static/landing_page/landing_page';
import NotFoundPage from '@static/not_found_page/not_found_page';
import SimpleTitle from '@ui/simple_title/simple_title';
import SimpleText from '@ui/simple_text/simple_text';
const Footer = () => {
    return (React.createElement("footer", { className: "footer order-4 xl:h-[220px] md:h-[260px] xss:h-auto" },
        React.createElement("div", { className: "mt-10 flex mx-auto xl:max-w-6xl xl:px-0\n\t\t\t\tmd:flex-row xss:flex-col xss:px-8 xss:w-full xss:max-w-full" },
            React.createElement("article", { className: "flex md:order-1 xss:order-2 flex-row my-0 xl:max-w-6xl\n\t\t\t\t\t\tlg:max-w-5xl md:max-w-3xl m:max-w-xl xs:max-w-sm xss:max-w-xs" },
                React.createElement("aside", { className: "footer_logo flex justify-start" },
                    React.createElement(FooterLogo, null)),
                React.createElement("aside", { className: "footer_navigation ml-10 mt-[-5px]" },
                    React.createElement(FooterNavigation, null))),
            React.createElement("div", { className: "flex flex-col md:order-2 xss:order-1\n\t\t\t\t\tmd:mt-[-5px] md:mb-0 xss:mb-10 w-full md:items-end xss:items-start" },
                React.createElement("div", null,
                    React.createElement(SimpleTitle, { text: "0731 14 11 50 - 0", link: true, href: "tel:+07311411500" }),
                    React.createElement(SimpleText, { text: 'Unser Kundenmanagement ist telefonisch von\nMo. - Fr. von 8.00 - 16.00 Uhr\
							 für Sie erreichbar.\nWir beantworten gerne all Ihre Fragen zu Serviceleistungen.' })))),
        React.createElement("div", { className: "flex mx-auto lg:mt-[-20px] xl:max-w-6xl xl:px-0 xss:mt-8\n\t\t\t\tflex-row xss:px-8 xss:w-full xss:max-w-full md:mb-0 xss:mb-10" },
            React.createElement(FooterCopyright, null))));
};
export default function App() {
    const [location] = useLocation();
    const simpleFooter = location.indexOf('editor') > 0 ? '' : React.createElement(Footer, null);
    // Return the App component.
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "wrapper flex flex-row justify-center items-stretch my-0 mx-auto" },
            React.createElement(ToastContainer, null),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", component: LandingPage }),
                React.createElement(Route, { path: "/contact", component: Contact }),
                React.createElement(Route, { path: "/data_privacy", component: DataPrivacyPage }),
                React.createElement(Route, { path: "/editor", component: Analyzer }),
                React.createElement(Route, { path: "/gender_neutrality", component: GenderNeutralityPage }),
                React.createElement(Route, { path: "/imprint", component: ImprintPage }),
                React.createElement(Route, { component: NotFoundPage })),
            simpleFooter)));
}
