import { DetailActionType } from '@state/types';
import { HitDetailTypeEnum } from '@shared/enums';
const initialState = {
    pendingHitDetail: false,
    errorHitDetail: null,
    dataHitDetail: null,
};
const hitDetailReducer = (state = initialState, action = {
    type: DetailActionType.REQUEST,
    detailType: HitDetailTypeEnum.EMPTY,
    payload: null
}) => {
    switch (action.type) {
        case DetailActionType.REQUEST:
            return {
                pendingHitDetail: true,
                errorHitDetail: null,
                dataHitDetail: action.payload,
                typeHitDetail: action.detailType,
                triggerHitDetail: action.trigger
            };
        case DetailActionType.OPENED:
            return { pendingHitDetail: false, errorHitDetail: null, dataHitDetail: null };
        case DetailActionType.ERROR:
            return { pendingHitDetail: false, errorHitDetail: action.payload, dataHitDetail: null };
        default:
            return state;
    }
};
export default hitDetailReducer;
