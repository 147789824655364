import React from 'react';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import SimpleHeader from '@ui/simple_header/simple_header';
import './data_privacy_page.scss';
const DataPrivacyPage = () => {
    const categories = [
        'Information über die Erhebung personenbezogener Daten',
        'Ihre Rechte',
        'Erhebung personenbezogener Daten bei Besuch unserer Website',
        'Cookies',
        'Erhebung personenbezogener Daten bei Kontaktaufnahme',
        'Weitere Funktionen und Angebote unserer Website',
        '<span className="font-bold underline">Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</span>',
    ];
    const entries = [
        [
            'Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website. \
       Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, \
       E-Mail-Adressen, Nutzerverhalten.',
            'Verantwortlicher gem.Art. 4 Abs. 7 EU - Datenschutz - Grundverordnung(DSGVO) ist die BITE GmbH, \
       Magirus - Deutz - Straße 12, 89077 Ulm, vertreten durch die Geschäftsführer Hubert Ketterer und Stefan Häck.',
            'Unseren Datenschutzbeauftragten erreichen Sie unter \
       <a href="mailto:datenschutz@b-ite.de">datenschutz@b-ite.de</a> \
       oder unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte“.',
            'Für unsere Kunden gilt zusätzlich die „Ergänzende Datenschutzerklärung für Kunden der BITE GmbH“. \
       Diese kann unter <a href="mailto:datenschutz@b-ite.de">datenschutz@b-ite.de</a> angefordert werden.'
        ],
        [
            `Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten: 
      <ul>
      <li>Recht auf Auskunft,</li>
      <li>Recht auf Berichtigung oder Löschung,</li>
      <li>Recht auf Einschränkung der Verarbeitung,</li>
      <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
      <li>Recht auf Datenübertragbarkeit.</li></ul>`,
            'Sie haben zudem das Recht, sich bei einer Datenschutz - Aufsichtsbehörde über die \
       Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.'
        ],
        [
            `Bei der rein informativen Nutzung der Website, also wenn Sie sich nicht registrieren oder \
       uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, \
       die Ihr Browser an unseren Server übermittelt.Wenn Sie unsere Website betrachten möchten, \
       erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere \
       Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:
      <ul>
      <li>IP-Adresse</li>
      <li>Datum und Uhrzeit der Anfrage</li>
      <li>Inhalt der Anforderung(konkrete Seite)</li>
      <li>Zugriffsstatus / HTTP - Statuscode</li>
      <li>jeweils übertragene Datenmenge</li>
      <li>vom Browser übermittelter Referer</li>
      <li>vom Browser übermittelter User - Agent</li>
      </ul>
      Rechtsgrundlage für die Datenerhebung ist Art. 6 Abs. 1 S. 1 lit.f DSGVO.Zweck und \
      berechtigtes Interesse sind die Sicherstellung der Stabilität, Funktionalität und Sicherheit unserer Website.`,
        ],
        [
            'Diese Seite nutzt keine Cookies.',
        ],
        [
            'Bei Ihrer Kontaktaufnahme mit uns über das Kontaktformular werden die von Ihnen mitgeteilten \
       Daten von uns gespeichert, um Ihre Fragen zu beantworten, bzw.Ihr Anliegen zu bearbeiten. Die \
       Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 S. 1 lit.f DSGVO auf der Basis \
       des berechtigten Interesses, Ihnen eine Möglichkeit zur unkomplizierten und elektronischen \
       Kontaktaufnahme und Kommunikation zu geben.Die in diesem Zusammenhang anfallenden Daten löschen \
       wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, \
       falls gesetzliche Aufbewahrungspflichten bestehen.',
        ],
        [
            'Neben der rein informatorischen Nutzung unserer Website bieten wir verschiedene Leistungen an, \
       die Sie bei Interesse nutzen können.Dazu müssen Sie in der Regel weitere personenbezogene Daten \
       angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten \
       Grundsätze zur Datenverarbeitung gelten.',
        ],
        [
            '<span className="font-bold">Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten \
       erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die \
       Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns \
       ausgesprochen haben.</span>',
            '<span className="font-bold">Soweit wir die Verarbeitung Ihrer personenbezogenen Daten \
       auf die Interessenabwägung stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist \
       der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich \
       ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung \
       eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten \
       nicht wie von uns durchgeführt verarbeiten sollten.Im Falle Ihres begründeten Widerspruchs prüfen wir die \
       Sachlage und werden entweder die Datenverarbeitung einstellen bzw.anpassen oder Ihnen unsere zwingenden \
       schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</span>',
            '<span className="font-bold">Soweit wir Ihre personenbezogenen Daten für Zwecke der Werbung \
       verwenden, können Sie dieser Nutzung jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie \
       uns unter folgenden Kontaktdaten informieren: <a href="mailto:info@b-ite.de">info@b-ite.de</a>.</span>',
        ]
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "data_privacy_page_div" },
            React.createElement(SimpleHeader, { headingText: "Datenschutzerkl\u00E4rung" }),
            React.createElement("div", { className: "content lg:pt-44 xss:pt-20" },
                React.createElement("article", { className: "flex flex-row mx-auto lg:max-w-6xl my-0 xl:px-0 lg:pb-10 xss:px-8 xss:py-6" },
                    React.createElement("div", null,
                        React.createElement(SimpleTitle, { sub: false, text: 'Datenschutzerklärung' })))),
            React.createElement(React.Fragment, null, categories.map((category, index) => {
                const formatCategoryIndex = '§ ' + (index + 1) + ' ';
                return (React.createElement("div", { key: index, className: "content" },
                    React.createElement("article", { className: "lg:max-w-6xl flex flex-row mx-auto my-0\n\t\t\t\t\t\t\t\t\t\txss:max-w-full xl:px-0 lg:pb-10 xss:px-8 xss:py-6" },
                        React.createElement("div", null,
                            React.createElement(SimpleTitle, { sub: true, text: formatCategoryIndex + category }),
                            entries[index].map((entry, entryIndex) => {
                                const formatEntryIndex = '(' + (entryIndex + 1) + ') ';
                                return (React.createElement(SimpleText, { key: entryIndex, text: formatEntryIndex + entry }));
                            })))));
            })),
            React.createElement("div", null,
                React.createElement("div", { className: "upside_down_checks" },
                    React.createElement("div", { className: "bg-white disclosure_box my-10 mx-auto max-w-6xl xl:w-full\n\t\t\t\t\t\t\txss:w-11/12 opacity-100 relative" },
                        React.createElement("p", { className: "max-w-[75%] py-10 mx-auto disclosure_text" },
                            "Im Zuge unserer Datenschutz und Sicherheitsbem\u00FChungen setzen die Business IT Engineers ein Vulnerability Disclosure \u00A0Programm um. Die Inhalte hierzu finden Sie unter folgendem Link:\u00A0",
                            React.createElement("a", { className: "base-color", href: "https://www.b-ite.de/vulnerability-disclosure.html", target: "blank" }, "https://www.b-ite.de/vulnerability-disclosure.html"))))))));
};
export default DataPrivacyPage;
