export var ActionEnum;
(function (ActionEnum) {
    ActionEnum["DETAIL_REPLACE"] = "detail_replace";
    ActionEnum["DETAIL_IGNORE"] = "detail_ignore";
    ActionEnum["TOOLTIP_REPLACE"] = "tooltip_replace";
    ActionEnum["TOOLTIP_IGNORE"] = "tooltip_ignore";
    ActionEnum["LIST_IGNORE_ADD"] = "list_ignore_add";
    ActionEnum["LIST_IGNORE_REMOVE"] = "list_ignore_remove";
})(ActionEnum || (ActionEnum = {}));
export var HitDetailTypeEnum;
(function (HitDetailTypeEnum) {
    HitDetailTypeEnum["EMPTY"] = "hit_detail_empty";
    HitDetailTypeEnum["CURRENT"] = "hit_detail_current";
    HitDetailTypeEnum["SPECIFIC"] = "hit_detail_specific";
    HitDetailTypeEnum["NEXT"] = "hit_detail_next";
    HitDetailTypeEnum["PREVIOUS"] = "hit_detail_previous";
})(HitDetailTypeEnum || (HitDetailTypeEnum = {}));
export var HitDetailMobileTypeEnum;
(function (HitDetailMobileTypeEnum) {
    HitDetailMobileTypeEnum["EMPTY"] = "hit_detail_mobile_empty";
    HitDetailMobileTypeEnum["CURRENT"] = "hit_detail_mobile_current";
    HitDetailMobileTypeEnum["SPECIFIC"] = "hit_detail_mobile_specific";
    HitDetailMobileTypeEnum["NEXT"] = "hit_detail_mobile_next";
    HitDetailMobileTypeEnum["PREVIOUS"] = "hit_detail_mobile_previous";
})(HitDetailMobileTypeEnum || (HitDetailMobileTypeEnum = {}));
export var KeyCodeEnum;
(function (KeyCodeEnum) {
    KeyCodeEnum[KeyCodeEnum["ENTER"] = 13] = "ENTER";
    KeyCodeEnum[KeyCodeEnum["ESCAPE"] = 27] = "ESCAPE";
})(KeyCodeEnum || (KeyCodeEnum = {}));
export var IgnoreTypeEnum;
(function (IgnoreTypeEnum) {
    IgnoreTypeEnum["WORD"] = "word";
    IgnoreTypeEnum["HIT"] = "hit";
})(IgnoreTypeEnum || (IgnoreTypeEnum = {}));
export var HitFeedbackEnum;
(function (HitFeedbackEnum) {
    HitFeedbackEnum["CORRECT"] = "correct";
    HitFeedbackEnum["WRONG"] = "wrong";
    HitFeedbackEnum["IDK"] = "idk";
    HitFeedbackEnum["NEW"] = "new";
    HitFeedbackEnum["NOT"] = "not";
})(HitFeedbackEnum || (HitFeedbackEnum = {}));
export var ReplFeedbackEnum;
(function (ReplFeedbackEnum) {
    ReplFeedbackEnum["CORRECT"] = "correct";
    ReplFeedbackEnum["WRONG"] = "wrong";
    ReplFeedbackEnum["INAPPR"] = "inappr";
    ReplFeedbackEnum["NEW"] = "new";
    ReplFeedbackEnum["GOOD_LACK"] = "good_but_lack";
})(ReplFeedbackEnum || (ReplFeedbackEnum = {}));
export var ModalTypeEnum;
(function (ModalTypeEnum) {
    ModalTypeEnum["FEEDBACK"] = "feedback";
    ModalTypeEnum["NEW"] = "new";
})(ModalTypeEnum || (ModalTypeEnum = {}));
export var TriggerOriginEnum;
(function (TriggerOriginEnum) {
    TriggerOriginEnum["DETAIL"] = "detail";
    TriggerOriginEnum["TOOLTIP"] = "tooltip";
    TriggerOriginEnum["LIST"] = "list";
    TriggerOriginEnum["EDITOR"] = "editor";
    TriggerOriginEnum["HISTORY"] = "history";
})(TriggerOriginEnum || (TriggerOriginEnum = {}));
export var TypeFeedbackEnum;
(function (TypeFeedbackEnum) {
    TypeFeedbackEnum["NORMAL"] = "normal";
    TypeFeedbackEnum["NEW"] = "new";
})(TypeFeedbackEnum || (TypeFeedbackEnum = {}));
export var SidebarTypeEnum;
(function (SidebarTypeEnum) {
    SidebarTypeEnum["LEFT"] = "left";
    SidebarTypeEnum["RIGHT"] = "right";
    SidebarTypeEnum["HIT_DETAIL"] = "hit_detail";
})(SidebarTypeEnum || (SidebarTypeEnum = {}));
export var SidebarTriggerTypeEnum;
(function (SidebarTriggerTypeEnum) {
    SidebarTriggerTypeEnum["CLOSE"] = "close";
    SidebarTriggerTypeEnum["OPEN"] = "open";
})(SidebarTriggerTypeEnum || (SidebarTriggerTypeEnum = {}));
export var ThemeEnum;
(function (ThemeEnum) {
    ThemeEnum["STANDARD"] = "standard";
    ThemeEnum["HIGH_CONTRAST"] = "high_contrast";
})(ThemeEnum || (ThemeEnum = {}));
export var TooltipTriggerEnum;
(function (TooltipTriggerEnum) {
    TooltipTriggerEnum["CLOSE"] = "close";
    TooltipTriggerEnum["OPEN"] = "open";
})(TooltipTriggerEnum || (TooltipTriggerEnum = {}));
export var PreparationTypeEnum;
(function (PreparationTypeEnum) {
    PreparationTypeEnum["REPLACE"] = "replace";
    PreparationTypeEnum["IGNORE"] = "ignore";
})(PreparationTypeEnum || (PreparationTypeEnum = {}));
export var HitFilterEnum;
(function (HitFilterEnum) {
    HitFilterEnum["IGNORE"] = "ignore";
    HitFilterEnum["NEW"] = "new";
    HitFilterEnum["IGNORE_NEW"] = "ignore_and_new";
})(HitFilterEnum || (HitFilterEnum = {}));
export var HitFindEnum;
(function (HitFindEnum) {
    HitFindEnum["HIT_ID"] = "hit_id";
})(HitFindEnum || (HitFindEnum = {}));
export var DeviceTypeEnum;
(function (DeviceTypeEnum) {
    DeviceTypeEnum["DESKTOP"] = "desktop";
    DeviceTypeEnum["MOBILE"] = "mobile";
    DeviceTypeEnum["TABLET"] = "tablet";
})(DeviceTypeEnum || (DeviceTypeEnum = {}));
