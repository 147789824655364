import React from 'react';
import { useActions } from '@hooks/use_actions';
import './analyzer_footer.scss';
import { getLocalStorage } from '@utils/storage';
import { ThemeEnum } from '@shared/enums';
const AnalyzerFooter = () => {
    const { toggleTheme } = useActions();
    const themeMode = getLocalStorage('theme_mode') ? getLocalStorage('theme_mode') : ThemeEnum.STANDARD;
    // eslint-disable-next-line
    const handleClick = (event) => {
        event.preventDefault();
        switch (themeMode) {
            case ThemeEnum.STANDARD:
                toggleTheme(ThemeEnum.HIGH_CONTRAST);
                break;
            case ThemeEnum.HIGH_CONTRAST:
                toggleTheme(ThemeEnum.STANDARD);
                break;
        }
    };
    return (React.createElement("footer", { className: "editor_footer z-[1] md:h-10 leading-10 md:fixed lg:block xss:hidden" },
        React.createElement("article", { className: "flex md:flex-row xss:flex-col xl:mx-auto lg:mx-28 md:mx-14\n\t\t\t\t\txss:ml-0 my-0 2xl:max-w-[992px] xl:max-w-[884px] 2xl:px-0 xss:max-w-full md:px-2" },
            React.createElement("div", { className: "flex md:order-1 xss:order-2 items-center justify-between md:px-0 xss:px-4" },
                React.createElement("nav", null,
                    React.createElement("ul", { className: "DESKTOP-MENU xss:block md:space-x-6 justify-center md:flex" },
                        React.createElement("li", { className: "2xl:w-36 md:w-24" },
                            React.createElement("a", { className: "text-white", href: "/imprint" }, "Impressum")),
                        React.createElement("li", { className: "2xl:w-36 md:w-24" },
                            React.createElement("a", { href: "/data_privacy" }, "Datenschutz")),
                        React.createElement("li", { className: "2xl:w-36 md:w-24" },
                            React.createElement("a", { href: "/contact" }, "Kontakt")),
                        React.createElement("li", { className: "2xl:w-36 md:w-24" },
                            React.createElement("a", { href: "/" }, "Homepage"))))),
            React.createElement("div", { className: "flex items-end md:order-2 xss:order-1 md:justify-end md:flex-endtext-center md:ml-auto" },
                React.createElement("a", { onClick: handleClick, 
                    /* eslint-disable-next-line */
                    className: 'lg:h-10 xss:h-14 lg:leading-10 xss:leading-[56px] md:w-[150px] xss:w-full cursor-pointer theme_mode ' + (themeMode && themeMode === 'high_contrast' ? 'standard' : 'high_contrast') }, themeMode && themeMode === 'high_contrast' ? 'Standard-Modus' : 'Hochkontrast-Modus')))));
};
export default AnalyzerFooter;
