import React, { useState } from 'react';
import FinnitChecks from '@assets/icons/finnit_checks.svg';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './draft_mini.scss';
import { config } from '@config/config';
import { setSessionStorage } from '@utils/storage';
const DraftMini = () => {
    const [text] = useState('');
    const openEditor = () => {
        setSessionStorage('start_text', text);
        window.location.href = config.FINNIT_FRONTEND_URL + '/editor';
    };
    const openGenderNeutrality = () => {
        window.location.href = config.FINNIT_FRONTEND_URL + '/gender_neutrality';
    };
    return (React.createElement("article", { className: "pt-20 content draft_mini flex lg:flex-row xss:flex-col justify-center" },
        React.createElement("div", { className: "left flex lg:w-[570px] xss:justify-center\n\t\t\t\t\tlg:justify-start flex-col xss:pt-8 3xl:ml-96 2xl:ml-48 xl:ml-24\n\t\t\t\t\tlg:ml-24 md:pl-8 md:pr-8 xss:max-w-full md:px-0 xss:px-8" },
            React.createElement("div", { className: "action lg:max-w-md m:max-w-full \n\t\t\t\t\t\ts:max-w-md xs:max-w-[350px] xss:max-w-[300px] mb-4" },
                React.createElement("a", { className: "button base block mb-4", onClick: openEditor },
                    React.createElement("span", null, "Jetzt mit Finnit loslegen!")),
                React.createElement("a", { className: "button white block", onClick: openGenderNeutrality },
                    React.createElement("span", null, "Was bedeutet Gender-Neutralit\u00E4t?"))),
            React.createElement("div", { className: "lg:max-w-sm md:max-w-2xl m:max-w-xl s:max-w-md \n\t\t\t\t\t\txss:max-w-[300px] 3xl:mb-10 2xl:mb-8 xl:mb-2 xss:mb-8" },
                React.createElement(SimpleText, { text: 'Wenn die besten Talente angesprochen und der optimale \
						Eindruck nach außen vermittelt werden soll, dann zählt letztendlich \
						jedes Wort, das in Stellenausschreibungen zum Einsatz kommt. \
            Dessen sind sich die Verantwortlichen in den Personalabteilungen mittlerweile bewusst. \
            Viel ist in den letzten Jahren unter anderem hinsichtlich Gender-Neutralität \
						aufgeklärt und sensibilisiert worden, doch die Umsetzung stellt noch viele \
						Unternehmen vor Herausforderungen.' }))),
        React.createElement("div", { className: "right xss:justify-center flex" },
            React.createElement("div", { className: "image" },
                React.createElement("img", { className: "h-auto xss:max-w-full", src: '/public/assets/img/draft_eins.jpg' }),
                React.createElement("div", { className: "title_container\n            md:h-[634px] md:mt-[-634px] md:pt-[465px]\n            galaxy_l:h-[611px] galaxy_l:mt-[-611px] galaxy_l:pt-[455px]\n            iphone6plus_8plus_l:h-[608px] iphone6plus_8plus_l:mt-[-608px] iphone6plus_8plus_l:pt-[450px]\n            surface_duo_w:h-[594px] surface_duo_w:mt-[-594px] surface_duo_w:pt-[420px]\n            iphone6_xs_l:h-[551px] iphone6_xs_l:mt-[-551px] iphone6_xs_l:pt-[410px]\n            m:h-[528px] m:mt-[-528px] m:pt-[390px] m:pl-[30px] m:pr-[30px]\n            sm:h-[469px] sm:mt-[-469px] sm:pt-[340px]\n            surface_duo:h-[446px] surface_duo:mt-[-469px] surface_duo:pt-[320px]\n            s:h-[396px] s:mt-[-396px] s:pt-[280px]\n            iphone12promax:h-[353px] iphone12promax:mt-[-353px] iphone12promax:pt-[200px]\n            xs:h-[342px] xs:mt-[-342px] xs:pt-[200px]\n\t\t\t\t\t\tmobile_l:mt-[-350px] mobile_l:pt-[190px]\n            iphone11_12pro:h-[322px] iphone11_12pro:mt-[-322px] iphone11_12pro:pt-[170px]\n            galaxy:pl-[25px] galaxy:pr-[25px]\n            xss:h-[310px] xss:mt-[-310px] xss:pt-[170px]\n          " },
                    React.createElement("div", { className: "title white md:px-6 galaxy:px-4 xss:px-6" },
                        React.createElement(SimpleTitle, { sub: false, text: 'Punkten Sie mit Ihren' }),
                        React.createElement(SimpleTitle, { sub: false, text: 'Stellenanzeigen bei den Talenten' })),
                    React.createElement("div", { className: "subtitle flex flex-row white mt-2 md:px-6 galaxy:px-4 xss:px-6" },
                        React.createElement("span", { className: "w-5 h-5" },
                            React.createElement(FinnitChecks, null)),
                        React.createElement("span", { className: "ml-4" }, "FINNISH it with FINNIT!")))))));
};
export default DraftMini;
