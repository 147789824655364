import React, { useState } from 'react';
import FinnitNavCross from '@assets/icons/finnit_nav_cross.svg';
import FinnitNavMenu from '@assets/icons/finnit_nav_menu.svg';
import './nav.scss';
const Nav = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    if (isNavOpen) {
        document.body.style.overflow = 'hidden';
    }
    else {
        document.body.style.overflow = 'scroll';
    }
    return (React.createElement("div", { className: "flex items-center justify-between" },
        React.createElement("nav", null,
            React.createElement("section", { className: "MOBILE-MENU flex md:hidden" },
                React.createElement("div", { className: "HAMBURGER-ICON space-y-2", onClick: () => setIsNavOpen((prev) => !prev) },
                    React.createElement("div", { className: "symbol finnit_nav_menu" },
                        React.createElement(FinnitNavMenu, { className: "w-6 h-6" }))),
                React.createElement("div", { className: isNavOpen ? 'show_menu_nav' : 'hide_menu_nav' },
                    React.createElement("div", { className: "CROSS-ICON absolute top-0 right-0 px-8 py-8", onClick: () => setIsNavOpen(false) },
                        React.createElement("div", { className: "symbol finnit_nav_menu mt-[-4px]" },
                            React.createElement(FinnitNavCross, { className: "w-6 h-6" }))),
                    React.createElement("ul", { className: "MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]" },
                        React.createElement("li", { className: "w-52 my-8 uppercase" },
                            React.createElement("a", { href: "/editor" }, "Jetzt loslegen")),
                        React.createElement("li", { className: "w-52 my-8 uppercase" },
                            React.createElement("a", { href: "/gender_neutrality" }, "\u00DCber Gender-Neutralit\u00E4t")),
                        React.createElement("li", { className: "w-52 my-8 uppercase" },
                            React.createElement("a", { href: "/contact" }, "Kontakt"))))),
            React.createElement("ul", { className: "DESKTOP-MENU hidden space-x-8 p-0.5 justify-center md:flex" },
                React.createElement("li", { className: "w-36" },
                    React.createElement("a", { href: "/editor" }, "Zum Editor")),
                React.createElement("li", { className: "w-44" },
                    React.createElement("a", { href: "/gender_neutrality" }, "Geschlechter-Neutralit\u00E4t")),
                React.createElement("li", { className: "w-36" },
                    React.createElement("a", { href: "/contact" }, "Kontakt"))))));
};
export default Nav;
