export class SimpleHttpClient {
    async post(url, body, signal) {
        try {
            let abortSignal = undefined;
            if (signal) {
                abortSignal = signal;
            }
            const response = await this.request(url, 'POST', 'cors', { 'Content-Type': 'application/json' }, body, abortSignal);
            return response.json();
        }
        catch (exception) {
            console.error(exception);
        }
    }
    async request(
    // eslint-disable-next-line
    url, method, mode, headers, body, signal) {
        if (signal) {
            return fetch(url, {
                method,
                mode,
                headers,
                signal,
                body,
            });
        }
        else {
            return fetch(url, {
                method,
                mode,
                headers,
                body,
            });
        }
    }
}
