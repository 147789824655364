import React from 'react';
import { config } from '@config/config';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './editor_mini.scss';
const EditorMini = () => {
    const openEditor = () => {
        window.location.href = config.FINNIT_FRONTEND_URL + '/editor';
    };
    return (React.createElement("article", { className: "content editor_mini flex xss:flex-col justify-center" },
        React.createElement("div", { className: "left flex xss:max-w-full xss:justify-center xss:flex-col md:ml-0 xss:ml-0" },
            React.createElement("div", { className: "xss:p-0 xss:pl-8 xss:pr-8 xss:max-w-full" },
                React.createElement("div", { className: "flex sm:flex-row xss:flex-col" },
                    React.createElement(SimpleTitle, { sub: true, light: true, text: 'Und genau hier' }),
                    React.createElement("div", { className: "sm:ml-2 xss:ml-0 mb-6" },
                        React.createElement(SimpleTitle, { sub: true, text: 'kommt FINNIT ins Spiel!' }))),
                React.createElement("div", { className: "image p-4 mb-10" },
                    React.createElement("img", { src: '/public/assets/img/editor_animation.gif' })),
                React.createElement(SimpleText, { text: 'Ein innovatives Softwaretool, das Ihre Ausschreibungen oder auch \
						sonstigen Texte bis auf das letzte Wort \
          	analysiert, entsprechend markiert und direkt mit Verbesserungsvorschlägen aufwartet. \
          	Das (Arbeits-)Leben kann so einfach sein!\n\n\
          	Gerne können Sie sich hier an Ort und Stelle direkt ein Bild von FINNIT machen:' })),
            React.createElement("div", { className: "action xss:mt-0 h-fit xss:p-8" },
                React.createElement("a", { className: "button base w-full xss:block", onClick: openEditor },
                    React.createElement("span", null, "Zum Editor"))))));
};
export default EditorMini;
