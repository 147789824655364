import { IgnoreActionType } from '@state/types';
import { IgnoreTypeEnum } from '@shared/enums';
const initialState = {
    pendingIgnore: false,
    typeIgnore: undefined,
};
const ignoreReducer = (state = initialState, action = {
    type: IgnoreActionType.ADD,
    payload: { word: '', hitId: '', ignoreType: IgnoreTypeEnum.HIT, startIdx: 0, nodeKey: '' }
}) => {
    switch (action.type) {
        case IgnoreActionType.ADD:
            return {
                pendingIgnore: true,
                typeIgnore: IgnoreActionType.ADD,
                triggerIgnore: action.trigger,
                dataIgnore: action.payload
            };
        case IgnoreActionType.REMOVE:
            return {
                pendingIgnore: true,
                typeIgnore: IgnoreActionType.REMOVE,
                triggerIgnore: action.trigger,
                dataIgnore: action.payload
            };
        case IgnoreActionType.ADDED:
            return { pendingIgnore: false, typeIgnore: IgnoreActionType.ADDED };
        case IgnoreActionType.REMOVED:
            return { pendingIgnore: false, typeIgnore: IgnoreActionType.REMOVED };
        default:
            return state;
    }
};
export default ignoreReducer;
