import { $getHtmlContent } from '@lexical/clipboard';
import { $createParagraphNode, $createRangeSelection, $createTextNode, $getRoot, $getSelection, $isRangeSelection } from 'lexical';
import { selectionMove } from './selection_move';
/**
 * Handle copy content of editor to clipboard
 */
export function handleCopy(editor) {
    editor.update(() => {
        const beforeSelection = $getSelection();
        let htmlString = '';
        if (beforeSelection !== null) {
            htmlString = $getHtmlContent(editor);
            if (htmlString === null) {
                const root = $getRoot();
                if (root.getTextContentSize() > 0) {
                    const firstChild = root.getFirstChild();
                    const lastChild = root.getLastChild();
                    if (firstChild && lastChild) {
                        selectionMove(editor, $createRangeSelection(), firstChild.getKey(), lastChild.getKey(), 0, lastChild.getTextContentSize());
                        htmlString = $getHtmlContent(editor);
                    }
                }
            }
        }
        if (htmlString) {
            const clipboard = navigator.clipboard;
            if (clipboard != null) {
                const data = [
                    new ClipboardItem({
                        'text/html': new Blob([htmlString], {
                            type: 'text/html',
                        }),
                    }),
                ];
                clipboard.write(data);
            }
        }
        selectionMove(editor, beforeSelection, beforeSelection.anchor.key, beforeSelection.focus.key, beforeSelection.anchor.offset, beforeSelection.focus.offset);
    });
}
/**
 * Handle clearing of editor
 */
export function handleClear(editor) {
    editor.update(() => {
        const root = $getRoot();
        root.clear();
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode();
        paragraphNode.append(textNode);
        root.append(paragraphNode);
        const selection = $getSelection();
        const textNodeKey = textNode.getKey();
        if ($isRangeSelection(selection)) {
            selectionMove(editor, selection, textNodeKey, textNodeKey, 0, 0, 'text');
        }
        else {
            const rangeSelection = $createRangeSelection();
            selectionMove(editor, rangeSelection, textNodeKey, textNodeKey, 0, 0, 'text');
        }
    });
}
