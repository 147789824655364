export function getSessionStorage(key, json = false) {
    if (json) {
        // eslint-disable-next-line
        return JSON.parse(window.sessionStorage.getItem(key));
    }
    else {
        return window.sessionStorage.getItem(key);
    }
}
// eslint-disable-next-line
export function setSessionStorage(key, value, json = false) {
    if (json) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }
    else {
        window.sessionStorage.setItem(key, value);
    }
}
export function delSessionStorage(key) {
    window.sessionStorage.removeItem(key);
}
export function getLocalStorage(key, json = false) {
    if (json) {
        // eslint-disable-next-line
        return JSON.parse(window.localStorage.getItem(key));
    }
    else {
        return window.localStorage.getItem(key);
    }
}
// eslint-disable-next-line
export function setLocalStorage(key, value, json = false) {
    if (json) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
    else {
        window.localStorage.setItem(key, value);
    }
}
export function delLocalStorage(key) {
    window.localStorage.removeItem(key);
}
