import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useActions } from '@hooks/use_actions';
import { useTypedSelector } from '@hooks/use_typed_selector';
import { ModalActionType } from '@state/types';
import { KeyCodeEnum, HitFeedbackEnum, ReplFeedbackEnum, ModalTypeEnum, TypeFeedbackEnum } from '@shared/enums';
import SimpleTitle from '@ui/simple_title/simple_title';
import SimpleText from '@ui/simple_text/simple_text';
import Feedback from '@assets/icons/feedback.svg';
import Minus from '@assets/icons/minus.svg';
import Plus from '@assets/icons/plus.svg';
import '@modals/feedback_modal/analyzer_feedback_modal.scss';
import { getLocalStorage } from '@utils/storage';
import { useForceUpdate } from '@utils/use_force_update';
const AnalyzerFeedbackModal = () => {
    const forceUpdate = useForceUpdate();
    // eslint-disable-next-line
    const buttonRef = useRef(null);
    // eslint-disable-next-line
    const messageRef = useRef(null);
    // eslint-disable-next-line
    const overlayRef = useRef(null);
    const { triggerModal, sendFeedback, sentFeedback } = useActions();
    const [opened, setOpened] = useState(false);
    const [currentHit, setCurrentHit] = useState();
    const [message, setMessage] = useState('');
    const [hitFeedback, setHitFeedback] = useState();
    const [replFeedback, setReplFeedback] = useState();
    const [hitFeedbackDone, setHitFeedbackDone] = useState(false);
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [replFeedbackDone, setReplFeedbackDone] = useState(false);
    const [messageFeedbackDone, setMessageFeedbackDone] = useState(false);
    const [modalType, setModalType] = useState();
    const [feedbackRepls, setFeedbackRepls] = useState(['']);
    const [feedbackReplsFilled, setFeedbackReplsFilled] = useState(false);
    const closeModal = () => {
        triggerModal(ModalTypeEnum.FEEDBACK, ModalActionType.CLOSE, currentHit);
    };
    const submitFeedback = async () => {
        if (modalType === ModalTypeEnum.FEEDBACK && hitFeedback && replFeedback && currentHit) {
            setFeedbackSent(false);
            const content_hash = getLocalStorage('content_hash') ? getLocalStorage('content_hash') : 'EMPTY';
            if (content_hash !== 'EMPTY' && content_hash.length === 64) {
                sendFeedback({
                    hit: currentHit,
                    content_hash: getLocalStorage('content_hash') ? getLocalStorage('content_hash') : 'EMPTY',
                    hit_feedback: hitFeedback,
                    repl_feedback: replFeedback,
                    type_feedback: TypeFeedbackEnum.NORMAL,
                    message,
                });
            }
            closeModal();
        }
        else if (modalType === ModalTypeEnum.NEW && messageFeedbackDone && currentHit) {
            currentHit.repls = feedbackRepls;
            setFeedbackSent(false);
            const content_hash = getLocalStorage('content_hash') ? getLocalStorage('content_hash') : 'EMPTY';
            if (content_hash !== 'EMPTY' && content_hash.length === 64) {
                if (feedbackRepls.length > 0) {
                    currentHit.repls = feedbackRepls.filter((feedbackRepl) => {
                        if (feedbackRepl.length > 0) {
                            return feedbackRepl;
                        }
                    });
                }
                sendFeedback({
                    hit: currentHit,
                    content_hash: getLocalStorage('content_hash') ? getLocalStorage('content_hash') : 'EMPTY',
                    hit_feedback: HitFeedbackEnum.NEW,
                    repl_feedback: ReplFeedbackEnum.NEW,
                    type_feedback: TypeFeedbackEnum.NEW,
                    message,
                });
            }
            closeModal();
        }
    };
    const { error, data, done } = useTypedSelector((store) => store.feedback);
    if (!feedbackSent && done) {
        setFeedbackSent(done);
    }
    if (data && !feedbackSent) {
        if (data.status === 'ok') {
            if (modalType === ModalTypeEnum.FEEDBACK) {
                toast.success('Vielen Dank für Ihr Feedback!');
            }
            else if (modalType === ModalTypeEnum.NEW) {
                toast.success('Vielen Dank für Ihre Unterstützung!');
            }
        }
        else {
            toast.error('Beim Senden des Feedbacks ist ein Fehler aufgetreten.');
        }
        sentFeedback();
        setFeedbackSent(true);
    }
    else if (!feedbackSent) {
        if (error) {
            toast.error(error);
            sentFeedback();
            setFeedbackSent(true);
        }
    }
    const toggleModal = () => {
        const body = document.querySelector('body');
        const modal = document.querySelector('.modal');
        if (body && modal) {
            modal.classList.toggle('hidden');
            modal.classList.toggle('opacity-0');
            modal.classList.toggle('pointer-events-none');
            body.classList.toggle('modal-active');
        }
    };
    const { triggeredModal, typeModal, typeTrigger, hitModal } = useTypedSelector((store) => store.modal);
    if (triggeredModal && typeTrigger !== undefined) {
        if (hitModal && JSON.stringify(currentHit) != JSON.stringify(hitModal)) {
            setCurrentHit(hitModal);
        }
        switch (typeTrigger) {
            case ModalActionType.OPEN:
                if (!opened) {
                    setModalType(typeModal);
                    setHitFeedback('');
                    setReplFeedback('');
                    setMessage('');
                    setHitFeedbackDone(false);
                    setReplFeedbackDone(false);
                    setFeedbackRepls(['']);
                    forceUpdate();
                    setOpened(true);
                    toggleModal();
                    triggerModal(ModalTypeEnum.FEEDBACK, ModalActionType.OPENED, hitModal ? hitModal : undefined);
                }
                break;
            case ModalActionType.CLOSE:
                if (opened) {
                    setOpened(false);
                    toggleModal();
                    triggerModal(ModalTypeEnum.FEEDBACK, ModalActionType.CLOSED, hitModal ? hitModal : undefined);
                }
                break;
        }
    }
    const handleMessageKeyUp = (event) => {
        const eventTarget = event.target;
        setMessage(eventTarget.value);
        if (eventTarget.value.length > 0) {
            setMessageFeedbackDone(true);
        }
        else {
            setMessageFeedbackDone(false);
        }
    };
    const handleModalKeyUp = (event) => {
        if (messageRef.current === event.target) {
            handleMessageKeyUp(event);
        }
        else {
            if (event.keyCode === KeyCodeEnum.ESCAPE) {
                closeModal();
            }
        }
    };
    // eslint-disable-next-line
    const handleModalClick = (event) => {
        if (overlayRef.current === event.target) {
            closeModal();
        }
    };
    // eslint-disable-next-line
    const handleChangeHit = (event) => {
        setHitFeedback(event.target.value);
        setHitFeedbackDone(true);
    };
    // eslint-disable-next-line
    const handleChangeRepl = (event) => {
        setReplFeedback(event.target.value);
        setReplFeedbackDone(true);
    };
    // listen from redux on hit-detail-state
    const { pendingHitDetail, dataHitDetail } = useTypedSelector((store) => store.hitDetail);
    if (pendingHitDetail) {
        if (dataHitDetail && JSON.stringify(dataHitDetail) != JSON.stringify(currentHit)) {
            setCurrentHit(dataHitDetail);
        }
        else {
            setCurrentHit(undefined);
        }
    }
    const checkIfFeedbackReplsAreFilled = () => {
        let feedbackReplsAreFilled = true;
        feedbackRepls.forEach((feedbackRepl) => {
            if (feedbackRepl.length === 0) {
                feedbackReplsAreFilled = false;
            }
        });
        setFeedbackReplsFilled(feedbackReplsAreFilled);
    };
    // eslint-disable-next-line
    const handleFeedbackRepl = (event, index) => {
        feedbackRepls[index] = event.target.value;
        setFeedbackRepls(feedbackRepls);
        checkIfFeedbackReplsAreFilled();
    };
    // eslint-disable-next-line
    const addRepl = (event) => {
        event.preventDefault();
        feedbackRepls.push('');
        setFeedbackRepls(feedbackRepls);
        forceUpdate();
        checkIfFeedbackReplsAreFilled();
    };
    // eslint-disable-next-line
    const removeLastRepl = (event) => {
        event.preventDefault();
        feedbackRepls.pop();
        setFeedbackRepls(feedbackRepls);
        forceUpdate();
        checkIfFeedbackReplsAreFilled();
    };
    if (modalType === ModalTypeEnum.FEEDBACK) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { onClick: handleModalClick, onKeyUp: handleModalKeyUp, className: "z-50 modal opacity-0 pointer-events-none \n\t\t\t\tfixed w-full h-full top-0 left-0 flex items-center justify-center" },
                React.createElement("div", { ref: overlayRef, className: "modal-overlay absolute w-full h-full bg-gray-900 opacity-50" }),
                React.createElement("div", { className: "modal-container bg-white w-11/12 md:max-w-xl \n\t\t\t\t\t\t\tmx-auto rounded shadow-lg z-50 overflow-y-auto" },
                    React.createElement("div", { className: "modal-content py-4 w-full text-left px-6" },
                        React.createElement("div", { className: "flex flex-row pb-3" },
                            React.createElement("div", { className: "symbol feedback mt-1 mr-2" },
                                React.createElement(Feedback, null)),
                            React.createElement(SimpleTitle, { text: "Feedback", sub: true }),
                            React.createElement("div", { onClick: closeModal, className: "ml-auto modal-close cursor-pointer z-50" },
                                React.createElement("svg", { className: "fill-current text-black", xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18", viewBox: "0 0 18 18" },
                                    React.createElement("path", { d: "M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 \n\t\t\t\t\t\t\t\t\t\t\t\t9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" })))),
                        React.createElement("div", { onChange: handleChangeHit, className: "flex flex-col mb-8 mt-2" },
                            React.createElement(SimpleText, { text: "Der beschriebene Begriff war:" }),
                            React.createElement("label", { className: "mb-3" },
                                React.createElement("input", { value: "correct", name: "hit", type: "radio", checked: hitFeedback === 'correct', className: "category_input" }),
                                React.createElement("span", { className: "text-sm ml-4 font-light" }, "Richtig gegendert")),
                            React.createElement("label", { className: "mb-3" },
                                React.createElement("input", { value: "not", name: "hit", type: "radio", checked: hitFeedback === 'not', className: "category_input" }),
                                React.createElement("span", { className: "text-sm ml-4 font-light" }, "Nicht gegendert")),
                            React.createElement("label", null,
                                React.createElement("input", { value: "idk", name: "hit", type: "radio", checked: hitFeedback === 'idk', className: "category_input" }),
                                React.createElement("span", { className: "text-sm ml-4 font-light" }, "Bin mir nicht sicher"))),
                        React.createElement("div", { onChange: handleChangeRepl, className: "flex flex-col mb-8" },
                            React.createElement(SimpleText, { text: "Die Vorschl\u00E4ge von Finnit zum Begriff waren:" }),
                            React.createElement("label", { className: "mb-3" },
                                React.createElement("input", { value: "correct", name: "repl", type: "radio", checked: replFeedback === 'correct', className: "category_input" }),
                                React.createElement("span", { className: "text-sm ml-4 font-light" }, "Passend")),
                            React.createElement("label", { className: "mb-3" },
                                React.createElement("input", { value: "good_but_lack", name: "repl", type: "radio", checked: replFeedback === 'good_but_lack', className: "category_input" }),
                                React.createElement("span", { className: "text-sm ml-4 font-light" }, "Gut, aber mit M\u00E4ngeln")),
                            React.createElement("label", null,
                                React.createElement("input", { value: "inappr", name: "repl", type: "radio", checked: replFeedback === 'inappr', className: "category_input" }),
                                React.createElement("span", { className: "text-sm ml-4 font-light" }, "Ungeeignet"))),
                        React.createElement("label", null,
                            React.createElement(SimpleText, { text: "Sonstiges Feedback:" }),
                            React.createElement("textarea", { ref: messageRef, placeholder: "(optionales Feedback)", className: "message_input w-full mt-1", value: message, onKeyUp: handleMessageKeyUp })),
                        React.createElement("div", { className: "flex flex-col w-full pt-2" },
                            replFeedbackDone && hitFeedbackDone ?
                                React.createElement("button", { ref: buttonRef, onClick: submitFeedback, className: "button base w-full" }, "Feedback absenden") :
                                React.createElement("button", { disabled: true, className: "button base w-full disabled" }, "Feedback absenden"),
                            React.createElement("button", { onClick: closeModal, className: "mt-3 button base-border w-full" }, "Zur\u00FCck")))))));
    }
    else {
        return (React.createElement("div", { onClick: handleModalClick, onKeyUp: handleModalKeyUp, className: "z-50 modal opacity-0 pointer-events-none\n\t\t\t\t\tfixed w-full h-full top-0 left-0 flex items-center justify-center" },
            React.createElement("div", { ref: overlayRef, className: "modal-overlay absolute w-full h-full bg-gray-900 opacity-50" }),
            React.createElement("div", { className: "modal-container bg-white w-11/12 md:max-w-xl\n\t\t\t\t\t\tmx-auto rounded shadow-lg z-50 overflow-y-auto" },
                React.createElement("div", { className: "modal-content py-4 w-full text-left px-6" },
                    React.createElement("div", { className: "flex flex-row pb-3" },
                        React.createElement("div", { className: "symbol feedback mt-1 mr-2" },
                            React.createElement(Feedback, null)),
                        React.createElement(SimpleTitle, { text: "Helfen Sie uns Finnit zu verbessern", sub: true }),
                        React.createElement("div", { onClick: closeModal, className: "ml-auto modal-close cursor-pointer z-50" },
                            React.createElement("svg", { className: "fill-current text-black", xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18", viewBox: "0 0 18 18" },
                                React.createElement("path", { d: "M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 \n\t\t\t\t\t\t\t\t\t\t\t9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" })))),
                    React.createElement(SimpleText, { text: "Manchmal kommt es vor, dass Finnit ein Wort nicht erkennt. \\\n\t\t\t\t\t\t\t\tF\u00FCr Ihre R\u00FCckmeldung und Ideenvorschl\u00E4ge zu einem gefundenen \\\n\t\t\t\t\t\t\t\tBegriff sind wir Ihnen sehr dankbar!" }),
                    React.createElement("div", { className: "h-[110px] mt-6" },
                        React.createElement("label", null,
                            React.createElement(SimpleText, { text: "Markierter Begriff:" }),
                            React.createElement("span", { className: "badge base" }, currentHit ? currentHit.word : ''))),
                    React.createElement("div", null,
                        React.createElement("label", null,
                            React.createElement(SimpleText, { text: "Ihre R\u00FCckmeldung:" }),
                            React.createElement("textarea", { ref: messageRef, placeholder: "Was hat gefehlt?", className: "message_input w-full mt-1", value: message, onKeyUp: handleMessageKeyUp }))),
                    React.createElement("div", { className: "mt-4" },
                        React.createElement("label", null,
                            React.createElement(SimpleText, { text: "Ihre Vorschl\u00E4ge (optional):" }),
                            feedbackRepls.map((feedbackRepl, index) => {
                                if (index === feedbackRepls.length - 1 && feedbackRepls.length > 1) {
                                    return React.createElement("div", { key: index, className: "flex flex-row" },
                                        React.createElement("input", { type: "text", className: "repl_input mb-2", value: feedbackRepl, onChange: (event) => handleFeedbackRepl(event, index) }),
                                        React.createElement("button", { onClick: removeLastRepl, className: "mt-[-6px] ml-2" },
                                            React.createElement("div", { className: "symbol minus" },
                                                React.createElement(Minus, null))));
                                }
                                return React.createElement("input", { key: index, type: "text", className: "repl_input mb-2", value: feedbackRepl, onChange: (event) => handleFeedbackRepl(event, index) });
                            }))),
                    React.createElement("div", null, feedbackReplsFilled ?
                        React.createElement("button", { onClick: addRepl },
                            React.createElement("div", { className: "symbol plus" },
                                React.createElement(Plus, null))) :
                        React.createElement("button", { disabled: true, className: "disabled" },
                            React.createElement("div", { className: "symbol plus" },
                                React.createElement(Plus, null)))),
                    React.createElement("div", { className: "flex w-full mt-10" }, messageFeedbackDone ?
                        React.createElement("button", { ref: buttonRef, onClick: submitFeedback, className: "button base w-full" }, "R\u00FCckmeldung abschicken") :
                        React.createElement("button", { disabled: true, className: "button base w-full disabled" }, "R\u00FCckmeldung abschicken"))))));
    }
};
export default AnalyzerFeedbackModal;
