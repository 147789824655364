import { addClassNamesToElement } from '@lexical/utils';
import { $getNodeByKey, $isElementNode, ElementNode, } from 'lexical';
import { HitLevel } from '@shared/interfaces/analyzer';
import { getDOMNodeByKey } from '../utils/get_dom_node_by_key';
export function getAllHolylightNodes(node) {
    const holylightNodes = [];
    const children = node.__children;
    if (!children || children.length === 0)
        return [];
    for (const child of children) {
        const childNode = $getNodeByKey(child);
        if ($isHolylightNode(childNode)) {
            holylightNodes.push(childNode);
        }
        else if ($isElementNode(childNode)) {
            const subChildrenNodes = getAllHolylightNodes(childNode);
            holylightNodes.push(...subChildrenNodes);
        }
    }
    return holylightNodes;
}
export function getAllHolylightNodesWithSameWord(node, word) {
    const holylightNodes = [];
    const children = node.__children;
    if (!children || children.length === 0)
        return [];
    for (const child of children) {
        const childNode = $getNodeByKey(child);
        if ($isHolylightNode(childNode) && childNode.getWord().toLowerCase() === word.toLowerCase()) {
            holylightNodes.push(childNode);
        }
        else if ($isElementNode(childNode)) {
            const subChildrenNodes = getAllHolylightNodesWithSameWord(childNode, word);
            holylightNodes.push(...subChildrenNodes);
        }
    }
    return holylightNodes;
}
export function getHolylightNodeByHitId(node, hitId) {
    let holylightNode;
    const children = node.__children;
    if (!children || children.length === 0)
        return null;
    for (const child of children) {
        const childNode = $getNodeByKey(child);
        if ($isHolylightNode(childNode) && childNode.getHitId() === hitId) {
            holylightNode = childNode;
        }
        else if ($isElementNode(childNode)) {
            const subHolylightNode = getHolylightNodeByHitId(childNode, hitId);
            if (subHolylightNode) {
                holylightNode = subHolylightNode;
            }
        }
    }
    return holylightNode ?? null;
}
export class HolylightNode extends ElementNode {
    __hitId;
    __text;
    __hit;
    __ignore;
    static getType() {
        return 'holylight';
    }
    static clone(node) {
        return new HolylightNode(node.__hitId, node.__text, node.__hit, node.__ignore, node.__key);
    }
    static importDOM() {
        return {
            span: () => ({
                conversion: convertHolylightElement,
                priority: 0,
            }),
        };
    }
    static importJSON(serializedNode) {
        const node = $createHolylightNode(serializedNode.hitId, serializedNode.text, serializedNode.hit);
        node.setFormat(serializedNode.format);
        node.setIndent(serializedNode.indent);
        node.setDirection(serializedNode.direction);
        return node;
    }
    constructor(hitId, text, hit, ignore = false, key) {
        super(key);
        this.__hitId = hitId;
        this.__text = text;
        this.__hit = hit;
        this.__ignore = ignore;
    }
    createDOM(config) {
        const element = document.createElement('span');
        addClassNamesToElement(element, config.theme.holylight);
        if (this.__ignore === true) {
            addClassNamesToElement(element, 'ignore');
        }
        element.setAttribute('holyKey', this.__key);
        return element;
    }
    updateDOM() {
        return false;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'holylight',
            hitId: this.getHitId(),
            text: this.getText(),
            hit: this.getHit(),
            ignore: this.getIgnore(),
            version: 1,
        };
    }
    getHitId() {
        return this.getLatest().__hitId;
    }
    setHitId(hitId) {
        const self = this.getLatest().getWritable();
        self.__hitId = hitId;
    }
    getText() {
        return this.getLatest().__text;
    }
    setText(text) {
        const self = this.getLatest().getWritable();
        self.__text = text;
    }
    getRepls() {
        return this.getLatest().__hit.repls || [];
    }
    getHit() {
        return this.getLatest().__hit;
    }
    setHit(hit) {
        const self = this.getLatest().getWritable();
        self.__hit = hit;
    }
    getIgnore() {
        return this.getLatest().__ignore;
    }
    setIgnore(ignore) {
        const self = this.getLatest().getWritable();
        self.__ignore = ignore;
        const element = getDOMNodeByKey(self.__key);
        if (ignore) {
            addClassNamesToElement(element, 'ignore');
        }
        else {
            element.classList.remove('ignore');
        }
    }
    getWord() {
        return this.getLatest().__hit.word;
    }
    insertNewAfter(selection) {
        const element = this.getParentOrThrow().insertNewAfter(selection);
        if ($isElementNode(element)) {
            const holylightNode = $createHolylightNode(this.__hitId, this.__text, this.__hit);
            element.append(holylightNode);
            return holylightNode;
        }
        return null;
    }
    canInsertTextBefore() {
        return false;
    }
    canInsertTextAfter() {
        return false;
    }
    canBeEmpty() {
        return false;
    }
    isInline() {
        return true;
    }
}
function convertHolylightElement(domNode) {
    let node = null;
    if (domNode instanceof HTMLSpanElement) {
        node = $createHolylightNode(domNode.getAttribute('hitId') || '', domNode.getAttribute('text') || '', {
            word_id: 0,
            noun_chunk: '',
            word: '',
            start_idx: 0,
            end_idx: 0,
            block_key: '1',
            hit_id: '2',
            level: HitLevel.NOT_GENDERED,
            index: 0,
        });
    }
    return { node };
}
export function $createHolylightNode(hitId, text, hit) {
    return new HolylightNode(hitId, text, hit, hit.ignore === true ? true : false);
}
export function $isHolylightNode(node) {
    return node instanceof HolylightNode;
}
