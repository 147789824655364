import React, { useState } from 'react';
import FinnitChecks from '@assets/icons/finnit_checks.svg';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './draft.scss';
import { config } from '@config/config';
import { setSessionStorage } from '@utils/storage';
const Draft = () => {
    const [text] = useState('');
    const openEditor = () => {
        setSessionStorage('start_text', text);
        window.location.href = config.FINNIT_FRONTEND_URL + '/editor';
    };
    const openGenderNeutrality = () => {
        window.location.href = config.FINNIT_FRONTEND_URL + '/gender_neutrality';
    };
    return (React.createElement("article", { className: "pt-20 content draft overflow-hidden md:px-8" },
        React.createElement("div", { className: "mx-auto xl:flex-row flex xss:flex-col\n\t\t\t\tjustify-center s:pb-0 xss:pb-12 max-w-6xl" },
            React.createElement("div", { className: "left flex lg:w-full xss:justify-center xl:justify-start\n\t\t\t\t\t\tflex-col 3xl:pt-24 2xl:pt-12 xl:pt-8 xl:mt-0 lg:mt-4 xss:mt-8 xss:max-w-full" },
                React.createElement("div", { className: "title flex md:flex-row xl:flex-col" },
                    React.createElement(SimpleTitle, { sub: false, light: true, text: 'Punkten Sie mit Ihren' }),
                    React.createElement("div", { className: "md:ml-3 xl:ml-0" },
                        React.createElement(SimpleTitle, { sub: false, text: 'Stellenanzeigen bei den Talenten' }))),
                React.createElement("div", { className: "subtitle flex xl:flex-row" },
                    React.createElement("span", { className: "w-5 h-5" },
                        React.createElement(FinnitChecks, null)),
                    React.createElement("span", { className: "ml-4" }, "FINNISH it with FINNIT!")),
                React.createElement("div", { className: "xl:max-w-sm md:max-w-full m:max-w-xl s:max-w-md\n\t\t\t\t\t\t\txss:max-w-[300px] 3xl:mb-10 2xl:mb-8 xl:mb-2 xss:mb-8" },
                    React.createElement(SimpleText, { text: 'Wenn die besten Talente angesprochen und der optimale \
							Eindruck nach außen vermittelt werden soll, \
      	      dann zählt letztendlich jedes Wort, das in Stellenausschreibungen zum Einsatz kommt. \
      	      Dessen sind sich die Verantwortlichen in den Personalabteilungen mittlerweile bewusst. \
      	      Viel ist in den letzten Jahren unter anderem hinsichtlich Gender-Neutralität \
							aufgeklärt und sensibilisiert worden, \
      	      doch die Umsetzung stellt noch viele Unternehmen vor Herausforderungen.' })),
                React.createElement("div", { className: "action xl:max-w-md md:max-w-full m:max-w-xl\n\t\t\t\t\t\t\ts:max-w-md xs:max-w-[350px] xss:max-w-[300px]" },
                    React.createElement("a", { className: "button base block mb-4", onClick: openEditor },
                        React.createElement("span", null, "Jetzt mit Finnit loslegen!")),
                    React.createElement("a", { className: "button white block", onClick: openGenderNeutrality },
                        React.createElement("span", null, "Was bedeutet Gender-Neutralit\u00E4t?"))),
                React.createElement("div", { className: "mini-editor", onClick: openEditor })),
            React.createElement("div", { className: "right lg:flex-row lg:justify-end xss:justify-center flex\n\t\t\t\t\txl:ml-32 xl:mr-[-100px] ipad_pro_l:mr-[-120px] 2xl:mr-[-200px] 3xl:mr-[-400px]" },
                React.createElement("div", { className: "image 3xl:w-[951px] 3xl:h-[786px] 2xl:mt-0 xl:ml-0\n\t\t\t\t\t\t\txl:mt-0 lg:mt-12 xss:mt-12" },
                    React.createElement("img", { className: "xl:w-full h-auto xss:max-w-full", src: '/public/assets/img/draft_eins.jpg' }))))));
};
export default Draft;
