export const DEMO_TEXT = `
Für unsere Niederlassung in Glauchau suchen wir ab dem 01.08.2022 einen Auszubildenden (m/w/d) im Bereich Anwendungsentwicklung.

## Deine Aufgaben

*	Konfiguration, Administration und Programmierung von Logistikanwendungen, Schnittstellen zu Systemen und Webseiten unserer Kunden
*	Datenbankverwaltung
*	Schulung von Mitarbeitern in Bezug auf Hard- und Software
*	Zielorientierte Arbeit an IT-Projekten


## Dein Profil

* Du hast mindestens die Mittlere Reife erreicht
* Hast ein seht gutes technisches Verständnis
* Du bist ein Macher und zeigst hohe Einsatzbereitschaft
* Flexibilität und Belastbarkeit vereinst du in deiner Person


## Deine Vorteile

*	Gute Übernahmechancen und Entwicklungsmöglichkeiten
*	Sicherer und zukunftsorientierter Ausbildungsplatz
*	Weiterbildung mit zertifizierten Ausbildern
*	Selbst gewählte Hardware sowie eigens entwickelte Software
*	Vernetzte Standorte mit modernstem Equipment
*	Intensive Prüfungsvorbereitung durch unsere Lehrer
*	Angebote und Rabatte bei über 600 namenhaften Anbieter/-Innen


## Wir bieten:

*	Einen interessanten, abwechslungsreichen und sicheren Ausbildungsplatz in einem internationalen Umfeld
*	Eine Arbeitsstruktur mit erfahrenen Managern und flachen Hierarchien
*	Ein erfahrenes Team, welches Dich bei deiner Ausbildung unterstützt
*	Spannende Aktivitäten sowie interne Sprach - und Fortbildungskurse bei zahlreichen Veranstaltern
*	Rundum sorglos mit unserem Betriebsarzt
*	Einen Ausbildungsvertrag und eine Ausbildungsvergütung nach dem Tarifvertrag für Auszubildende der Länder (TVA - L BBiG)

***

#### Wir freuen uns auf deine Bewerbung. Gerne per Mail mit Lebenslauf, Zeugnisse, Arbeitsproben etc an die Mailadresse: bewerbung@finnit.de`;
