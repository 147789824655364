import React from 'react';
import './editor_reverted_mini.scss';
import SimpleTitle from '@ui/simple_title/simple_title';
import SimpleText from '@ui/simple_text/simple_text';
import FinnitDash from '@assets/icons/dash.svg';
const EditorRevertedMini = () => {
    return (React.createElement("article", { className: "content editor_reverted xl:px-0 lg:px-8 lg:pt-20 lg:pb-20 xss:px-0" },
        React.createElement("div", { className: "max-w-6xl mx-auto flex lg:flex-row xss:flex-col xss:px-8 m:mt-6 m:pb-20" },
            React.createElement("div", { className: "left flex flex-col xss:flex-none lg:p-0 m:pt-16 xss:pt-16 lg:pb-0 xss:pb-16" },
                React.createElement("div", { className: "symbol dash ml-64 lg:mb-28 xss:hidden lg:block" },
                    React.createElement(FinnitDash, null)),
                React.createElement("div", { className: "xss:mx-auto image 3xl:ml-[-60px] 3xl:w-[633px] 3xl:h-[421px]\n\t\t\t\t\t\txl:w-[520px] xl:h-[320px] lg:w-[450px] lg:h-[286px]\n\t\t\t\t\t\tm:w-[480px] m:h-[320px]\n            sm:w-[450px] sm:h-[307px]\n            xs:w-[340px] xs:h-[239px]\n            galaxy:w-[280px] galaxy:h-[230px]\n\t\t\t\t\t\txss:w-[250px] xss:h-[200px] p-4 lg:mr-20" },
                    React.createElement("img", { className: "3xl:w-[570px] 3xl:h-[334px] \n\t\t\t\t\t\t\txl:w-[471px] xl:h-[276px] lg:w-[350px] lg:h-[205px]\n\t\t\t\t\t\t\tm:w-[471px] m:h-[276px]\n              sm:w-[450px] sm:h-[264px]\n              xs:w-[340px] xs:h-[199px]\n              galaxy:w-[280px] galaxy:h-[164px]\n\t\t\t\t\t\t\txss:w-[250px] xss:h-[146px]", src: '/public/assets/img/editor_animation.gif' }))),
            React.createElement("div", { className: "right flex-row flex xl:ml-28 xss:ml-0" },
                React.createElement("div", { className: "lg:max-w-full xss:max-w-full" },
                    React.createElement(SimpleTitle, { sub: true, light: true, text: 'Finnit hilft Ihnen bei der korrekten Formulierung' }),
                    React.createElement("div", { className: "lg:max-w-sm mt-5" },
                        React.createElement(SimpleText, { text: 'Geschlechtsneutrale Formulierungen umfassen alle Geschlechter, \
							bieten dadurch eine elegante Möglichkeit Diskriminierungen zu vermeiden, \
							sind korrekt und kurz, wodurch der Lesefluss aufrecht erhalten wird.\
              Beispiele hierfür wären Abteilungsleitung statt Abteilungsleiter, \
							Mitarbeitende statt Mitarbeiter, die Bewerbenden etc.\
              Auch kann versucht werden mit Hilfe von direkter Ansprache und \
							der Verwendung des Plurals geschlechtsneutral zu formulieren.\
              \n\n\
              Dennoch lässt es sich an dieser Stelle nicht abstreiten, \
							dass ein gewisser zeitlicher Aufwand für die Erfassung \
							eines geschlechtersensiblen Textes erforderlich ist.\
              \n\n\
              Hier kann ein innovatives Software-Tool Abhilfe schaffen!\
              FINNIT, das Analysetool für Stellenausschreibungen analysiert \
							nicht nur die eingegebenen Texte, sondern liefert zudem auch \
							direkt Lösungsmöglichkeiten hinsichtlich eines geschlechtersensiblen Sprachgebrauchs.\
              \n\n\
              FINISH IT with FINNIT!' })))))));
};
export default EditorRevertedMini;
