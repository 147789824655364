import { AnalyzerActionType } from '@state/types';
const initialState = {
    loading: false,
    controller: new AbortController(),
};
const analyzerReducer = (state = initialState, action = { type: AnalyzerActionType.REQUEST, controller: new AbortController() }) => {
    switch (action.type) {
        case AnalyzerActionType.REQUEST:
            return { loading: true, controller: action.controller, data: undefined, done: false };
        case AnalyzerActionType.SUCCESS:
            return { loading: false, data: action.payload, done: false };
        case AnalyzerActionType.DONE:
            return { loading: false, done: true, data: undefined };
        case AnalyzerActionType.ERROR:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
export default analyzerReducer;
