import React, { useRef, useState } from 'react';
import { useActions } from '@hooks/use_actions';
import { IgnoreTypeEnum, ModalTypeEnum, PreparationTypeEnum, SidebarTypeEnum, TooltipTriggerEnum, TriggerOriginEnum, } from '@shared/enums';
import { calculatePosition } from '@utils/calculate_position';
import Feedback from '@assets/icons/feedback.svg';
import Ignore from '@assets/icons/ignore.svg';
import { ModalActionType } from '@state/types';
import Replacement from '@ui/replacement/replacement';
import SimpleText from '@ui/simple_text/simple_text';
import Question from '@assets/icons/question.svg';
import '@component/analyzer/editor/plugins/holylight/holylight_tooltip_plugin.scss';
const HolylightTooltipPlugin = (props) => {
    // eslint-disable-next-line
    const tooltipRef = useRef(null);
    let tooltip = React.createElement("div", { className: "tooltip", ref: tooltipRef });
    const [moreReplsActive, setMoreReplsActive] = useState(false);
    const [activatedHitRepls, setActivatedHitRepls] = useState([]);
    // problem?: hit_id can change, if text changes before hit
    // hit_id has format: blockKey + startIdx
    // so if startIdx changes and user will open tooltip again, need activate more repls again
    if (props.hit && !activatedHitRepls.includes(props.hit.hit_id)) {
        setMoreReplsActive(false);
    }
    else if (props.hit && activatedHitRepls.includes(props.hit.hit_id)) {
        setMoreReplsActive(true);
    }
    const { triggerPreparation, triggerModal, toggleSidebar, toggleTooltip } = useActions();
    const ignoreHit = () => {
        if (props.nodeKey && props.domElement) {
            toggleTooltip(TooltipTriggerEnum.CLOSE, {
                nodeKey: props.nodeKey,
                domElement: props.domElement,
                hit: props.hit,
            });
        }
        triggerPreparation(PreparationTypeEnum.IGNORE, {
            triggerOriginEnum: TriggerOriginEnum.TOOLTIP,
            ignoreTypeEnum: IgnoreTypeEnum.WORD,
            hit: props.hit,
            hitId: props.hit?.hit_id,
            nodeKey: props.nodeKey,
        });
    };
    const feedbackHit = () => {
        if (props.nodeKey && props.domElement) {
            toggleTooltip(TooltipTriggerEnum.CLOSE, {
                nodeKey: props.nodeKey,
                domElement: props.domElement,
                hit: props.hit,
            });
            triggerModal(ModalTypeEnum.FEEDBACK, ModalActionType.OPEN, props.hit);
        }
    };
    const showIgnoreList = () => {
        if (props.nodeKey && props.domElement) {
            toggleTooltip(TooltipTriggerEnum.CLOSE, {
                nodeKey: props.nodeKey,
                domElement: props.domElement,
                hit: props.hit,
            });
            toggleSidebar(SidebarTypeEnum.RIGHT);
            document.getElementById('ignored_list')?.classList.contains('block') ?
                '' : document.getElementById('ignored_list_toggle_button')?.click();
        }
    };
    const activateMoreReplacements = () => {
        const elements = tooltipRef.current?.base?.querySelectorAll('.inactive') ||
            tooltipRef.current?.querySelectorAll('.inactive');
        elements.forEach((element) => {
            element.classList.remove('inactive');
        });
        setMoreReplsActive(true);
        if (props.hit) {
            activatedHitRepls?.push(props.hit.hit_id);
            setActivatedHitRepls(activatedHitRepls);
        }
    };
    if (props.enabled === true &&
        props.hit &&
        props.typeTrigger === TooltipTriggerEnum.OPEN &&
        props.hit.ignore !== true) {
        tooltip = React.createElement("div", { ref: tooltipRef, id: props.nodeKey, className: "tooltip" },
            React.createElement("ul", { className: "replacements" },
                props.hit.repls &&
                    props.hit.repls.map((replacement, index) => {
                        if (props.nodeKey && props.domElement && props.hit) {
                            const replace = React.createElement(Replacement, { nodeKey: props.nodeKey, domElement: props.domElement, hit: props.hit, replacementString: replacement.repl, replacementId: replacement.repl_id, replacementNumerus: replacement.repl_numerus, tooltip: true });
                            if (index < 3 || moreReplsActive === true) {
                                return replace;
                            }
                            else {
                                // eslint-disable-next-line
                                replace.props.additionalClassname = 'inactive';
                                return replace;
                            }
                        }
                    }),
                moreReplsActive === false && props.hit.repls && props.hit.repls.length > 3 ?
                    React.createElement(React.Fragment, null,
                        React.createElement("hr", { className: "ml-8" }),
                        React.createElement("li", { onClick: activateMoreReplacements, contentEditable: false, className: "replacement in_tooltip activate" },
                            React.createElement("span", { className: "ml-6" },
                                "+",
                                props.hit.repls.length - 3,
                                " weitere Vorschl\u00E4ge anzeigen"))) : '',
                React.createElement("hr", { className: "ml-8" }),
                React.createElement("li", { onClick: ignoreHit, contentEditable: false, className: "flex flex-row replacement in_tooltip ignore" },
                    React.createElement("div", { className: "symbol ignore mr-2" },
                        React.createElement(Ignore, null)),
                    React.createElement("span", null, "Diesen Begriff nicht markieren")),
                React.createElement("hr", { className: "ml-8" }),
                React.createElement("li", { onClick: feedbackHit, contentEditable: false, className: "flex flex-row replacement in_tooltip feedback" },
                    React.createElement("div", { className: "symbol feedback mr-2" },
                        React.createElement(Feedback, null)),
                    React.createElement("span", null, "Ich m\u00F6chte Feedback geben"))));
        if (tooltipRef.current && props.domElement) {
            const position = calculatePosition(props.domElement);
            tooltipRef.current.style.position = 'absolute';
            tooltipRef.current.style.display = 'block';
            tooltipRef.current.style.left = position.left - 20 + 'px';
            if (window.innerHeight > position.bottom + 250) {
                tooltipRef.current.style.top = position.top + 20 + 'px';
            }
            else {
                tooltipRef.current.style.top = position.top - tooltipRef.current.offsetHeight + 'px';
            }
        }
    }
    else if (props.enabled === true &&
        props.hit &&
        props.typeTrigger === TooltipTriggerEnum.OPEN &&
        props.hit.ignore === true) {
        tooltip = React.createElement("div", { ref: tooltipRef, id: props.nodeKey, className: "tooltip" },
            React.createElement("ul", { contentEditable: false, className: 'replacements' },
                React.createElement("li", { contentEditable: false, className: "flex flex-col feedback_new in_tooltip feedback p-4" },
                    React.createElement(SimpleText, { text: "Dieser Begriff ist bereits in den nicht markierten Begriffen." }),
                    React.createElement("button", { onClick: showIgnoreList, className: "button grey" },
                        React.createElement("div", { className: "flex flex-row" },
                            React.createElement("div", { className: "symbol question mr-2" },
                                React.createElement(Question, null)),
                            "Nicht markierte Begriffe anzeigen")))));
        if (tooltipRef.current && props.domElement) {
            const position = calculatePosition(props.domElement);
            tooltipRef.current.style.position = 'absolute';
            tooltipRef.current.style.display = 'block';
            tooltipRef.current.style.left = position.left - 20 + 'px';
            if (window.innerHeight > position.bottom + 250) {
                tooltipRef.current.style.top = position.top + 20 + 'px';
            }
            else {
                tooltipRef.current.style.top = position.top - tooltipRef.current.offsetHeight + 'px';
            }
        }
    }
    return (tooltip);
};
export { HolylightTooltipPlugin };
