import { config } from '@config/config';
import React from 'react';
const FooterNavigation = () => {
    const openDataPrivacy = () => {
        window.location.href = config.FINNIT_FRONTEND_URL + '/data_privacy';
    };
    const openImprint = () => {
        window.location.href = config.FINNIT_FRONTEND_URL + '/imprint';
    };
    const openContact = () => {
        window.location.href = config.FINNIT_FRONTEND_URL + '/contact';
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("ul", null,
            React.createElement("li", null,
                React.createElement("a", { onClick: openImprint }, "Impressum")),
            React.createElement("li", null,
                React.createElement("a", { onClick: openDataPrivacy }, "Datenschutz")),
            React.createElement("li", null,
                React.createElement("a", { onClick: openContact }, "Kontakt")))));
};
export default FooterNavigation;
