/**
 * Move selection in editor to specific anchor and focus (start- and endpoint)
 */
import { $getNodeByKey, $isElementNode, $setSelection } from 'lexical';
export function selectionMove(editor, selection, anchorKey, focusKey, anchorOffset, focusOffset, type = 'text') {
    if ($isElementNode($getNodeByKey(anchorKey)) && type === 'text') {
        type = 'element';
    }
    selection.anchor.set(anchorKey, anchorOffset, type);
    selection.focus.set(focusKey, focusOffset, type);
    $setSelection(selection);
    editor.focus();
}
