import { ReplaceActionType } from '@state/types';
const initialState = {
    pendingReplace: false,
};
const replaceReducer = (state = initialState, action = {
    type: ReplaceActionType.PENDING,
}) => {
    switch (action.type) {
        case ReplaceActionType.PENDING:
            return { pendingReplace: true, dataReplace: action.payload };
        case ReplaceActionType.FINISHED:
            return { pendingReplace: false };
        case ReplaceActionType.ERROR:
            return { pendingReplace: false };
        default:
            return state;
    }
};
export default replaceReducer;
