import React from 'react';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import FinnitDash from '@assets/icons/dash.svg';
import FinnitStar from '@assets/icons/star.svg';
import SimpleTitle from '@ui/simple_title/simple_title';
import './teaser.scss';
const Teaser = () => {
    return (React.createElement("article", { className: "pt-20 teaser xl:px-0 lg:px-8" },
        React.createElement("div", { className: "mx-auto flex max-w-6xl md:justify-start xss:justify-center" },
            React.createElement("div", { className: "left flex flex-row\n\t\t\t\t\t\t3xl:mr-28 2xl:mr-32 xl:mr-16 lg:mr-10" },
                React.createElement("div", { className: "symbol dash lg:mt-24 xss:mt-30 2xl:ml-[-270px] xl:ml-[-200px] xl:pt-0\n\t\t\t\t\t\tiphone11_12pplus_l:block xss:hidden xss:ml-10 xss:pt-20" },
                    React.createElement(FinnitDash, null)),
                React.createElement("div", { className: "symbol star lg:pt-6 lg:mt-36 2xl:ml-24 xl:ml-20 lg:ml-6 lg:block xss:hidden" },
                    React.createElement(FinnitStar, null)),
                React.createElement("div", { className: "symbol two_points lg:mt-16 2xl:ml-20 xl:ml-16 lg:ml-6 xl:mr-24 lg:flex xss:hidden" },
                    React.createElement(FinnitTwoPoints, null))),
            React.createElement("div", { className: "title 2xl:w-[600px] mt-12 3xl:ml-32 2xl:ml-8 lg:ml-10 lg:mr-10 lg:mx-0\n          xl:mb-0 lg:mb-20 iphone12promax:mb-0 google:mb-10 md:mx-auto xss:mb-20 xss:text-center" },
                React.createElement(SimpleTitle, { light: true, text: 'Diskriminierung, Benachteiligung sowie' }),
                React.createElement("div", { className: "md:ml-[-14px] xss:ml-0 tracking-wide mt-2" },
                    React.createElement(SimpleTitle, { light: false, text: 'Schubladendenken effektiv vermeiden' }))),
            React.createElement("div", { className: "right 2xl:ml-10 flex-row flex" },
                React.createElement("div", { className: "symbol star mt-20 2xl:ml-0 2xl:mr-[-200px] lg:block xss:hidden" },
                    React.createElement(FinnitStar, null)),
                React.createElement("div", { className: "symbol dash 2xl:ml-80 xl:ml-24 lg:ml-8 mt-28 3xl:mr-[-400px]\n\t\t\t\t\t\t2xl:mr-[-180px] xl:mr-[-200px] lg:mr-[-100px] xss:hidden iphone11_12pplus_l:block" },
                    React.createElement(FinnitDash, null)),
                React.createElement("div", { className: "symbol star black xss:pt-6 xss:mt-auto xss:mr-2\n\t\t\t\t\t\tiphone11_12pplus_l:block xss:hidden" },
                    React.createElement(FinnitStar, null))))));
};
export default Teaser;
