import React from 'react';
import { config } from '@config/config';
import FinnitBackground from '@assets/finnit_background.svg';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './editor.scss';
const Editor = () => {
    const openEditor = () => {
        window.location.href = config.FINNIT_FRONTEND_URL + '/editor';
    };
    return (React.createElement("article", { className: "content editor overflow-hidden xl:px-0 lg:px-8" },
        React.createElement("div", { className: "mx-auto flex lg:flex-row xss:flex-col justify-center lg:mb-20 lg:mt-2 max-w-6xl" },
            React.createElement("div", { className: "left flex lg:max-wm-sm xss:max-w-full xss:justify-center \n\t\t\t\t\tlg:justify-start lg:flex-col xss:flex-col 3xl:mt-28 2xl:mt-16 \n\t\t\t\t\tlg:mt-8 mr-auto" },
                React.createElement("div", { className: "xl:max-w-sm lg:max-w-[300px] lg:p-0 xss:p-8 xss:max-w-full" },
                    React.createElement("div", { className: "flex lg:flex-col sm:flex-row xss:flex-col" },
                        React.createElement(SimpleTitle, { sub: true, light: true, text: 'Und genau hier' }),
                        React.createElement("div", { className: "lg:ml-0 sm:ml-2 xss:ml-0 mb-6" },
                            React.createElement(SimpleTitle, { sub: true, text: 'kommt FINNIT ins Spiel!' }))),
                    React.createElement(SimpleText, { text: 'Ein innovatives Softwaretool, das Ihre Ausschreibungen oder auch \
							sonstigen Texte bis auf das letzte Wort \
      	    	analysiert, entsprechend markiert und direkt mit Verbesserungsvorschlägen aufwartet. \
      	    	Das (Arbeits-)Leben kann so einfach sein!\n\n\
      	    	Gerne können Sie sich hier an Ort und Stelle direkt ein Bild von FINNIT machen:' })),
                React.createElement("div", { className: "action lg:mt-12 lg:p-0 xss:mt-0 h-fit xss:p-8" },
                    React.createElement("a", { className: "button base w-full xss:block", onClick: openEditor },
                        React.createElement("span", null, "Zum Editor")))),
            React.createElement("div", { className: "right lg:flex-row lg:justify-start xss:justify-center flex lg:p-0 \n\t\t\t\t\txss:p-8 md:mb-[-100px]" },
                React.createElement("div", { className: "background 3xl:w-[1087px] 3xl:h-[589px]\n\t\t\t\t\t\txl:w-[900px] xl:h-[488px] lg:w-[785px] xl:ml-[-100px]\n\t\t\t\t\t\tlg:h-[425px] md:w-[1200px] md:h-[650px] [xss:w-full xss:h-full" },
                    React.createElement(FinnitBackground, null)),
                React.createElement("div", { className: "image 3xl:ml-[-1021px] 3xl:mt-24 3xl:w-[580px] \n\t\t\t\t\t\t3xl:h-[368px] 2xl:ml-[-850px] xl:ml-[-850px] xl:mt-16 \n\t\t\t\t\t\txl:w-[520px] xl:h-[320px] lg:mt-20 lg:ml-[-700px] lg:w-[450px] lg:h-[286px]\n\t\t\t\t\t\txss:w-full xss:h-full p-4 md:mt-10 md:ml-[-850px] md:mr-20" },
                    React.createElement("img", { className: "3xl:w-[548px] 3xl:h-[320px] xl:w-[471px] xl:h-[276px] lg:w-[350px] lg:h-[205px]\n\t\t\t\t\t\t\t\txss:w-full xss:h-full", src: '/public/assets/img/editor_animation.gif' }))))));
};
export default Editor;
