import unidecode from 'unidecode-plus';
export const umlautChars = [
    'ä', 'ö', 'ü', 'ß',
    'à', 'ò', 'ù', 'á', 'ó', 'ú',
    'â', 'ã', 'å', 'ê', 'ô', 'ö', 'õ', 'û'
];
export function checkSpecialChar(char) {
    return umlautChars.includes(char);
}
export function fixSpecialCharsInWord(lemma, word, lemmaEnd) {
    const lemmaStart = 0;
    let newLemma = '';
    for (let i = lemmaStart; i <= lemmaEnd; i++) {
        if (checkSpecialChar(word.charAt(i))) {
            newLemma += word.charAt(i);
        }
        else {
            newLemma += lemma.charAt(i);
        }
    }
    return newLemma;
}
export function findLemmaAndWordStart(word, lemma = '') {
    if (!lemma || !word) {
        return { word, lemma, wordStart: 0, lemmaStart: 0 };
    }
    if (word.length > 0 && lemma.length > 0 && lemma === word) {
        return { word, lemma, wordStart: 0, lemmaStart: 0 };
    }
    else {
        const unidecodedWord = unidecode(word).toLowerCase();
        const lemmaDecodedWord = unidecode(lemma).toLowerCase();
        const lemmaStartIdx = unidecodedWord.indexOf(lemmaDecodedWord);
        if (lemmaStartIdx === 0) {
            const wordStart = lemma.length;
            const wordSub = word.substring(wordStart);
            lemma = fixSpecialCharsInWord(lemma, word, wordStart - 1);
            return { word: wordSub, lemma, wordStart, lemmaStart: lemmaStartIdx };
        }
        else if (lemmaStartIdx > 0) {
            const wordWithoutLemmaLength = unidecodedWord.replace(lemmaDecodedWord, '').length;
            const wordSub = word.substring(0, wordWithoutLemmaLength);
            return { word: wordSub, lemma, wordStart: 0, lemmaStart: lemmaStartIdx };
        }
        else {
            return { word, lemma, wordStart: 0, lemmaStart: 0 };
        }
    }
}
const wordRegex = /(?:\r\n|\r|\n)/g;
export function calculateWordCount(text) {
    const cleanString = text.replace(wordRegex, ' ').trim();
    const wordArray = cleanString.match(/\S+/g);
    return wordArray ? wordArray.length : 0;
}
