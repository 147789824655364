import React from 'react';
import FinnitDash from '@assets/icons/dash.svg';
import FinnitQuotesUp from '@assets/icons/quotes_up.svg';
import FinnitQuotesDown from '@assets/icons/quotes_down.svg';
import FinnitStar from '@assets/icons/star.svg';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './motivation.scss';
const Motivation = () => {
    return (React.createElement("article", { className: "content motivation xl:px-0 lg:px-8" },
        React.createElement("div", { className: "mx-auto max-w-6xl flex lg:flex-row xss:flex-col" },
            React.createElement("div", { className: "left flex lg:px-0 xss:px-8 flex-row lg:justify-start xss:justify-center" },
                React.createElement("div", { className: "symbol dash xss:mt-[-10px] lg:ml-[-250px] sm:block xss:hidden" },
                    React.createElement(FinnitDash, null)),
                React.createElement("div", { className: "symbol star pt-9 lg:mt-auto xss:mt-96 z-10 2xl:mr-12 \n\t\t\t\t\txl:ml-28 lg:ml-36 s:block xss:hidden" },
                    React.createElement(FinnitStar, null)),
                React.createElement("div", { className: "xss:mt-10 w-[243px] xss:mb-10" },
                    React.createElement("div", { className: "symbol quotes_up mb-7" },
                        React.createElement(FinnitQuotesUp, null)),
                    React.createElement(SimpleText, { quotes: true, text: 'Gendern oder Gendering ist eine eingedeutschte Wortbildung aus \
							dem englischen Sprachraum und bezeichnet\
          		im allgemeinen Sinne die Berücksichtigung oder Analyse des \
							Geschlechter-Aspekts in Bezug auf eine Grundgesamtheit\
          von Personen oder Daten, etwa in Wissenschaft, Statistik und Lehre.\n\
          - Wikipedia' }),
                    React.createElement("div", { className: "symbol quotes_down mt-4 ml-auto" },
                        React.createElement(FinnitQuotesDown, null)))),
            React.createElement("div", { className: "right flex flex-row lg:justify-start xss:justify-center lg:mb-0 xss:mb-20\n\t\t\t\t\tlg:mt-0 xss:mt-[-100px]" },
                React.createElement("div", { className: "symbol two_points mt-[155px] xl:mr-[111px] xss:mr-9 sm:block xss:hidden" },
                    React.createElement(FinnitTwoPoints, null)),
                React.createElement("div", { className: "max-w-sm s:mt-[90px] xss:mt-[-40px] sm:px-0 xss:px-8" },
                    React.createElement(SimpleTitle, { sub: true, light: true, text: 'Das Ziel von gendergerechter Sprache' }),
                    React.createElement(SimpleText, { text: 'Die Zielsetzung von geschlechtersensibler Sprache besteht darin, \
						Diskriminierungen und Benachteiligungen\
            sowie alteingesessenem Schubladendenken effektiv entgegenzuwirken. \
						Wenn nämlich aus den Putzfrauen Putzkräfte und\
            aus den Chefs Führungskräfte werden, erfolgt in den Köpfen auch \
						keine automatisierte Geschlechterzuordnung mehr.' })),
                React.createElement("div", { className: "symbol star xl:ml-[49px] lg:ml-0 mt-[77px] sm:block xss:hidden" },
                    React.createElement(FinnitStar, null))))));
};
export default Motivation;
