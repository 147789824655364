import React from 'react';
import SimpleHeader from '@ui/simple_header/simple_header';
import SimpleTitle from '@ui/simple_title/simple_title';
import SimpleText from '@ui/simple_text/simple_text';
import './imprint_page.scss';
import FinnitWhiteBackground from '@assets/finnit_white_background.svg';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import FinnitDash from '@assets/icons/dash.svg';
import FinnitStar from '@assets/icons/star.svg';
const ImprintPage = () => {
    const imprintText = `
		Inhaber:
    BITE GmbH
    Filchnerstraße 16
    89231 Ulm\n
		Sitz der Gesellschaft:
		BITE GmbH
		Magirus-Deutz-Straße 12
		89077 Ulm
    Deutschland\n
    Fon: +49(0) 731 / 14 11 50 - 0
    Fax: +49(0) 731 / 14 11 50 - 10
    Mo. – Fr.von 8: 00 bis 16: 00 Uhr
		Mail: <a className="underline" href="mailto:info@b-ite.de?subject=Finnit">info@b-ite.de</a>\n
    Geschäftsführer: Hubert Ketterer und Stefan Häck
    Registergericht Ulm HRB 722234
    USt - IdNr.: DE260078815\n
    Unsere Hinweise zum Datenschutz finden Sie unter folgendem Link:\
    <a href="https://www.b-ite.de/legal-notice" target="blank">www.b-ite.de/legal-notice</a>`;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "imprint_page_div" },
            React.createElement(SimpleHeader, { headingText: "Impressum" }),
            React.createElement("div", { className: "content lg:pt-44 xss:pt-20" },
                React.createElement("div", { className: "mx-auto max-w-6xl" },
                    React.createElement("article", { className: "flex flex-row mx-auto my-0\n\t\t\t\t\t\t\txss:max-w-full lg:px-0 lg:pb-10 xss:px-8 xss:py-6" },
                        React.createElement("div", { className: "left flex xl:mr-0 \n\t\t\t\t\t\t\t\txss:justify-center lg:justify-start flex-col" },
                            React.createElement("div", { className: "symbol dash black\n\t\t\t\t\t\t\t\t\tml-[-450px] mb-5 3xl:block xss:hidden" },
                                React.createElement(FinnitDash, null)),
                            React.createElement(SimpleTitle, { sub: false, text: 'Impressum' }),
                            React.createElement(SimpleText, { text: imprintText }),
                            React.createElement("div", { className: "symbol two_points xss:hidden 3xl:block ml-[-250px] mt-[-500px]" },
                                React.createElement(FinnitTwoPoints, null)),
                            React.createElement("div", { className: "symbol dash ml-[-220px] mt-48 mb-5 2xl:block xss:hidden" },
                                React.createElement(FinnitDash, null)),
                            React.createElement("div", { className: "symbol xss:hidden 2xl:block star black ml-[-350px] mt-20" },
                                React.createElement(FinnitStar, null))),
                        React.createElement("div", { className: "right flex flex-col ml-20 mr-[-100px] overflow-x-hidden" },
                            React.createElement("div", { className: "symbol dash black\n\t\t\t\t\t\t\t\t\tmt-14 ml-auto mr-[-320px] mb-5 3xl:block xss:hidden" },
                                React.createElement(FinnitDash, null)),
                            React.createElement("div", { className: "symbol star mt-10 mb-[-20px] ml-48 lg:block xss:hidden" },
                                React.createElement(FinnitStar, null)),
                            React.createElement("div", { className: "white_background self-center 2xl:block xss:hidden\n\t\t\t\t\t\t\t\t\t\t2xl:mt-4 2xl:ml-20" },
                                React.createElement(FinnitWhiteBackground, null)),
                            React.createElement("div", { className: "symbol two_points xss:hidden 3xl:block ml-auto mr-[-180px] mt-[-25px]" },
                                React.createElement(FinnitTwoPoints, null)),
                            React.createElement("div", { className: "symbol dash ml-auto mr-28 mt-32 mb-40 2xl:block xss:hidden" },
                                React.createElement(FinnitDash, null)))))))));
};
export default ImprintPage;
