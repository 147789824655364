import { $getNodeByKey, $getSelection, $isRangeSelection } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $isHolylightNode } from '@component/analyzer/editor/nodes/holylight_node';
import { useActions } from '@hooks/use_actions';
import { useCurrentDimensions } from '@utils/dimensions';
import { DeviceTypeEnum, HitDetailTypeEnum, TooltipTriggerEnum } from '@shared/enums';
import { useCurrentDeviceType } from '@utils/device_type';
export default function HolylightClickablePlugin({ filter }) {
    const [editor] = useLexicalComposerContext();
    const dimensions = useCurrentDimensions();
    const deviceType = useCurrentDeviceType();
    const { toggleTooltip, requestHitDetail } = useActions();
    useEffect(() => {
        function onClick(e) {
            const pointerType = e.pointerType;
            const event = e;
            const holylightDomTextNode = getHolylightDomNode(event, editor);
            if (holylightDomTextNode === null) {
                removeAllHolylightTooltips(pointerType === 'touch');
                toggleTooltip(TooltipTriggerEnum.CLOSE, {
                    nodeKey: '',
                    domElement: document.createElement('div'),
                    hit: undefined,
                });
                return;
            }
            const holylightDomNode = holylightDomTextNode.parentElement;
            const selection = editor.getEditorState().read($getSelection);
            if ($isRangeSelection(selection) && !selection.isCollapsed()) {
                return;
            }
            editor.update(() => {
                const firstSelNode = selection.anchor.getNode();
                let firstSelNodeParent = firstSelNode.getParent();
                let firstSelNodeParentKey = firstSelNodeParent?.getKey();
                if ((!$isHolylightNode(firstSelNodeParent) ||
                    (holylightDomNode && firstSelNodeParentKey !== holylightDomNode.getAttribute('holyKey'))) &&
                    holylightDomNode && holylightDomNode.classList.contains('lexical-holylight')) {
                    firstSelNodeParent = getHolylightNodeByDomNode(holylightDomNode);
                    firstSelNodeParentKey = firstSelNodeParent?.getKey();
                }
                if (firstSelNodeParent &&
                    firstSelNodeParentKey &&
                    $isHolylightNode(firstSelNodeParent) &&
                    holylightDomNode) {
                    const hit = firstSelNodeParent.getHit();
                    if (!holylightDomNode?.classList.contains('active') ||
                        holylightDomNode?.classList.contains('first')) {
                        removeAllHolylightTooltips(pointerType === 'touch');
                        toggleTooltip(TooltipTriggerEnum.OPEN, {
                            nodeKey: firstSelNodeParentKey,
                            domElement: holylightDomNode,
                            hit,
                        }, (dimensions.width < 1024 || deviceType !== DeviceTypeEnum.DESKTOP), pointerType === 'touch' ? true : false);
                        if (hit.ignore !== true &&
                            (dimensions.width >= 1024 && deviceType === DeviceTypeEnum.DESKTOP)) {
                            requestHitDetail(HitDetailTypeEnum.SPECIFIC, hit);
                        }
                        if (holylightDomNode.classList.contains('first'))
                            holylightDomNode.classList.remove('first');
                    }
                    else {
                        toggleTooltip(TooltipTriggerEnum.CLOSE, {
                            nodeKey: '',
                            hit,
                            // eslint-disable-next-line
                        }, dimensions.width < 1024);
                        holylightDomNode.classList.remove('active', 'first');
                    }
                }
            });
        }
        return editor.registerRootListener((rootElement, prevRootElement) => {
            if (prevRootElement !== null) {
                prevRootElement.removeEventListener('click', onClick);
            }
            if (rootElement !== null) {
                rootElement.addEventListener('click', onClick);
            }
        });
    }, [editor, filter]);
    return null;
}
function isHolylightDomNode(domNode) {
    if (domNode.parentElement?.classList.contains('lexical-holylight')) {
        return true;
    }
    return false;
}
function removeAllHolylightTooltips(mobile = false) {
    for (const tooltip of document.getElementsByClassName(mobile ? 'tooltip_mobile' : 'tooltip')) {
        tooltip.remove();
    }
    for (const holylight of document.getElementsByClassName('lexical-holylight active')) {
        holylight.classList.remove('active');
    }
}
function getHolylightDomNode(event, editor) {
    return editor.getEditorState().read(() => {
        const domNode = event.target;
        if (isHolylightDomNode(domNode)) {
            return domNode;
        }
        if (domNode.parentNode && isHolylightDomNode(domNode.parentNode)) {
            return domNode.parentNode;
        }
        return null;
    });
}
function getHolylightNodeByDomNode(domNode) {
    const holyKey = domNode.getAttribute('holyKey');
    if (holyKey) {
        return $getNodeByKey(holyKey);
    }
    return null;
}
