import React from 'react';
import FinnitDash from '@assets/icons/dash.svg';
import FinnitStar from '@assets/icons/star.svg';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './couch.scss';
const Couch = () => {
    return (React.createElement("article", { className: "content couch xl:px-0 lg:px-8" },
        React.createElement("div", { className: "max-w-6xl mx-auto flex lg:flex-row xss:flex-col justify-center lg:mb-4 lg:mt-10" },
            React.createElement("div", { className: "left flex lg:max-wm-sm xss:max-w-full xss:justify-center \n\t\t\t\t\tlg:justify-start lg:flex-row xss:flex-col 3xl:mt-28 2xl:mt-16 \n\t\t\t\t\tlg:mt-8" },
                React.createElement("div", { className: "xl:max-w-[400px] lg:max-w-[300px] lg:p-0 xss:p-8 3xl:w-[400px] xss:max-w-full" },
                    React.createElement(SimpleTitle, { sub: true, light: true, text: "Kommen Sie direkt auf den Punkt!" }),
                    React.createElement("div", { className: "xl:max-w-[360px]" },
                        React.createElement(SimpleText, { text: 'Trifft der gewählte Ausschreibungstitel die zu besetzende Position zu 100% und \
      	      	ist er darüber hinaus auch allgemein verständlich? \
      	      	Spiegeln die formulierten Aufgaben und Voraussetzungen auch das benötigte Anforderungsprofil wieder? \
      	      	Ohne ein klares Ja auf beide Fragestellungen, können definitiv keine passenden \
								Bewerbungen für diese Vakanz eingehen.' }))),
                React.createElement("div", { className: "symbol dash xl:ml-14 lg:mt-20 lg:ml-8 xss:ml-8" },
                    React.createElement("span", null,
                        React.createElement(FinnitDash, null)))),
            React.createElement("div", { className: "right lg:flex-row lg:justify-start xss:justify-center flex \n\t\t\t\t\tlg:p-0 3xl:ml-14 2xl:ml-20 xl:ml-10 lg:ml-6 xss:p-8" },
                React.createElement("div", { className: "image lg:mt-[-128px]" },
                    React.createElement("img", { src: '/public/assets/img/couch_eins.png' })),
                React.createElement("div", { className: "symbol star mt-auto md:ml-[-128px] m:ml-[-148px] sm:ml-[-140px] \n\t\t\t\t\t\ts:ml-[-100px] xss:ml-[-72px]" },
                    React.createElement(FinnitStar, null)),
                React.createElement("div", { className: "symbol two_points lg:mt-[-84px] md:ml-24 s:mt-12 \n\t\t\t\t\t\ts:ml-20 xss:mt-6 xss:ml-12" },
                    React.createElement(FinnitTwoPoints, null))))));
};
export default Couch;
