import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { store } from '@state/index';
import App from '@component/app/app';
import './styles.scss';
import './styles/index.css';
const finnitRootEl = document.getElementById('finnit');
function init() {
    if (finnitRootEl) {
        render(React.createElement(Provider, { store: store },
            React.createElement(HelmetProvider, null,
                React.createElement(App, null))), finnitRootEl);
    }
}
init();
