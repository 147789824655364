import { combineReducers } from 'redux';
import analyzerReducer from './analyzer_reducer';
import blocksReducer from './blocks_reducer';
import demoReducer from './demo_reducer';
import feedbackReducer from './feedback_reducer';
import hitsReducer from './hits_reducer';
import hitDetailMobileReducer from './hit_detail_mobile_reducer';
import hitDetailReducer from './hit_detail_reducer';
import ignoreReducer from './ignore_reducer';
import modalReducer from './modal_reducer';
import prepareReducer from './prepare_reducer';
import replaceReducer from './replace_reducer';
import sidebarReducer from './sidebar_reducer';
import textInfoReducer from './text_info_reducer';
import themeReducer from './theme_reducer';
import toolbarReducer from './toolbar_reducer';
import trackingReducer from './tracking_reducer';
import tooltipReducer from './tooltip_reducer';
import holylightReducer from './holylight_reducer';
const reducers = combineReducers({
    analyzer: analyzerReducer,
    blocks: blocksReducer,
    demo: demoReducer,
    hitDetail: hitDetailReducer,
    hitDetailerMobile: hitDetailMobileReducer,
    hits: hitsReducer,
    holylight: holylightReducer,
    ignore: ignoreReducer,
    modal: modalReducer,
    prepare: prepareReducer,
    replace: replaceReducer,
    textInfo: textInfoReducer,
    toolbar: toolbarReducer,
    feedback: feedbackReducer,
    sidebar: sidebarReducer,
    theme: themeReducer,
    tooltip: tooltipReducer,
    tracking: trackingReducer,
});
export default reducers;
