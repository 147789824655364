import { $isListItemNode, $isListNode } from '@lexical/list';
import { $isTableCellNode, $isTableNode, $isTableRowNode } from '@lexical/table';
import { isEmptyObject } from '@utils/is_empty_object';
import { stringHash } from '@utils/string_hash';
import { nodeSubtreeBuild } from './node_subtree_build';
export function changedNodesBuild(node, currentChildrenHashes) {
    const blocks = [];
    const changedNodes = [];
    let changedSubtree = {};
    let childrenHashes = {};
    if ($isListNode(node) ||
        $isListItemNode(node) ||
        $isTableNode(node) ||
        $isTableRowNode(node) ||
        $isTableCellNode(node)) {
        node.getChildren().forEach((nodeChild) => {
            if (($isListItemNode(nodeChild) && $isListNode(nodeChild.getChildren()[0])) ||
                $isListNode(nodeChild) ||
                $isTableRowNode(nodeChild) ||
                $isTableCellNode(nodeChild)) {
                const changedNodeBuild = changedNodesBuild(nodeChild, currentChildrenHashes);
                if (changedNodeBuild.changedNodes.length > 0)
                    changedNodes.push(...changedNodeBuild.changedNodes);
                if (changedNodeBuild.blocks.length > 0)
                    blocks.push(...changedNodeBuild.blocks);
                if (!isEmptyObject(changedNodeBuild.changedSubtree)) {
                    changedSubtree = { ...changedSubtree, ...changedNodeBuild.changedSubtree };
                }
                if (!isEmptyObject(changedNodeBuild.childrenHashes)) {
                    childrenHashes = { ...childrenHashes, ...changedNodeBuild.childrenHashes };
                }
            }
            else {
                const nodeChildKey = nodeChild.getKey();
                const nodeChildContent = nodeChild.getTextContent();
                if (nodeChildContent.replace(/\n/g, '').length > 0) {
                    const nodeChildContentHash = stringHash(nodeChildContent);
                    if (currentChildrenHashes && currentChildrenHashes[nodeChildKey]) {
                        if (currentChildrenHashes[nodeChildKey] !== nodeChildContentHash) {
                            changedNodes.push(nodeChild);
                            blocks.push({ text: nodeChildContent, blockKey: nodeChildKey });
                            childrenHashes[nodeChildKey] = nodeChildContentHash;
                            changedSubtree[nodeChildKey] = nodeSubtreeBuild(nodeChild);
                        }
                    }
                    else {
                        changedNodes.push(nodeChild);
                        blocks.push({ text: nodeChildContent, blockKey: nodeChildKey });
                        childrenHashes[nodeChildKey] = nodeChildContentHash;
                        changedSubtree[nodeChildKey] = nodeSubtreeBuild(nodeChild);
                    }
                }
            }
        });
    }
    return { changedNodes, changedSubtree, blocks, childrenHashes };
}
