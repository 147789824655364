import { TRANSFORMERS } from '@lexical/markdown';
import { $createHorizontalRuleNode, $isHorizontalRuleNode, HorizontalRuleNode } from '../nodes/horizontal_rule_node';
import { $createImageNode, $isImageNode, ImageNode } from '../nodes/image_node';
const HR_REGEX = /^(---|\*\*\*|___)\s?$/;
const IMAGE_IMPORT_REGEX = /!(?:\[([^[]*)\])(?:\(([^(]+)\))/;
const IMAGE_REGEX = /!(?:\[([^[]*)\])(?:\(([^(]+)\))$/;
/**
 * ElementTransformer for HorizontalRuleNode
 */
export const HR = {
    dependencies: [HorizontalRuleNode],
    export: (node) => {
        return $isHorizontalRuleNode(node) ? '***' : null;
    },
    regExp: HR_REGEX,
    replace: (parentNode, _1, _2, isImport) => {
        const line = $createHorizontalRuleNode();
        if (isImport || parentNode.getNextSibling() != null) {
            parentNode.replace(line);
        }
        else {
            parentNode.insertBefore(line);
        }
        line.selectNext();
    },
    type: 'element',
};
/**
 * TextMatchTransformer for ImageNode
 */
export const IMAGE = {
    dependencies: [ImageNode],
    export: (node) => {
        if (!$isImageNode(node)) {
            return null;
        }
        return `![${node.getAltText()}](${node.getSrc()})`;
    },
    importRegExp: IMAGE_IMPORT_REGEX,
    regExp: IMAGE_REGEX,
    replace: (textNode, match) => {
        const [, altText, src] = match;
        const imageNode = $createImageNode({
            altText,
            maxWidth: 500,
            src,
        });
        textNode.replace(imageNode);
    },
    trigger: ')',
    type: 'text-match',
};
const CUSTOM_TRANSFORMERS = [
    ...TRANSFORMERS,
    HR,
    IMAGE,
];
export { CUSTOM_TRANSFORMERS };
