import React, { useEffect, useState } from 'react';
import { $getRoot, $getSelection, UNDO_COMMAND, REDO_COMMAND, $createTextNode, $isRangeSelection, INSERT_PARAGRAPH_COMMAND, $getNodeByKey } from 'lexical';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { createEmptyHistoryState, HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { $isHolylightNode, getAllHolylightNodesWithSameWord, getHolylightNodeByHitId } from '@component/analyzer/editor/nodes/holylight_node';
import { useActions } from '@hooks/use_actions';
import { useTypedSelector } from '@hooks/use_typed_selector';
import { IgnoreActionType, ToolbarActionType } from '@state/types';
import Toolbar from './toolbar/toolbar';
import { updateBlocksAndHits, filterHits } from '@utils/block_hit';
import { setSessionStorage } from '@utils/storage';
import { stringHash } from '@utils/string_hash';
import { calculateWordCount } from '@utils/text_helper';
import './textarea.scss';
import { useDebounce } from '@utils/use_debounce';
import { HitDetailMobileTypeEnum, HitFilterEnum, HitDetailTypeEnum, IgnoreTypeEnum, SidebarTriggerTypeEnum, SidebarTypeEnum, TooltipTriggerEnum, TriggerOriginEnum, DeviceTypeEnum, } from '@shared/enums';
import { scrollToElement, scrollToElementIfNeeded } from '@utils/scroll_to';
import { toast } from 'react-toastify';
import { DEMO_TEXT } from '@api/mock';
import { finnitAnalyzationPrepare } from './utils/finnit_analyzation_prepare';
import { makeItHoly } from './plugins/holylight/index';
import SpeechToTextPlugin, { SPEECH_TO_TEXT_COMMAND } from './plugins/speech_to_text/index';
import { CUSTOM_TRANSFORMERS } from './transformers/custom';
import HolylightClickablePlugin from './plugins/holylight/holylight_clickable_plugin';
import { selectionMove } from './utils/selection_move';
import { handleClear, handleCopy } from './utils/copy_clear';
import { useCurrentDimensions } from '@utils/dimensions';
import { useCurrentDeviceType } from '@utils/device_type';
import { getDOMNodeByKey } from './utils/get_dom_node_by_key';
const TextArea = (props) => {
    const dimensions = useCurrentDimensions();
    const deviceType = useCurrentDeviceType();
    // initial stuff for history, toolbar
    const [historyState] = useState(createEmptyHistoryState());
    const [isSpeechToText, setIsSpeechToText] = useState(false);
    const [editor] = useLexicalComposerContext();
    // initial stuff for content and hashes
    const [wysiwygRootContentHash, setWysiwygRootContentHash] = useState();
    const [childrenHashes, setChildrenHashes] = useState({});
    // eslint-disable-next-line
    const [analyzerRequest, setAnalyzerRequest] = useState();
    const [editorText, setEditorText] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [charCount, setCharCount] = useState(0);
    const [demo, setDemo] = useState(false);
    const [ignoreState, setIgnoreState] = useState({});
    const [deIgnoreState, setDeIgnoreState] = useState({});
    const [response, setResponse] = useState();
    const [blocks, setBlocks] = useState();
    const [hits, setHits] = useState();
    const [hitDetail, setHitDetail] = useState();
    const [requestBlocks] = useState();
    const [enabledAnalyzerRequest, setEnabledAnalyzerRequest] = useState(true);
    const [enabledAnalyzerReducer, setEnabledAnalyzerReducer] = useState(true);
    const [textIsDifferent, setTextIsDifferent] = useState(true);
    const { openedHitDetail, requestAnalyzer, finishReplacement, triggerHolylight, triggeredHolylight, addedIgnore, removedIgnored, updateBlocks, updateHits, requestHitDetail, updateTextInfo, triggeredDemoText, analyzerDone, toggleSidebar, requestHitDetailMobile, triggerToolbar, removeIgnored, toggleTooltip, } = useActions();
    const handleReplace = (replaceData) => {
        if (blocks) {
            const filteredHitEntries = [];
            const filteredBlockEntries = blocks.map((block) => {
                if (replaceData.blockKey === block.blockKey) {
                    const filteredAndFixedHits = block.hits.filter((hit) => {
                        if (hit.ignore !== true && hit.word_id !== replaceData.wordId) {
                            return hit;
                        }
                        else if (hit.word_id === replaceData.wordId) {
                            editor.update(() => {
                                const selection = $getSelection();
                                if ($isRangeSelection(selection)) {
                                    const selectionAnchorOffset = selection.anchor.offset;
                                    const selectionFocusOffset = selection.anchor.offset;
                                    const holylightNode = $getNodeByKey(replaceData.nodeKey);
                                    if (holylightNode) {
                                        const replacedTextNode = $createTextNode(replaceData.replacementString);
                                        holylightNode.replace(replacedTextNode);
                                        selectionMove(editor, selection, replacedTextNode.__key, replacedTextNode.__key, selectionAnchorOffset, selectionFocusOffset, 'text');
                                    }
                                }
                            });
                        }
                    });
                    block.hits = filteredAndFixedHits;
                    filteredHitEntries.push(...filteredAndFixedHits);
                }
                else {
                    filteredHitEntries.push(...filterHits(block.hits, HitFilterEnum.IGNORE));
                }
                return block;
            });
            setBlocks(filteredBlockEntries);
            setHits(filteredHitEntries);
            updateBlocks(filteredBlockEntries);
            updateHits(filteredHitEntries);
            if (filteredHitEntries.length === 0) {
                requestHitDetail(HitDetailTypeEnum.EMPTY, null);
                requestHitDetailMobile(HitDetailMobileTypeEnum.EMPTY, null);
            }
            else if (filteredHitEntries.length > 0) {
                requestHitDetail(HitDetailTypeEnum.CURRENT, null);
            }
            toggleSidebar(SidebarTypeEnum.HIT_DETAIL, SidebarTriggerTypeEnum.CLOSE);
            finishReplacement();
        }
    };
    /**
     * will be called if clicked or typed into "Editor"
     */
    const handleChange = (root, enable_analyzer_request = true) => {
        setEnabledAnalyzerRequest(enable_analyzer_request);
        const currentText = root.getTextContent();
        setEditorText(currentText);
        const newRootContent = root.getTextContent();
        const newWysiwygRootContentHash = stringHash(newRootContent);
        if (currentText.length > 0) {
            if (newRootContent.length > 0 &&
                (newWysiwygRootContentHash !== wysiwygRootContentHash)) {
                const finnitPreparation = finnitAnalyzationPrepare(root, childrenHashes);
                setChildrenHashes(finnitPreparation.childrenHashes);
                const analyzerRequestData = {
                    blocks: finnitPreparation.blocks
                };
                setAnalyzerRequest({
                    changedNodes: finnitPreparation.changedNodes,
                    changedSubtree: finnitPreparation.changedSubtree,
                    changedWysiwygRootContentHash: newWysiwygRootContentHash,
                    request: analyzerRequestData,
                });
            }
        }
        else {
            // text-length is 0, editor is empty
            setChildrenHashes({});
            setEnabledAnalyzerReducer(false);
            setBlocks([]);
            setResponse(undefined);
            setHits([]);
            updateBlocks([]);
            updateHits([]);
            setHitDetail(null);
            scrollToElementIfNeeded('querySelector', 'div[contenteditable="true"]');
        }
        setWysiwygRootContentHash(newWysiwygRootContentHash);
    };
    // listen from redux on toolbar-state
    const { toolbarAction } = useTypedSelector((store) => store.toolbar);
    if (toolbarAction && toolbarAction != ToolbarActionType.FINISHED) {
        const undoStackLength = historyState.undoStack.length;
        // TODO: history for ignoring
        // const redoStackLength = historyState.redoStack.length;
        switch (toolbarAction) {
            case ToolbarActionType.UNDO:
                if (deIgnoreState[undoStackLength] && hits) {
                    const ignoredEntry = deIgnoreState[undoStackLength];
                    removeIgnored(TriggerOriginEnum.HISTORY, ignoredEntry.ignoreType, ignoredEntry.word, ignoredEntry.hitId);
                    delete (deIgnoreState[undoStackLength]);
                    setDeIgnoreState(deIgnoreState);
                }
                editor.dispatchCommand(UNDO_COMMAND, undefined);
                break;
            case ToolbarActionType.REDO:
                editor.dispatchCommand(REDO_COMMAND, undefined);
                break;
            case ToolbarActionType.COPY:
                handleCopy(editor);
                toast.success('Der Text wurde erfolgreich in die Zwischenablage kopiert!');
                break;
            case ToolbarActionType.CLEAR:
                handleClear(editor);
                setBlocks([]);
                setHits([]);
                updateBlocks([]);
                updateHits([]);
                setResponse(undefined);
                setEnabledAnalyzerReducer(false);
                scrollToElement('querySelector', 'div[contenteditable="true"]', 200);
                toast.success('Der Text wurde erfolgreich gelöscht!');
                break;
            case ToolbarActionType.MIC:
                editor.dispatchCommand(SPEECH_TO_TEXT_COMMAND, !isSpeechToText);
                setIsSpeechToText(!isSpeechToText);
                break;
        }
        updateTextInfo({
            chars: charCount,
            words: wordCount,
            text: editorText,
            redo: historyState.redoStack.length > 0,
            undo: historyState.undoStack.length > 0,
        });
        triggerToolbar(ToolbarActionType.FINISHED);
    }
    const debouncedEditorText = useDebounce(editorText, 0);
    useEffect(() => {
        if (debouncedEditorText) {
            const currentWordCount = calculateWordCount(debouncedEditorText);
            const currentCharCount = debouncedEditorText.length;
            updateTextInfo({
                chars: currentCharCount,
                words: currentWordCount,
                text: debouncedEditorText,
                redo: historyState.redoStack.length > 0,
                undo: historyState.undoStack.length > 0,
            });
            setWordCount(currentWordCount);
            setCharCount(currentCharCount);
            if (debouncedEditorText && !demo) {
                setSessionStorage('current_text', debouncedEditorText);
            }
        }
        else {
            updateTextInfo({
                chars: 0,
                words: 0,
                text: '',
                redo: historyState.redoStack.length > 0,
                undo: historyState.undoStack.length > 0,
            });
        }
    }, [debouncedEditorText]);
    const debouncedAnalyzerRequest = useDebounce(analyzerRequest, 150);
    useEffect(() => {
        if (debouncedAnalyzerRequest) {
            setEnabledAnalyzerReducer(true);
            requestAnalyzer(debouncedAnalyzerRequest.request);
            if (demo) {
                setDemo(false);
            }
        }
    }, [debouncedAnalyzerRequest]);
    const debouncedBlocks = useDebounce(requestBlocks, 100);
    useEffect(() => {
        if (textIsDifferent && debouncedBlocks && enabledAnalyzerRequest) {
            setEnabledAnalyzerReducer(true);
            requestAnalyzer({ blocks: debouncedBlocks });
            if (demo) {
                setDemo(false);
            }
            setTextIsDifferent(false);
        }
    }, [debouncedBlocks]);
    // listen from redux on hit-detail-state
    const { pendingHitDetail, dataHitDetail, triggerHitDetail } = useTypedSelector((store) => store.hitDetail);
    if (pendingHitDetail) {
        let element = null;
        if (dataHitDetail && JSON.stringify(dataHitDetail) != JSON.stringify(hitDetail)) {
            setHitDetail(dataHitDetail);
            editor.update(() => {
                document.querySelectorAll('.lexical-holylight.active')?.forEach((holylightActive) => {
                    holylightActive.classList.remove('active', 'first');
                });
                const holylightNode = getHolylightNodeByHitId($getRoot(), dataHitDetail.hit_id);
                if (holylightNode) {
                    element = getDOMNodeByKey(holylightNode.__key);
                    element?.classList.add('active');
                    if (triggerHitDetail === TriggerOriginEnum.EDITOR) {
                        scrollToElementIfNeeded('element', element);
                    }
                    else {
                        scrollToElement('element', element);
                    }
                    setSessionStorage('active_hit', dataHitDetail.hit_id);
                }
            });
        }
        else if (dataHitDetail != hitDetail) {
            setHitDetail(null);
        }
        openedHitDetail();
    }
    // listen from redux on analyzer-state
    const { done, data } = useTypedSelector((store) => store.analyzer);
    if (!done && data && stringHash(JSON.stringify(data)) != response) {
        setResponse(stringHash(JSON.stringify(data)));
        const { currentBlocks, currentHits } = updateBlocksAndHits(data, blocks);
        setBlocks(currentBlocks);
        updateBlocks(currentBlocks);
        if (enabledAnalyzerReducer && JSON.stringify(hits) != JSON.stringify(currentHits)) {
            setHits(currentHits);
            updateHits(currentHits);
            if (currentHits.length > 0 && !hitDetail) {
                let firstNotIgnoredHit;
                for (const currentHit of currentHits) {
                    if (currentHit.ignore !== true) {
                        firstNotIgnoredHit = currentHit;
                        break;
                    }
                }
                if (firstNotIgnoredHit) {
                    requestHitDetail(HitDetailTypeEnum.SPECIFIC, firstNotIgnoredHit);
                }
            }
            else if (currentHits.length === 0 && hits && hits.length > 0) {
                requestHitDetail(HitDetailTypeEnum.EMPTY, null);
            }
            else {
                requestHitDetail(HitDetailTypeEnum.EMPTY, null);
            }
        }
        triggerHolylight({
            editor,
            changedNodes: analyzerRequest.changedNodes,
            changedSubtree: analyzerRequest.changedSubtree,
            currentBlocks: currentBlocks
        });
        analyzerDone();
    }
    // listen from redux on holylight-state
    const { pendingHolylight, dataHolylight } = useTypedSelector((store) => store.holylight);
    if (pendingHolylight && dataHolylight) {
        dataHolylight.editor.update(() => {
            makeItHoly(dataHolylight.editor, dataHolylight.changedNodes, dataHolylight.changedSubtree, dataHolylight.currentBlocks);
            // only check tooltip on desktop
            const activeTooltips = document.getElementsByClassName('tooltip');
            if (activeTooltips.length > 0) {
                toggleTooltip(TooltipTriggerEnum.CLOSE, { nodeKey: '' }, (dimensions.width < 1024 || deviceType !== DeviceTypeEnum.DESKTOP));
            }
        });
        triggeredHolylight();
    }
    // listen from redux on ignore-state
    const { pendingIgnore, typeIgnore, dataIgnore } = useTypedSelector((store) => store.ignore);
    if (pendingIgnore && dataIgnore && hits) {
        editor.update(() => {
            const node = $getNodeByKey(dataIgnore.nodeKey);
            const ignoredOrNot = typeIgnore === IgnoreActionType.ADD ? true : false;
            if ($isHolylightNode(node)) {
                node.setIgnore(ignoredOrNot);
            }
            else {
                // find holylightNodes, where ignored word is same
                const holylightNodes = getAllHolylightNodesWithSameWord($getRoot(), dataIgnore.word);
                holylightNodes?.forEach((holylightNode) => {
                    holylightNode.setIgnore(ignoredOrNot);
                });
            }
        });
        const redoStackLength = historyState.redoStack.length;
        let ignored = false;
        switch (typeIgnore) {
            case IgnoreActionType.ADD:
                if (dataIgnore.ignoreType === IgnoreTypeEnum.HIT) {
                    const updatedHits = hits.map((hit) => {
                        if (hit.hit_id === dataIgnore.hitId) {
                            ignored = true;
                            hit.ignore = true;
                        }
                        return hit;
                    });
                    setHits(updatedHits);
                    updateHits(updatedHits);
                }
                else if (dataIgnore.ignoreType === IgnoreTypeEnum.WORD) {
                    const updatedHits = hits.map((hit) => {
                        if (hit.word === dataIgnore.word) {
                            ignored = true;
                            hit.ignore = true;
                        }
                        return hit;
                    });
                    setHits(updatedHits);
                    updateHits(updatedHits);
                }
                if (ignored) {
                    const undoStackLength = historyState.undoStack.length;
                    deIgnoreState[undoStackLength] = dataIgnore;
                    setDeIgnoreState(deIgnoreState);
                    toggleSidebar(SidebarTypeEnum.HIT_DETAIL, SidebarTriggerTypeEnum.CLOSE);
                }
                if (blocks) {
                    const filteredHitEntries = filterHits(hits, HitFilterEnum.IGNORE);
                    if (filteredHitEntries.length === 0) {
                        requestHitDetail(HitDetailTypeEnum.EMPTY, null);
                        requestHitDetailMobile(HitDetailMobileTypeEnum.EMPTY, null);
                    }
                    else if (filteredHitEntries.length > 0) {
                        requestHitDetail(HitDetailTypeEnum.CURRENT, null);
                        requestHitDetailMobile(HitDetailMobileTypeEnum.CURRENT, null);
                    }
                }
                addedIgnore();
                break;
            case IgnoreActionType.REMOVE:
                if (dataIgnore.ignoreType === IgnoreTypeEnum.HIT) {
                    const updatedHits = hits.map((hit) => {
                        if (hit.hit_id === dataIgnore.hitId) {
                            hit.ignore = false;
                        }
                        return hit;
                    });
                    setHits(updatedHits);
                    updateHits(updatedHits);
                }
                else if (dataIgnore.ignoreType === IgnoreTypeEnum.WORD) {
                    const updatedHits = hits.map((hit) => {
                        if (hit.word === dataIgnore.word) {
                            hit.ignore = false;
                        }
                        return hit;
                    });
                    setHits(updatedHits);
                    updateHits(updatedHits);
                }
                if (blocks) {
                    const filteredHitEntries = filterHits(hits, HitFilterEnum.IGNORE);
                    if (filteredHitEntries.length === 0) {
                        requestHitDetail(HitDetailTypeEnum.EMPTY, null);
                        requestHitDetailMobile(HitDetailMobileTypeEnum.EMPTY, null);
                    }
                    else if (filteredHitEntries.length > 0) {
                        requestHitDetail(HitDetailTypeEnum.CURRENT, null);
                        requestHitDetailMobile(HitDetailMobileTypeEnum.CURRENT, null);
                    }
                }
                ignoreState[redoStackLength] = dataIgnore;
                setIgnoreState(ignoreState);
                removedIgnored();
                break;
        }
        if (editorText.length > 0 && analyzerRequest.changedNodes) {
            triggerHolylight({
                editor,
                changedNodes: analyzerRequest.changedNodes,
                changedSubtree: analyzerRequest.changedSubtree,
                currentBlocks: blocks,
            });
        }
    }
    // listen from redux on replacer-state
    const { dataReplace } = useTypedSelector((store) => store.replace);
    if (dataReplace) {
        handleReplace(dataReplace);
    }
    // listen from redux on demo-state
    const { triggerDemo } = useTypedSelector((store) => store.demo);
    if (triggerDemo) {
        editor.update(() => {
            const root = $getRoot();
            $convertFromMarkdownString(DEMO_TEXT, CUSTOM_TRANSFORMERS);
            if (root.getChildren().length === 0) {
                editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
            }
        });
        triggeredDemoText();
        setDemo(true);
    }
    const onChange = (editorState) => {
        editorState.read(() => {
            if (!demo) {
                toggleSidebar(SidebarTypeEnum.LEFT, SidebarTriggerTypeEnum.CLOSE);
                setTimeout(() => {
                    // only check tooltip on mobile
                    const activeTooltips = document.getElementsByClassName('tooltip_mobile');
                    if (activeTooltips.length > 0) {
                        toggleTooltip(TooltipTriggerEnum.CLOSE, { nodeKey: '' }, (dimensions.width < 1024 || deviceType !== DeviceTypeEnum.DESKTOP));
                    }
                });
            }
            const root = $getRoot();
            handleChange(root);
        });
    };
    useEffect(() => {
        if (editorText.length === 0) {
            scrollToElementIfNeeded('querySelector', '.lexical-paragraph');
            editor.focus();
            // eslint-disable-next-line
            navigator?.virtualKeyboard?.show();
        }
    }, []);
    return (React.createElement("div", { className: "editor_container w-full" },
        React.createElement(Toolbar, { enabled: props.toolbar_enabled }),
        React.createElement(RichTextPlugin, { contentEditable: React.createElement(ContentEditable, null), placeholder: React.createElement("div", { className: "placeholder" }, "Willkommen bei Finnit! - Klicken Sie hier in den Editor, um Ihre Stellenanzeige zu formulieren.") }),
        React.createElement(OnChangePlugin, { onChange: onChange }),
        React.createElement(LinkPlugin, null),
        React.createElement(ListPlugin, null),
        React.createElement(HolylightClickablePlugin, null),
        React.createElement(MarkdownShortcutPlugin, { transformers: CUSTOM_TRANSFORMERS }),
        React.createElement(SpeechToTextPlugin, null),
        React.createElement(HistoryPlugin, { externalHistoryState: historyState })));
};
export default TextArea;
