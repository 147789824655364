import React from 'react';
import { HitLevel } from '@shared/interfaces/analyzer';
import NotGenderedIcon from '@assets/icons/not_gendered_icon.svg';
import CorrectGenderedIcon from '@assets/icons/correct_gendered_icon.svg';
import WrongGenderedIcon from '@assets/icons/wrong_gendered_icon.svg';
import './hit_level_box.scss';
const HitLevelBox = (props) => {
    switch (props.level) {
        case HitLevel.NOT_GENDERED:
            return (React.createElement("div", { className: "flex flex-row justify-between" },
                React.createElement("div", { className: "w-48 h-7 p-1 hit_level_box not_gendered flex mb-4" },
                    React.createElement(NotGenderedIcon, null),
                    "\u00A0\u00A0Nicht genderneutral")));
        case HitLevel.CORRECT_GENDERED:
            return (React.createElement("div", { className: "flex flex-row justify-between" },
                React.createElement("div", { className: "w-48 h-7 p-1 hit_level_box correct_gendered flex mb-4" },
                    React.createElement(CorrectGenderedIcon, null),
                    "\u00A0\u00A0Korrekt")));
        case HitLevel.WRONG_GENDERED:
            return (React.createElement("div", { className: "flex flex-row justify-between" },
                React.createElement("div", { className: "w-48 h-7 p-1 hit_level_box wrong_gendered flex mb-4" },
                    React.createElement(WrongGenderedIcon, null),
                    "\u00A0\u00A0Falsch gegendert.")));
    }
    return (React.createElement(React.Fragment, null));
};
export default HitLevelBox;
