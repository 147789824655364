import React, { useState } from 'react';
import { useTypedSelector } from '@hooks/use_typed_selector';
import { useActions } from '@hooks/use_actions';
import { ToolbarActionType } from '@state/types';
import CopyClipboard from '@assets/toolbar/copy.svg';
import CopyClipboardEnabled from '@assets/toolbar/copy_enabled.svg';
import Delete from '@assets/toolbar/delete.svg';
import DeleteEnabled from '@assets/toolbar/delete_enabled.svg';
import Redo from '@assets/toolbar/redo.svg';
import RedoEnabled from '@assets/toolbar/redo_enabled.svg';
import Undo from '@assets/toolbar/undo.svg';
import UndoEnabled from '@assets/toolbar/undo_enabled.svg';
import Mic from '@assets/toolbar/mic.svg';
import MicEnabled from '@assets/toolbar/mic_enabled.svg';
import FinnitChecks from '@assets/icons/finnit_checks.svg';
import FinnitNavMenu from '@assets/icons/finnit_nav_menu.svg';
import './toolbar.scss';
import { SidebarTriggerTypeEnum, SidebarTypeEnum } from '@shared/enums';
const Toolbar = (props) => {
    const [copyEnabled, setCopyEnabled] = useState(false);
    const [deleteEnabled, setDeleteEnabled] = useState(false);
    const [redoEnabled, setRedoEnabled] = useState(false);
    const [undoEnabled, setUndoEnabled] = useState(false);
    const [speechEnabled, setSpeechEnabled] = useState(false);
    const { triggerToolbar, toggleSidebar } = useActions();
    const { dataTextInfo } = useTypedSelector((store) => store.textInfo);
    if (dataTextInfo && dataTextInfo.chars > 0) {
        setCopyEnabled(true);
        setDeleteEnabled(true);
    }
    else {
        setCopyEnabled(false);
        setDeleteEnabled(false);
    }
    if (dataTextInfo && dataTextInfo.undo) {
        setUndoEnabled(true);
    }
    else {
        setUndoEnabled(false);
    }
    if (dataTextInfo && dataTextInfo.redo) {
        setRedoEnabled(true);
    }
    else {
        setRedoEnabled(false);
    }
    const handleCopyClipboard = () => {
        triggerToolbar(ToolbarActionType.COPY);
    };
    const handleUndo = () => {
        triggerToolbar(ToolbarActionType.UNDO);
    };
    const handleRedo = () => {
        triggerToolbar(ToolbarActionType.REDO);
    };
    const handleClear = () => {
        toggleSidebar(SidebarTypeEnum.HIT_DETAIL, SidebarTriggerTypeEnum.CLOSE);
        triggerToolbar(ToolbarActionType.CLEAR);
    };
    const handleMic = () => {
        setSpeechEnabled(!speechEnabled);
        triggerToolbar(ToolbarActionType.MIC);
    };
    let CopyClipboardButton = React.createElement("span", { className: "svg_container" },
        React.createElement(CopyClipboard, { className: "disabled" }));
    if (copyEnabled) {
        CopyClipboardButton = React.createElement("span", { className: "svg_container enabled" },
            React.createElement(CopyClipboardEnabled, { onClick: handleCopyClipboard }));
    }
    let DeleteButton = React.createElement("span", { className: "svg_container" },
        React.createElement(Delete, { className: "disabled" }));
    if (deleteEnabled) {
        DeleteButton = React.createElement("span", { className: "svg_container enabled" },
            React.createElement(DeleteEnabled, { onClick: handleClear }));
    }
    let RedoButton = React.createElement("span", { className: "svg_container" },
        React.createElement(Redo, { className: "disabled" }));
    if (redoEnabled) {
        RedoButton = React.createElement("span", { className: "svg_container enabled" },
            React.createElement(RedoEnabled, { onClick: handleRedo }));
    }
    let UndoButton = React.createElement("span", { className: "svg_container" },
        React.createElement(Undo, { className: "disabled" }));
    if (undoEnabled) {
        UndoButton = React.createElement("span", { className: "svg_container enabled" },
            React.createElement(UndoEnabled, { onClick: handleUndo }));
    }
    let SpeechButton = React.createElement("span", { className: "svg_container" },
        React.createElement(Mic, { onClick: handleMic }));
    if (speechEnabled) {
        SpeechButton = React.createElement("span", { className: "svg_container enabled" },
            React.createElement(MicEnabled, { onClick: handleMic }));
    }
    if (props.enabled) {
        return (React.createElement("div", { className: "editor_toolbar sticky top-0 z-10" },
            React.createElement("div", { className: "leading-8 h-8 w-full xss:hidden lg:flex" }),
            React.createElement("div", { className: "flex leading-8 lg:h-8 xss:h-14 w-full" },
                React.createElement("div", { className: "lg:hidden xss:flex xss:ml-4 xss:mr-4 sm:mr-10 cursor-pointer items-center" },
                    React.createElement("a", { href: "/" },
                        React.createElement("div", { className: "symbol finnit_checks" },
                            React.createElement(FinnitChecks, null)))),
                React.createElement("div", { className: "undo_button cursor-pointer flex lg:items-start xss:items-center", title: "R\u00FCckg\u00E4ngig" }, UndoButton),
                React.createElement("div", { className: "redo_button cursor-pointer flex lg:items-start xss:items-center", title: "Wiederherstellen" }, RedoButton),
                React.createElement("div", { className: "copy_button cursor-pointer flex lg:items-start xss:items-center", title: "In Zwischenablage kopieren" }, CopyClipboardButton),
                React.createElement("div", { className: "clear_button cursor-pointer flex lg:items-start xss:items-center", title: "Leeren" }, DeleteButton),
                React.createElement("div", { className: "mic_button cursor-pointer flex lg:items-start xss:items-center", title: "Sprache" }, SpeechButton),
                React.createElement("div", { className: "lg:hidden xss:flex items-center ml-auto mr-4" },
                    React.createElement("div", { onClick: () => toggleSidebar(SidebarTypeEnum.RIGHT), className: "cursor-pointer symbol finnit_nav_menu" },
                        React.createElement(FinnitNavMenu, { className: "w-6 h-6" })))),
            React.createElement("div", { className: "editor_toolbar-after" })));
    }
    else {
        return (React.createElement(React.Fragment, null));
    }
};
export default Toolbar;
