import React from 'react';
import SimpleHeader from '@ui/simple_header/simple_header';
import Draft from './draft/draft';
import Office from './office/office';
import Couch from './couch/couch';
import Editor from './editor/editor';
import { useCurrentDimensions } from '@utils/dimensions';
import './landing_page.scss';
import DraftMini from './draft_mini/draft_mini';
import OfficeMini from './office_mini/office_mini';
import EditorMini from './editor_mini/editor_mini';
const LandingPage = () => {
    const dimensions = useCurrentDimensions();
    if (dimensions.width > 768) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "landing_page_div flex" },
                React.createElement(SimpleHeader, { headingText: "Das Analysetool f\u00FCr Ihre Stellenanzeigen" }),
                React.createElement(Draft, null),
                React.createElement(Office, null),
                React.createElement(Couch, null),
                React.createElement(Editor, null))));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "landing_page_div flex" },
                React.createElement(SimpleHeader, { headingText: "Das Analysetool f\u00FCr Ihre Stellenanzeigen" }),
                React.createElement(DraftMini, null),
                React.createElement(OfficeMini, null),
                React.createElement(Couch, null),
                React.createElement(EditorMini, null))));
    }
};
export default LandingPage;
