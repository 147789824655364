import { IgnoreActionType, ReplaceActionType } from '@state/types';
import { ActionEnum, TriggerOriginEnum } from '@shared/enums';
import { submitAction } from '@services/action.service';
import { getLocalStorage } from '@utils/storage';
const initialState = {};
const trackingReducer = (state = initialState, 
// eslint-disable-next-line
action) => {
    let action_type;
    let optional;
    switch (action.type) {
        case ReplaceActionType.PENDING:
            if (action.trigger === TriggerOriginEnum.TOOLTIP) {
                action_type = ActionEnum.TOOLTIP_REPLACE;
            }
            else if (action.trigger === TriggerOriginEnum.DETAIL) {
                action_type = ActionEnum.DETAIL_REPLACE;
            }
            optional = action.payload;
            break;
        case IgnoreActionType.ADD:
            if (action.trigger === TriggerOriginEnum.LIST) {
                action_type = ActionEnum.LIST_IGNORE_ADD;
            }
            else if (action.trigger === TriggerOriginEnum.TOOLTIP) {
                action_type = ActionEnum.TOOLTIP_IGNORE;
            }
            else if (action.trigger === TriggerOriginEnum.DETAIL) {
                action_type = ActionEnum.DETAIL_IGNORE;
            }
            optional = action.payload;
            break;
        case IgnoreActionType.REMOVE:
            if (action.trigger === TriggerOriginEnum.LIST) {
                action_type = ActionEnum.LIST_IGNORE_REMOVE;
            }
            optional = action.payload;
            break;
    }
    if (action_type && action_type.length > 0) {
        try {
            submitAction({ content_hash: getLocalStorage('content_hash'), action_type, optional });
            // eslint-disable-next-line
        }
        catch (exception) {
            // do nothing
        }
    }
    return state;
};
export default trackingReducer;
