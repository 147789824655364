/* eslint-disable no-empty */
import React from 'react';
import SimpleHeader from '@ui/simple_header/simple_header';
import Teaser from './teaser/teaser';
import Gendern from './gendern/gendern';
import GendernMini from './gendern_mini/gendern_mini';
import Motivation from './motivation/motivation';
import SimpleGendern from './simple_gendern/simple_gendern';
import SimpleGendernMini from './simple_gendern_mini/simple_gendern_mini';
import EditorReverted from './editor_reverted/editor_reverted';
import EditorRevertedMini from './editor_reverted_mini/editor_reverted_mini';
import { useCurrentDimensions } from '@utils/dimensions';
const GenderNeutralityPage = () => {
    const dimensions = useCurrentDimensions();
    if (dimensions.width < 1024) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "gender_neutrality_page_div" },
                React.createElement(SimpleHeader, { headingText: "Was bedeutet Gender-Neutralit\u00E4t?" }),
                React.createElement(Teaser, null),
                React.createElement(GendernMini, null),
                React.createElement(Motivation, null),
                React.createElement(SimpleGendernMini, null),
                React.createElement(EditorRevertedMini, null))));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "gender_neutrality_page_div" },
                React.createElement(SimpleHeader, { headingText: "Was bedeutet Gender-Neutralit\u00E4t?" }),
                React.createElement(Teaser, null),
                React.createElement(Gendern, null),
                React.createElement(Motivation, null),
                React.createElement(SimpleGendern, null),
                React.createElement(EditorReverted, null))));
    }
};
export default GenderNeutralityPage;
