import React, { useEffect, useRef, useState } from 'react';
import { useActions } from '@hooks/use_actions';
import { DeviceTypeEnum, IgnoreTypeEnum, ModalTypeEnum, PreparationTypeEnum, SidebarTypeEnum, TooltipTriggerEnum, TriggerOriginEnum, } from '@shared/enums';
import Feedback from '@assets/icons/feedback.svg';
import Ignore from '@assets/icons/ignore.svg';
import { ModalActionType } from '@state/types';
import Replacement from '@ui/replacement/replacement';
import SimpleText from '@ui/simple_text/simple_text';
import Question from '@assets/icons/question.svg';
import { useDetectKeyboardOpen } from '@utils/use_detect_keyboard_open';
import { hideVirtualKeyboard } from '@utils/hide_virtual_keyboard';
import '@component/analyzer/editor/plugins/holylight/holylight_tooltip_plugin.scss';
import { useCurrentDimensions } from '@utils/dimensions';
import { useCurrentDeviceType } from '@utils/device_type';
const HolylightTooltipMobilePlugin = (props) => {
    const dimensions = useCurrentDimensions();
    const deviceType = useCurrentDeviceType();
    const isKeyboardOpen = useDetectKeyboardOpen(true);
    if (isKeyboardOpen && dimensions.width < 1024 && deviceType !== DeviceTypeEnum.DESKTOP) {
        hideVirtualKeyboard();
    }
    // eslint-disable-next-line
    const tooltipRef = useRef(null);
    let tooltip = React.createElement("div", { className: 'tooltip' + (props.mobile === true ? ' mobile' : ''), ref: tooltipRef });
    const [moreReplsActive, setMoreReplsActive] = useState(false);
    const [activatedHitRepls, setActivatedHitRepls] = useState([]);
    // problem?: hit_id can change, if text changes before hit
    // hit_id has format: blockKey + startIdx
    // so if startIdx changes and user will open tooltip again, need activate more repls again
    if (props.hit && !activatedHitRepls.includes(props.hit.hit_id)) {
        setMoreReplsActive(false);
    }
    else if (props.hit && activatedHitRepls.includes(props.hit.hit_id)) {
        setMoreReplsActive(true);
    }
    const { triggerPreparation, triggerModal, toggleSidebar, toggleTooltip } = useActions();
    const ignoreHit = () => {
        if (props.nodeKey) {
            toggleTooltip(TooltipTriggerEnum.CLOSE, {
                nodeKey: props.nodeKey,
                hit: props.hit,
            }, true);
        }
        triggerPreparation(PreparationTypeEnum.IGNORE, {
            triggerOriginEnum: TriggerOriginEnum.TOOLTIP,
            ignoreTypeEnum: IgnoreTypeEnum.WORD,
            hit: props.hit,
            hitId: props.hit?.hit_id,
            nodeKey: props.nodeKey,
        });
    };
    const feedbackHit = () => {
        if (props.nodeKey) {
            toggleTooltip(TooltipTriggerEnum.CLOSE, {
                nodeKey: props.nodeKey,
                hit: props.hit,
            }, true);
            triggerModal(ModalTypeEnum.FEEDBACK, ModalActionType.OPEN, props.hit);
        }
    };
    const showIgnoreList = () => {
        if (props.nodeKey) {
            toggleTooltip(TooltipTriggerEnum.CLOSE, {
                nodeKey: props.nodeKey,
                hit: props.hit,
            }, true);
            toggleSidebar(SidebarTypeEnum.RIGHT);
            document.getElementById('ignored_list')?.classList.contains('block') ?
                '' : document.getElementById('ignored_list_toggle_button')?.click();
        }
    };
    const activateMoreReplacements = () => {
        const elements = tooltipRef.current?.base?.querySelectorAll('.inactive') ||
            tooltipRef.current?.querySelectorAll('.inactive');
        elements.forEach((element) => {
            element.classList.remove('inactive');
        });
        setMoreReplsActive(true);
        if (props.hit) {
            activatedHitRepls?.push(props.hit.hit_id);
            setActivatedHitRepls(activatedHitRepls);
        }
    };
    if (props.enabled === true &&
        props.hit &&
        props.typeTrigger === TooltipTriggerEnum.OPEN &&
        props.hit.ignore !== true &&
        props.mobile === true) {
        tooltip =
            React.createElement(React.Fragment, null,
                React.createElement("button", { draggable: "true", onClick: () => toggleTooltip(TooltipTriggerEnum.CLOSE, { nodeKey: '' }, true), className: "lg:hidden z-10 button w-full h-16 p-0 click_panel subtitle xss:block" },
                    React.createElement("div", { className: "px-8 text-left" },
                        React.createElement(SimpleText, { color: "#33cccc", text: "Begriff ersetzen mit..." }))),
                React.createElement("hr", null),
                React.createElement("div", { ref: tooltipRef, id: props.nodeKey, className: "tooltip mobile" },
                    React.createElement("ul", { className: "replacements" },
                        props.hit.repls &&
                            props.hit.repls.map((replacement, index) => {
                                if (props.nodeKey && props.hit) {
                                    const replace = React.createElement(Replacement, { additionalClassname: "mobile", nodeKey: props.nodeKey, hit: props.hit, replacementString: replacement.repl, replacementId: replacement.repl_id, replacementNumerus: replacement.repl_numerus, tooltip: true });
                                    if (index < 3 || moreReplsActive === true) {
                                        return replace;
                                    }
                                    else {
                                        // eslint-disable-next-line
                                        replace.props.additionalClassname = 'mobile inactive';
                                        return replace;
                                    }
                                }
                            }),
                        moreReplsActive === false && props.hit.repls && props.hit.repls.length > 3 ?
                            React.createElement(React.Fragment, null,
                                React.createElement("hr", { className: "ml-8" }),
                                React.createElement("li", { onClick: activateMoreReplacements, contentEditable: false, className: "replacement in_tooltip activate mobile" },
                                    React.createElement("span", { className: "ml-6" },
                                        "+",
                                        props.hit.repls.length - 3,
                                        " weitere Vorschl\u00E4ge anzeigen"))) : '',
                        React.createElement("hr", { className: "ml-8" }),
                        React.createElement("li", { onClick: ignoreHit, contentEditable: false, className: "flex flex-row replacement in_tooltip ignore mobile" },
                            React.createElement("div", { className: "symbol ignore mr-2" },
                                React.createElement(Ignore, null)),
                            React.createElement("span", null, "Diesen Begriff nicht markieren")),
                        React.createElement("hr", { className: "ml-8" }),
                        React.createElement("li", { onClick: feedbackHit, contentEditable: false, className: "flex flex-row replacement in_tooltip feedback mobile" },
                            React.createElement("div", { className: "symbol feedback mr-2" },
                                React.createElement(Feedback, null)),
                            React.createElement("span", null, "Ich m\u00F6chte Feedback geben")))));
        if (tooltipRef.current &&
            props.enabled &&
            tooltipRef.current.parentElement &&
            tooltipRef.current.parentElement.classList.contains('loaded')) {
            tooltipRef.current.style.display = 'block';
            // eslint-disable-next-line
            navigator.virtualKeyboard.hide();
            hideVirtualKeyboard();
            setTimeout(() => {
                tooltipRef.current.parentElement.classList.add('really');
            }, 500);
        }
    }
    else if (props.enabled === true &&
        props.hit &&
        props.typeTrigger === TooltipTriggerEnum.OPEN &&
        props.hit.ignore === true &&
        props.mobile === true) {
        tooltip = React.createElement("div", { ref: tooltipRef, id: props.nodeKey, className: "tooltip mobile" },
            React.createElement("ul", { contentEditable: false, className: 'replacements' },
                React.createElement("li", { contentEditable: false, className: "flex flex-col feedback_new in_tooltip feedback p-4" },
                    React.createElement(SimpleText, { text: "Dieser Begriff ist bereits in den nicht markierten Begriffen." }),
                    React.createElement("button", { onClick: showIgnoreList, className: "button grey" },
                        React.createElement("div", { className: "flex flex-row" },
                            React.createElement("div", { className: "symbol question mr-2" },
                                React.createElement(Question, null)),
                            "Nicht markierte Begriffe anzeigen")))));
        if (tooltipRef.current) {
            tooltipRef.current.style.display = 'block';
        }
    }
    setTimeout(() => {
        let tooltipRefClientHeight = tooltipRef.current.clientHeight;
        const tooltipRefParent = tooltipRef.current.parentElement;
        if (props.enabled) {
            if (tooltipRefClientHeight > 0) {
                if (isKeyboardOpen || props.touch)
                    tooltipRefClientHeight += 99;
                const offset = deviceType !== DeviceTypeEnum.DESKTOP && dimensions.width < 1024 ? 64 : 0;
                tooltipRefParent.style.top = `calc(100vh - ${tooltipRefClientHeight + offset}px`;
            }
        }
        else {
            tooltipRefParent.style.top = 'calc(100vh - 120px)';
            // eslint-disable-next-line
            navigator?.virtualKeyboard?.show();
        }
    }, 50);
    useEffect(() => {
        function handleScroll() {
            let tooltipRefClientHeight = tooltipRef.current.clientHeight - tooltipRef.current.scrollHeight;
            const tooltipRefParent = tooltipRef.current.parentElement;
            if (props.enabled) {
                if (tooltipRefClientHeight > 0) {
                    if (isKeyboardOpen || props.touch)
                        tooltipRefClientHeight += 99;
                    const offset = deviceType !== DeviceTypeEnum.DESKTOP && dimensions.width < 1024 ? 64 : 0;
                    tooltipRefParent.style.top = `calc(100vh - ${tooltipRefClientHeight + offset}px`;
                }
            }
        }
        window.addEventListener('scroll', handleScroll);
    });
    return (tooltip);
};
export { HolylightTooltipMobilePlugin };
