import React from 'react';
import FinnitDash from '@assets/icons/dash.svg';
import FinnitStar from '@assets/icons/star.svg';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import FinnitChecksUpsideDown from '@assets/icons/finnit_checks_upsidedown.svg';
import './simple_gendern.scss';
import SimpleTitle from '@ui/simple_title/simple_title';
import SimpleText from '@ui/simple_text/simple_text';
const SimpleGendern = () => {
    return (React.createElement("article", { className: "content simple_gendern xl:px-0 lg:px-8" },
        React.createElement("div", { className: "flex mx-auto max-w-6xl pt-8 pb-10" },
            React.createElement("div", { className: "left flex 2xl:max-w-[535px] xl:max-w-[460px]\n\t\t\t\t\tlg:max-w-[400px] xss:justify-center lg:justify-start flex-col\n\t\t\t\t\tlg:mt-28 xss:mt-8 xss:max-w-full md:px-0 xss:px-8" },
                React.createElement("div", { className: "lg:max-w-[372px]" },
                    React.createElement(SimpleTitle, { light: true, sub: true, text: "Gendern kann sehr einfach sein." }),
                    React.createElement(SimpleText, { text: 'Von vielen Seiten wird das Gendern noch als zu umständlich und \
					 	zudem auch unverständlich empfunden. „Genderwahn“ oder auch „Genderunfug“ \
						sind nicht gerade selten im Sprachgebrauch der davon betroffenen Personen.\
          	Dabei muss es letztlich gar nicht kompliziert sein.\
          	Das Umformulieren oder besser der kreative Umgang mit der eigenen Sprache \
						ermöglicht oftmals ein effektives Vermeiden von umständlichen, schwerfälligen \
						und nahezu unverständlichen Formulierungen.' })),
                React.createElement("div", { className: "symbol dash lg:mt-20 xss:hidden lg:block\n\t\t\t\t\t\t\t2xl:ml-[-173px] xl:ml-0" },
                    React.createElement(FinnitDash, null))),
            React.createElement("div", { className: "right lg:flex-row lg:justify-start xss:justify-center flex\n\t\t\t\t\tlg:p-0 xss:ml-0 xss:p-8 3xl:ml-24 2xl:ml-24 xl:ml-14 lg:ml-0 md:ml-0" },
                React.createElement("div", { className: "symbol dash xss:hidden sm:block xl:mt-[475px]\n\t\t\t\t\t\tlg:mt-[420px] xss:mt-[420px]" },
                    React.createElement(FinnitDash, null)),
                React.createElement("div", { className: "finnit_square sm:ml-[-27px] xss:ml-[-30px] s:mt-1 xss:p-2\n\t\t\t\t\t\txl:w-[256px] xl:h-[565px] s:w-[200px] s:h-[441px] xss:w-[150px] xss:h-[331px]" }),
                React.createElement("div", { className: "image office xl:ml-[-202px] lg:ml-[-147px] s:ml-[-147px] xss:ml-[-137px]\n\t\t\t\t\t\ts:mt-[3rem] xss:mt-3 xl:max-h-[565px] s:max-h-[441px] xss:max-h-[331px] " },
                    React.createElement("img", { className: "xl:max-w-[256px] xl:max-h-[565px] s:max-w-[200px] s:max-h-[441px]\n\t\t\t\t\t\t\txss:max-w-[150px] xss:max-h-[331px]", src: '/public/assets/img/office_zwei.jpg' })),
                React.createElement("div", { className: "sub" },
                    React.createElement("div", { className: "symbol sm:block xss:hidden star" },
                        React.createElement(FinnitStar, null)),
                    React.createElement("div", { className: "image woman sm:mt-5 s:mt-24 xss:mt-8 s:ml-8 xss:ml-4" },
                        React.createElement("img", { className: "xl:max-w-[149px] xl:max-h-[640px] m:max-w-[130px] m:max-h-[558px]\n\t\t\t\t\t\t\t\ts:max-w-[110px] s:max-h-[472px] xss:max-w-[90px] xss:max-h-[344px]", src: '/public/assets/img/gendern_einfach_zwei.jpg' }))),
                React.createElement("div", { className: "sub" },
                    React.createElement("div", { className: "symbol two_points sm:block xss:hidden sm:mt-24\n\t\t\t\t\t\t\tsm:ml-8 xss:mt-0" },
                        React.createElement(FinnitTwoPoints, null)),
                    React.createElement("div", { className: "symbol checks_upside_downs m:block xss:hidden m:max-w-[259px]\n\t\t\t\t\t\t\tm:max-h[200px] sm:max-w-[222px] xss:mt-60 xss:ml-[-150px]\n\t\t\t\t\t\t\tm:ml-[-200px]" },
                        React.createElement(FinnitChecksUpsideDown, null)))))));
};
export default SimpleGendern;
