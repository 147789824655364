/**
 * Returns the relevant node in dom by lexicalKey
 */
// eslint-disable-next-line
export function getDOMNodeByKey(targetKey, node) {
    // eslint-disable-next-line
    const editorDOMNode = document.querySelectorAll('div[data-lexical-editor]')[0];
    const editorKey = editorDOMNode.__lexicalEditor._key;
    const lexicalKey = '__lexicalKey_' + editorKey;
    if (!node) {
        node = editorDOMNode;
    }
    if (targetKey === 'root') {
        return editorDOMNode;
    }
    if (node[lexicalKey] && node[lexicalKey] === targetKey) {
        return node;
    }
    for (let i = 0; i < node.childNodes.length; i++) {
        const child = getDOMNodeByKey(targetKey, node.childNodes[i]);
        if (child)
            return child;
    }
    return null;
}
