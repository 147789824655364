import React from 'react';
import { config } from '@config/config';
import FinnitLogo from '@assets/logo.svg';
import FinnitLogoInverted from '@assets/logo_inverted.svg';
const Logo = (props) => {
    const openStart = () => {
        window.location.href = config.FINNIT_FRONTEND_URL;
    };
    let finnitLogo = React.createElement(FinnitLogo, null);
    if (props.inverted) {
        finnitLogo = React.createElement(FinnitLogoInverted, null);
    }
    return (React.createElement("div", { className: "w-71" },
        React.createElement("a", { className: "cursor-pointer", onClick: openStart }, finnitLogo)));
};
export default Logo;
