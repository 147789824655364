import React from 'react';
import './dots_bounce.scss';
const DotsBounce = () => {
    return (React.createElement("div", { className: "dots_bounce overflow dark" },
        React.createElement("div", { className: "circle-line" },
            React.createElement("div", { className: "circle" }, "\u00A0"),
            React.createElement("div", { className: "circle" }, "\u00A0"),
            React.createElement("div", { className: "circle" }, "\u00A0"))));
};
export default DotsBounce;
