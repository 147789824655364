import React from 'react';
import { HitLevel } from '@shared/interfaces/analyzer';
import { useCurrentDimensions } from '@utils/dimensions';
import Hit from '@ui/hit/hit';
import { config } from '@config/config';
const HitList = (props) => {
    const dimensions = useCurrentDimensions();
    const heightTablet = 'h-[19rem]';
    const height = (dimensions.height > config.HEIGHT_BREAKPOINT ? 'max-h-[26rem] h-auto' : heightTablet);
    return (React.createElement("ul", { className: 'overflow-y-auto overflow_scroll ' + height }, props.hits.map((hit, index) => {
        if (hit.level !== HitLevel.NEW && hit.ignore !== true) {
            return React.createElement(Hit, { key: hit.hit_id, hit: hit, index: index, activeId: props.activeId });
        }
    })));
};
export default HitList;
