/**
 * Util for setting correct selection in editor
 * after extending, reverting and converting HolylightNode
 */
import { selectionMove } from './selection_move';
import { findTextKeys } from '../plugins/holylight/index';
import { nodeSubtreeBuild } from './node_subtree_build';
import { $isHolylightNode } from '../nodes/holylight_node';
/**
 * Set correct selection after revert HolylightNode (after removal of HolylightNode)
 */
export function setCorrectSelectionAfterRevertHoly(editor, selection, nodeToReplaceKey, selInfo, textNodes, selectionIndex = 0) {
    if (selectionIndex !== -1) {
        if (nodeToReplaceKey === selInfo.selectionAnchorKey) {
            const previousSibling = textNodes[0].getPreviousSibling();
            if (previousSibling && previousSibling.getFormat() === textNodes[0].getFormat()) {
                // previous textnode and new textnode have same format, so lexical will merge them
                // use key of previous textnode as selectionkey
                const previousSiblingKey = previousSibling.getKey();
                selInfo.selectionAnchorOffset = previousSibling.getTextContentSize() + selInfo.selectionAnchorOffset;
                selInfo.selectionFocusOffset = previousSibling.getTextContentSize() + selInfo.selectionFocusOffset;
                selectionMove(editor, selection, previousSiblingKey, previousSiblingKey, selInfo.selectionAnchorOffset, selInfo.selectionFocusOffset, 'text');
            }
            else {
                const textNodeKey = textNodes[0].getKey();
                selectionMove(editor, selection, textNodeKey, textNodeKey, selInfo.selectionAnchorOffset, selInfo.selectionFocusOffset, 'text');
            }
        }
        else {
            if (selInfo.selectionAnchorOffset === 0 && selInfo.selectionFocusOffset === 0) {
                const previousSibling = textNodes[selectionIndex].getPreviousSibling();
                if (previousSibling) {
                    const previousSiblingKey = previousSibling.getKey();
                    const previousSiblingTextContentSize = previousSibling.getTextContentSize();
                    selectionMove(editor, selection, previousSiblingKey, previousSiblingKey, previousSiblingTextContentSize, previousSiblingTextContentSize, 'text');
                }
                else {
                    const textNodeKey = textNodes[selectionIndex].getKey();
                    const textNodeTextContentSize = textNodes[selectionIndex].getTextContentSize();
                    selectionMove(editor, selection, textNodeKey, textNodeKey, textNodeTextContentSize, textNodeTextContentSize, 'text');
                }
            }
            else {
                selectionMove(editor, selection, textNodes[selectionIndex].getKey(), textNodes[selectionIndex].getKey(), selInfo.selectionAnchorOffset, selInfo.selectionFocusOffset, 'text');
            }
        }
    }
}
/**
 * Set correct selection after extend an existing HolylightNode
 */
export function setCorrectSelectionAfterExtendHoly(editor, selection, selectionAnchorKey, selectionAnchorOffset, selectionFocusOffset) {
    selectionMove(editor, selection, selectionAnchorKey, selectionAnchorKey, selectionAnchorOffset, selectionFocusOffset, 'text');
}
/**
 * Set correct selection after convert to HolylightNode
 */
export function setCorrectSelectionAfterConvertHoly(editor, selection, nodeToReplaceKeys, selInfo, textNodes, selectionIndex = 0, allPreviousSiblingsContentSizes = 0) {
    selInfo.selectionAnchorOffset += allPreviousSiblingsContentSizes;
    selInfo.selectionFocusOffset += allPreviousSiblingsContentSizes;
    if (nodeToReplaceKeys.includes(selInfo.selectionAnchorKey)) {
        let anchorParent = textNodes[selectionIndex].getParent();
        let anchorKey = selInfo.selectionAnchorKey;
        if (textNodes[0].getKey() != selInfo.selectionAnchorKey) {
            if ($isHolylightNode(anchorParent)) {
                anchorParent = anchorParent.getParent();
            }
            const anchorParentSubtree = anchorParent ? nodeSubtreeBuild(anchorParent) : undefined;
            if (anchorParentSubtree) {
                const anchorKeys = findTextKeys(anchorParentSubtree, selInfo.selectionAnchorOffset, selInfo.selectionFocusOffset);
                anchorKey = anchorKeys[0];
                let contentSizes = 0;
                anchorParentSubtree.forEach((subtree) => {
                    if (subtree.key === anchorKey) {
                        selInfo.selectionAnchorOffset -= contentSizes;
                        selInfo.selectionFocusOffset -= contentSizes;
                    }
                    else {
                        contentSizes = subtree.endIdx;
                    }
                });
            }
        }
        selectionMove(editor, selection, anchorKey, anchorKey, selInfo.selectionAnchorOffset, selInfo.selectionFocusOffset, 'text');
    }
    else {
        const anchorKey = (selectionIndex >= 0 && textNodes[selectionIndex].getKey()) || selInfo.selectionAnchorKey;
        selectionMove(editor, selection, anchorKey, anchorKey, selInfo.selectionAnchorOffset, selInfo.selectionFocusOffset, 'text');
    }
}
