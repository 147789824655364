import React from 'react';
import CorrectCheck from '@assets/sidebar/check.svg';
import DotsBounce from '@ui/loading/dots_bounce/dots_bounce';
import './box.scss';
export var BoxMode;
(function (BoxMode) {
    BoxMode["HIT"] = "hit";
    BoxMode["LOADING"] = "loading";
    BoxMode["CORRECT"] = "correct";
})(BoxMode || (BoxMode = {}));
const Box = (props) => {
    const BoxAnimation = (props.mode === BoxMode.LOADING ? React.createElement(DotsBounce, null) : React.createElement(CorrectCheck, null));
    return (React.createElement("div", { className: 'flex flex-row justify-center items-center box h-8 leading-8 mt-4 xss:w-full ' + props.mode }, props.mode === BoxMode.HIT && props.text ? props.text : BoxAnimation));
};
export default Box;
