import { config } from '@config/config';
import { createFingerprint } from '@utils/create_fingerprint';
import { toast } from 'react-toastify';
var ErrorEnum;
(function (ErrorEnum) {
    ErrorEnum["INVALID"] = "invalid";
    ErrorEnum["REQUIRED"] = "required";
})(ErrorEnum || (ErrorEnum = {}));
// eslint-disable-next-line
const convertErrorsToMessage = (errors) => {
    let errorsMessage = '';
    switch (errors.title) {
        case ErrorEnum.REQUIRED:
            errorsMessage += ' Anrede/Name fehlt.';
            break;
    }
    switch (errors.email) {
        case ErrorEnum.INVALID:
            errorsMessage += ' E-Mail fehlerhaft.';
            break;
        case ErrorEnum.REQUIRED:
            errorsMessage += ' E-Mail fehlt.';
            break;
    }
    switch (errors.body) {
        case ErrorEnum.REQUIRED:
            errorsMessage += ' Nachricht fehlt.';
            break;
    }
    return errorsMessage;
};
export const requestContact = (contactRequest) => {
    const fingerprint = createFingerprint();
    const formData = new FormData();
    formData.append('fingerprint', fingerprint);
    const request = new XMLHttpRequest();
    request.open('POST', config.CONTACT_API_URL);
    request.send(formData);
    request.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            const pre = JSON.parse(this.responseText);
            const body = `Es wurde das Kontaktformular mit folgenden Daten abgeschickt: \n\n
                    Firma: ${contactRequest.company}\n
                    E-Mail: ${contactRequest.mail}\n
                    Tel.: ${contactRequest.phone}\n\n
                    ${contactRequest.message}`;
            const title = 'Finnit_Kontaktformular';
            const apiFormData = new FormData();
            apiFormData.append('name', `${contactRequest.title} ${contactRequest.name}`);
            apiFormData.append('email', contactRequest.mail);
            apiFormData.append('title', title);
            apiFormData.append('body', body);
            apiFormData.append('fingerprint', fingerprint);
            apiFormData.append('token', pre.token);
            const apiRequest = new XMLHttpRequest();
            apiRequest.open('POST', pre.endpoint);
            apiRequest.send(apiFormData);
            apiRequest.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    const response = JSON.parse(this.responseText);
                    if (response.errors) {
                        const errorsMessage = convertErrorsToMessage(response.errors);
                        toast.error('Beim Senden Ihrer Kontaktanfrage ist ein Fehler aufgetreten. ' + errorsMessage);
                    }
                    else if (response.ticket) {
                        toast.success('Ihre Kontaktanfrage wurde erfolgreich verschickt.');
                    }
                }
            };
        }
    };
};
