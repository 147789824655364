import { BlocksActionType } from '@state/types';
const initialState = {
    errorBlocks: null,
    dataBlocks: null,
};
const blocksReducer = (state = initialState, action = { type: BlocksActionType.UPDATED, payload: null }) => {
    switch (action.type) {
        case BlocksActionType.UPDATED:
            return { errorBlocks: null, dataBlocks: action.payload };
        case BlocksActionType.ERROR:
            return { errorBlocks: action.payload, dataBlocks: null };
        default:
            return state;
    }
};
export default blocksReducer;
