export const lexicalTheme = {
    ltr: 'lexical-ltr',
    rtl: 'lexical-rtl',
    text: {
        base: 'lexical-base',
        bold: 'lexical-bold',
        underline: 'lexical-underline',
        strikethrough: 'lexical-strikethrough',
        underlineStrikethrough: 'lexical-underline-strikethrough',
        italic: 'lexical-italic',
        code: 'lexical-code',
        subscript: 'lexical-subscript',
        superscript: 'lexical-superscript',
    },
    paragraph: 'lexical-paragraph',
    image: 'lexical-image',
    characterLimit: 'lexical-characterLimit',
    list: {
        ul: 'lexical-ul',
        ulDepth: ['lexical-ul-depth'],
        ol: 'lexical-ol',
        olDepth: ['lexical-ol-depth'],
        listitem: 'lexical-listitem',
        listitemChecked: 'lexical-listitem-checked',
        listitemUnchecked: 'lexical-listitem-unchecked',
        nested: {
            list: 'lexical-nested-list',
            listitem: 'lexical-nest-listitem',
        },
    },
    table: 'lexical-table',
    tableRow: 'lexical-table-row',
    tableCell: 'lexical-table-cell',
    tableCellHeader: 'lexical-table-cell-header',
    mark: 'lexical-mark',
    markOverlap: 'lexical-mark-overlap',
    link: 'lexical-link',
    quote: 'lexical-quote',
    code: 'lexical-code',
    codeHighlight: {
        atrule: 'lexical-tokenAttr',
        attr: 'lexical-tokenAttr',
        boolean: 'lexical-tokenProperty',
        builtin: 'lexical-tokenSelector',
        cdata: 'lexical-tokenComment',
        char: 'lexical-tokenSelector',
        class: 'lexical-tokenFunction',
        'class-name': 'lexical-tokenFunction',
        comment: 'lexical-tokenComment',
        constant: 'lexical-tokenProperty',
        deleted: 'lexical-tokenProperty',
        doctype: 'lexical-tokenComment',
        entity: 'lexical-tokenOperator',
        function: 'lexical-tokenFunction',
        important: 'lexical-tokenVariable',
        inserted: 'lexical-tokenSelector',
        keyword: 'lexical-tokenAttr',
        namespace: 'lexical-tokenVariable',
        number: 'lexical-tokenProperty',
        operator: 'lexical-tokenOperator',
        prolog: 'lexical-tokenComment',
        property: 'lexical-tokenProperty',
        punctuation: 'lexical-tokenPunctuation',
        regex: 'lexical-tokenVariable',
        selector: 'lexical-tokenSelector',
        string: 'lexical-tokenSelector',
        symbol: 'lexical-tokenProperty',
        tag: 'lexical-tokenProperty',
        url: 'lexical-tokenOperator',
        variable: 'lexical-tokenVariable',
    },
    hashtag: 'lexical-hashtag',
    heading: {
        h1: 'lexical-h1',
        h2: 'lexical-h2',
        h3: 'lexical-h3',
        h4: 'lexical-h4',
        h5: 'lexical-h5',
        h6: 'lexical-h6',
    },
    holylight: 'lexical-holylight',
    root: 'lexical-root',
    embedBlock: {
        base: 'lexical-embedBlock',
    },
};
