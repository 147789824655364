import { ModalActionType } from '@state/types';
import { ModalTypeEnum } from '@shared/enums';
const initialState = {
    triggeredModal: undefined,
};
const modalReducer = (state = initialState, action = {
    modal: ModalTypeEnum.FEEDBACK,
    type: ModalActionType.OPEN
}) => {
    switch (action.type) {
        case ModalActionType.OPEN:
            return { triggeredModal: true, typeModal: action.modal, typeTrigger: action.type, hitModal: action.payload };
        case ModalActionType.CLOSE:
            return { triggeredModal: true, typeModal: action.modal, typeTrigger: action.type, hitModal: action.payload };
        case ModalActionType.OPENED:
            return { triggeredModal: false, typeTrigger: action.type };
        case ModalActionType.CLOSED:
            return { triggeredModal: false, typeTrigger: action.type };
        default:
            return state;
    }
};
export default modalReducer;
