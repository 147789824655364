export var AnalyzerActionType;
(function (AnalyzerActionType) {
    AnalyzerActionType["REQUEST"] = "analyzer_request";
    AnalyzerActionType["SUCCESS"] = "analyzer_success";
    AnalyzerActionType["DONE"] = "analyzer_done";
    AnalyzerActionType["ERROR"] = "analyzer_error";
})(AnalyzerActionType || (AnalyzerActionType = {}));
export var BlocksActionType;
(function (BlocksActionType) {
    BlocksActionType["UPDATED"] = "blocks_updated";
    BlocksActionType["ERROR"] = "blocks_error";
})(BlocksActionType || (BlocksActionType = {}));
export var DemoActionType;
(function (DemoActionType) {
    DemoActionType["TRIGGER"] = "demo_trigger";
    DemoActionType["TRIGGERED"] = "demo_triggered";
})(DemoActionType || (DemoActionType = {}));
export var FeedbackActionType;
(function (FeedbackActionType) {
    FeedbackActionType["REQUEST"] = "feedback_request";
    FeedbackActionType["SUCCESS"] = "feedback_success";
    FeedbackActionType["ERROR"] = "feedback_error";
    FeedbackActionType["DONE"] = "feedback_done";
})(FeedbackActionType || (FeedbackActionType = {}));
export var ReplaceActionType;
(function (ReplaceActionType) {
    ReplaceActionType["PENDING"] = "replace_pending";
    ReplaceActionType["FINISHED"] = "replace_finished";
    ReplaceActionType["ERROR"] = "replace_error";
})(ReplaceActionType || (ReplaceActionType = {}));
export var PrepareActionType;
(function (PrepareActionType) {
    PrepareActionType["PENDING"] = "prepare_pending";
    PrepareActionType["FINISHED"] = "prepare_finished";
    PrepareActionType["ERROR"] = "prepare_error";
})(PrepareActionType || (PrepareActionType = {}));
export var DetailActionType;
(function (DetailActionType) {
    DetailActionType["REQUEST"] = "detail_request";
    DetailActionType["OPENED"] = "detail_opened";
    DetailActionType["ERROR"] = "detail_error";
})(DetailActionType || (DetailActionType = {}));
export var DetailMobileActionType;
(function (DetailMobileActionType) {
    DetailMobileActionType["REQUEST"] = "detail_mobile_request";
    DetailMobileActionType["OPENED"] = "detail_mobile_opened";
})(DetailMobileActionType || (DetailMobileActionType = {}));
export var HolylightActionType;
(function (HolylightActionType) {
    HolylightActionType["STARTING"] = "holylight_starting";
    HolylightActionType["FINISHED"] = "holylight_finished";
})(HolylightActionType || (HolylightActionType = {}));
export var IgnoreActionType;
(function (IgnoreActionType) {
    IgnoreActionType["ADD"] = "ignore_add";
    IgnoreActionType["ADDED"] = "ignored_added";
    IgnoreActionType["REMOVE"] = "ignore_remove";
    IgnoreActionType["REMOVED"] = "ignored_removed";
})(IgnoreActionType || (IgnoreActionType = {}));
export var HitsActionType;
(function (HitsActionType) {
    HitsActionType["UPDATED"] = "hits_updated";
    HitsActionType["ERROR"] = "hits_error";
    HitsActionType["DONE"] = "hits_done";
})(HitsActionType || (HitsActionType = {}));
export var ModalActionType;
(function (ModalActionType) {
    ModalActionType["OPEN"] = "modal_open";
    ModalActionType["OPENED"] = "modal_opened";
    ModalActionType["CLOSE"] = "modal_close";
    ModalActionType["CLOSED"] = "modal_closed";
})(ModalActionType || (ModalActionType = {}));
export var TextInfoActionType;
(function (TextInfoActionType) {
    TextInfoActionType["UPDATED"] = "text_info_updated";
    TextInfoActionType["ERROR"] = "text_info_error";
})(TextInfoActionType || (TextInfoActionType = {}));
export var ToolbarActionType;
(function (ToolbarActionType) {
    ToolbarActionType["UNDO"] = "undo";
    ToolbarActionType["REDO"] = "redo";
    ToolbarActionType["CLEAR"] = "clear";
    ToolbarActionType["COPY"] = "copy";
    ToolbarActionType["MIC"] = "mic";
    ToolbarActionType["FINISHED"] = "finished";
})(ToolbarActionType || (ToolbarActionType = {}));
export var SidebarActionType;
(function (SidebarActionType) {
    SidebarActionType["TOGGLE"] = "sidebar_toggle";
    SidebarActionType["TOGGLED"] = "sidebar_toggled";
})(SidebarActionType || (SidebarActionType = {}));
export var ThemeActionType;
(function (ThemeActionType) {
    ThemeActionType["TOGGLE"] = "theme_toggle";
    ThemeActionType["TOGGLED"] = "theme_toggled";
})(ThemeActionType || (ThemeActionType = {}));
export var TooltipActionType;
(function (TooltipActionType) {
    TooltipActionType["TOGGLE"] = "tooltip_toggle";
    TooltipActionType["TOGGLED"] = "tooltip_toggled";
})(TooltipActionType || (TooltipActionType = {}));
