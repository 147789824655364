function isInViewPort(element) {
    const bounding = element.getBoundingClientRect();
    return (bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight));
}
// eslint-disable-next-line
export function scrollToElement(type, elementOrQueryselector, offset = 84) {
    let activeElementEditor;
    if (type === 'querySelector') {
        activeElementEditor = document.querySelector(elementOrQueryselector);
    }
    else {
        activeElementEditor = elementOrQueryselector;
    }
    if (activeElementEditor) {
        const position = activeElementEditor.getBoundingClientRect().top;
        const offsetPosition = position + window.scrollY - offset;
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
}
// eslint-disable-next-line
export function scrollToElementIfNeeded(type, elementOrQueryselector) {
    let activeElementEditor;
    if (type === 'querySelector') {
        activeElementEditor = document.querySelector(elementOrQueryselector);
    }
    else {
        activeElementEditor = elementOrQueryselector;
    }
    if (activeElementEditor) {
        if (!isInViewPort(activeElementEditor)) {
            const position = activeElementEditor.getBoundingClientRect().top;
            const offsetPosition = position + window.scrollY - 84;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }
    }
}
