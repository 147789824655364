import React from 'react';
import Heading from '@ui/heading/heading';
import Logo from '@ui/logo/logo';
import Nav from '@ui/nav/nav';
import SimpleSeparator from '@assets/simple_separator.svg';
import './simple_header.scss';
const SimpleHeader = (props) => {
    return (React.createElement("header", { className: "z-10 header h-20 flex flex-row w-full my-0 mx-auto items-center fixed bg-white" },
        React.createElement("div", { className: "flex flex-row items-center my-0 xl:px-0 mx-auto xl:max-w-6xl \n\t\t\t\t\tlg:max-w-5xl m:max-w-full\n\t\t\t\t\txss:max-w-full xss:w-full xss:px-8" },
            React.createElement("div", { className: "logo_div flex justify-start p-0.5" },
                React.createElement(Logo, { inverted: false })),
            React.createElement("div", { className: "flex justify-start my-0 mx-10 xss:hidden md:flex" },
                React.createElement(SimpleSeparator, null)),
            React.createElement("div", { className: "justify-start p-0.5 xss:hidden xl:flex md:max-w-[24rem] md:w-[24rem]" },
                React.createElement(Heading, { text: props.headingText })),
            React.createElement("nav", { className: "nav_div" },
                React.createElement(Nav, null)))));
};
export default SimpleHeader;
