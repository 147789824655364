import { HolylightActionType } from '@state/types';
const initialState = {
    pendingHolylight: true,
};
const holylightReducer = (state = initialState, action = { type: HolylightActionType.FINISHED }) => {
    switch (action.type) {
        case HolylightActionType.STARTING:
            return { pendingHolylight: true, dataHolylight: action.payload };
        case HolylightActionType.FINISHED:
            return { pendingHolylight: false };
        default:
            return state;
    }
};
export default holylightReducer;
