import { $isLinkNode } from '@lexical/link';
import { $isListItemNode, $isListNode } from '@lexical/list';
import { $isHeadingNode, $isQuoteNode } from '@lexical/rich-text';
import { $isTableCellNode, $isTableNode, $isTableRowNode } from '@lexical/table';
import { $createHolylightNode, $isHolylightNode, getAllHolylightNodes, } from '../../nodes/holylight_node';
import { nodeSubtreeBuild } from '../../utils/node_subtree_build';
import { $createTextNode, $getNodeByKey, $getSelection, $isParagraphNode, $isRangeSelection, $isTextNode, } from 'lexical';
import { filterHits } from '@utils/block_hit';
import { HitFilterEnum } from '@shared/enums';
import { setCorrectSelectionAfterConvertHoly, setCorrectSelectionAfterExtendHoly, setCorrectSelectionAfterRevertHoly, } from '@component/analyzer/editor/utils/set_correct_selection';
function calculatePreviousSiblingsContentSizes(lexicalNode) {
    let allPreviousSiblingsContentSizes = 0;
    lexicalNode.getPreviousSiblings().forEach((previousSibling) => {
        allPreviousSiblingsContentSizes += previousSibling.getTextContentSize();
    });
    return allPreviousSiblingsContentSizes;
}
/**
 * Find related block in block-array with the specific key
 */
function findBlock(blocks, nodeKey) {
    return blocks.filter((block) => {
        return block.blockKey === nodeKey;
    });
}
/**
 * Calculation as preparation for selection-setting
 */
function calculateSelectionIndex(selectionAnchorKey, childKey, previousSiblingKey, nextSiblingKey) {
    if (selectionAnchorKey === childKey)
        return 1;
    if (selectionAnchorKey === previousSiblingKey)
        return 0;
    if (selectionAnchorKey === nextSiblingKey)
        return 2;
    return -1;
}
function getTextNodeContent(nextSiblingTextContent, nextSiblingContentEndIdx, endIdx, hitEndIdx) {
    if (endIdx - hitEndIdx > 0)
        return nextSiblingTextContent.substring(0, nextSiblingContentEndIdx);
    return nextSiblingTextContent;
}
function findWordSubstringEndIdx(firstChildContentSize, startIdx, hitStartIdx) {
    const subtract = (hitStartIdx - startIdx);
    if (firstChildContentSize > (subtract))
        return firstChildContentSize - subtract;
    return firstChildContentSize;
}
/**
 * Find TextNodeKeys for current hit (by start- and endIdx)
 */
export function findTextKeys(subtreeDictionary, startIdx, endIdx) {
    const textKeys = [];
    for (const subtree of subtreeDictionary) {
        if (startIdx === endIdx && startIdx === subtree.startIdx && endIdx === subtree.endIdx) {
            // if we have a match, where startIdx and endIdx are identical
            // and also the specific node has these start- and endIdx
            // empty array and return only this value
            textKeys.splice(0, textKeys.length);
            textKeys.push(subtree.key);
            break;
        }
        else {
            if (((startIdx >= subtree.startIdx && startIdx < subtree.endIdx) ||
                (endIdx <= subtree.endIdx && endIdx > subtree.startIdx))) {
                textKeys.push(subtree.key);
            }
        }
    }
    return textKeys;
}
/**
 * Pre-Check for extending HolylightNode
 * If we have TextNodeKeys which are related to a hit and are already children of HolylightNode
 */
function checkParentsIfHolylight(textNodeKeys) {
    let holylightNode = null;
    for (const textNodeKey of textNodeKeys) {
        const textNode = $getNodeByKey(textNodeKey);
        if (!textNode)
            continue;
        const textNodeParent = textNode.getParent();
        if (textNodeParent) {
            if ($isParagraphNode(textNodeParent)) {
                continue;
            }
            if ($isHolylightNode(textNodeParent)) {
                holylightNode = textNodeParent;
                break;
            }
            else {
                holylightNode = checkParentsIfHolylight([textNodeParent.getKey()]);
                break;
            }
        }
    }
    return holylightNode ?? null;
}
/**
 * Convert multiple Nodes to HolylightNode
 *
 * For example multiple TextNodes which have different formats (such as bold, italic, etc..)
 */
function convertMultipleToHoly(editor, lexicalNode, lexicalNodeContent, firstChild, firstChildContentSize, hit, startIdx, endIdx, textNodeKeys) {
    const firstChildKey = firstChild.getKey();
    const word = lexicalNodeContent.substring(hit.start_idx, hit.end_idx);
    const selection = $getSelection();
    const selectionAnchorKey = selection.anchor.key;
    const selectionAnchorOffset = selection.anchor.offset;
    const selectionFocusOffset = selection.focus.offset;
    const newChildren = [];
    let selectionIndex = 0;
    let allPreviousSiblingsContentSizes = 0;
    const holylightNode = $createHolylightNode(hit.hit_id, word, hit);
    let currentKey = textNodeKeys[0];
    let index = 0;
    const nodeToReplaceKeys = [firstChildKey];
    while (currentKey !== textNodeKeys[textNodeKeys.length - 1]) {
        if (index === 0 && currentKey === firstChildKey) {
            allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(firstChild);
            firstChild.insertAfter(holylightNode);
            if ($isTextNode(firstChild)) {
                const wordSubstringEndIdx = findWordSubstringEndIdx(firstChildContentSize, startIdx, hit.start_idx);
                const textNode = $createTextNode(word.substring(0, wordSubstringEndIdx));
                const format = firstChild.getFormat();
                if (format > 0) {
                    textNode.setFormat(format);
                }
                holylightNode.append(textNode);
                newChildren.push(textNode);
            }
            const newContentBeforeStart = endIdx - firstChildContentSize;
            const newContentBefore = lexicalNodeContent.substring(newContentBeforeStart < 0 ? 0 : newContentBeforeStart, hit.start_idx);
            if (newContentBefore.length > 0) {
                firstChild.setTextContent(newContentBefore);
            }
            else {
                firstChild.remove();
            }
        }
        else if (index === 0 && currentKey !== firstChildKey) {
            const nodeSubtree = nodeSubtreeBuild(lexicalNode);
            textNodeKeys = findTextKeys(nodeSubtree, hit.start_idx, hit.end_idx);
            currentKey = textNodeKeys[0];
            continue;
        }
        else {
            const nextSibling = holylightNode.getNextSibling();
            if (nextSibling) {
                currentKey = nextSibling.getKey();
                if (!nodeToReplaceKeys.includes(currentKey)) {
                    nodeToReplaceKeys.push(currentKey);
                }
                allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(nextSibling);
                selectionIndex = currentKey === selectionAnchorKey ? index : selectionIndex;
                if ($isTextNode(nextSibling)) {
                    const nextSiblingTextContentSize = nextSibling.getTextContentSize();
                    endIdx += nextSiblingTextContentSize;
                    const nextSiblingContentEndIdx = nextSiblingTextContentSize - (endIdx - hit.end_idx);
                    const textNodeContent = getTextNodeContent(nextSibling.getTextContent(), nextSiblingContentEndIdx, endIdx, hit.end_idx);
                    const textNode = $createTextNode(textNodeContent);
                    const format = nextSibling.getFormat();
                    if (format > 0) {
                        textNode.setFormat(format);
                    }
                    holylightNode.append(textNode);
                    newChildren.push(textNode);
                    if (endIdx - hit.end_idx > 0) {
                        const newContentAfter = nextSibling
                            .getTextContent()
                            .substring(nextSiblingContentEndIdx, nextSiblingTextContentSize);
                        const newTextNodeAfter = $createTextNode(newContentAfter);
                        if (format > 0) {
                            newTextNodeAfter.setFormat(format);
                        }
                        holylightNode.insertAfter(newTextNodeAfter);
                    }
                }
                nextSibling.remove();
            }
        }
        index += 1;
    }
    setCorrectSelectionAfterConvertHoly(editor, selection, nodeToReplaceKeys, { selectionAnchorKey, selectionAnchorOffset, selectionFocusOffset }, newChildren, selectionIndex, allPreviousSiblingsContentSizes);
    return holylightNode;
}
/**
 * Converts a single TextNode to HolylightNode (with TextNode)
 */
function convertSingleToHoly(editor, lexicalNodeContent, child, hit, endIdx) {
    const childKey = child.getKey();
    const selection = $getSelection();
    const selectionAnchorOffset = selection.anchor.offset;
    const selectionFocusOffset = selection.focus.offset;
    const selectionAnchorKey = selection.anchor.key;
    const word = lexicalNodeContent.substring(hit.start_idx, hit.end_idx);
    const newContentAfter = lexicalNodeContent.substring(hit.end_idx, endIdx);
    const holylightNode = $createHolylightNode(hit.hit_id, word, hit);
    const format = child.getFormat();
    const holylightTextNode = $createTextNode(word);
    if (format > 0) {
        holylightTextNode.setFormat(format);
    }
    holylightNode.append(holylightTextNode);
    const previousSiblingKey = child.getPreviousSibling()?.getKey() || '';
    const nextSiblingKey = child.getNextSibling()?.getKey() || '';
    const allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(child);
    child.replace(holylightNode);
    const textNodeAfter = $createTextNode(newContentAfter);
    textNodeAfter.setFormat(format);
    holylightNode.insertAfter(textNodeAfter);
    const newContentBefore = lexicalNodeContent.substring(allPreviousSiblingsContentSizes, hit.start_idx);
    const textNodeBefore = $createTextNode(newContentBefore);
    if (newContentBefore.length > 0) {
        textNodeBefore.setFormat(format);
        holylightNode.insertBefore(textNodeBefore);
    }
    const selectionIndex = calculateSelectionIndex(selectionAnchorKey, childKey, previousSiblingKey, nextSiblingKey);
    // set new selection (to prevent crashing keypress flow / text writing flow of user
    setCorrectSelectionAfterConvertHoly(editor, selection, [childKey], { selectionAnchorKey, selectionAnchorOffset, selectionFocusOffset }, [textNodeBefore, holylightTextNode, textNodeAfter], selectionIndex, allPreviousSiblingsContentSizes);
    return textNodeAfter;
}
/**
 * Extend existing Holylight with chars
 */
function extendHolylight(editor, lexicalNodeContent, hit, startIdx, endIdx, textNodeKeys, holylightNode) {
    // we have to create a new HolylightNode with extended children from existing (old) HolylightNode
    const extendedHolylightNode = $createHolylightNode(hit.hit_id, hit.noun_chunk, hit);
    const holylightNodeChildren = holylightNode.getChildren();
    // get current selection
    const selection = $getSelection();
    let selectionAnchorKey = selection.anchor.key;
    let selectionAnchorOffset = selection.anchor.offset;
    let selectionFocusOffset = selection.focus.offset;
    // if hit starts before current endIdx
    // we have to extract chars from textNode before HolylightNode
    if (hit.start_idx < endIdx && hit.start_idx > startIdx) {
        const textNodeBefore = $getNodeByKey(textNodeKeys[0]);
        textNodeBefore?.setTextContent(lexicalNodeContent?.substring(startIdx, hit.start_idx));
        holylightNodeChildren.forEach((node, index) => {
            // extend first child
            if (index === 0) {
                const nodeContent = node.getTextContent();
                selectionAnchorKey = node.getKey();
                // extend content at start
                node.setTextContent(lexicalNodeContent?.substring(hit.start_idx, endIdx) + nodeContent);
            }
            extendedHolylightNode.append(node);
        });
        selectionAnchorOffset = selectionAnchorOffset - (hit.start_idx - startIdx);
        selectionFocusOffset = selectionFocusOffset - (hit.start_idx - startIdx);
        // if hit starts before current startIdx
        // we have to extract chars from textNode after HolylightNode
    }
    else if (hit.start_idx < startIdx && hit.start_idx < endIdx) {
        const textNodeAfter = $getNodeByKey(textNodeKeys[textNodeKeys.length - 1]);
        textNodeAfter?.setTextContent(lexicalNodeContent?.substring(hit.end_idx, endIdx));
        holylightNodeChildren.forEach((node, index) => {
            // extend last child
            if (index === holylightNodeChildren.length - 1) {
                const nodeContent = node.getTextContent();
                // extend content at end
                node.setTextContent(nodeContent + lexicalNodeContent?.substring(startIdx, hit.end_idx));
            }
            extendedHolylightNode.append(node);
        });
    }
    holylightNode.replace(extendedHolylightNode);
    setCorrectSelectionAfterExtendHoly(editor, selection, selectionAnchorKey, selectionAnchorOffset, selectionFocusOffset);
    return extendedHolylightNode;
}
/**
 * Check if we have hits which match with current LexicalNode (HolylightNode)
 */
function checkForHolyMatch(child, hits) {
    const check = hits.filter((hit) => {
        return hit.hit_id === child.__hitId && hit.noun_chunk.toLowerCase() === child.__text.toLowerCase();
    });
    if (check.length > 0)
        return true;
    return false;
}
/**
 * Update existing nodes with updated hit-information
 */
function updatePreviousHits(lexicalNode, newHits, nodeSubtree) {
    if (newHits.length > 0) {
        getAllHolylightNodes(lexicalNode).forEach((holylightNode) => {
            newHits.forEach((newHit) => {
                const textNodeKeys = findTextKeys(nodeSubtree, newHit.start_idx, newHit.end_idx);
                if (textNodeKeys[0] === holylightNode.getKey() || holylightNode.__children.includes(textNodeKeys[0])) {
                    holylightNode.setHit(newHit);
                    holylightNode.setHitId(newHit.hit_id);
                    holylightNode.setText(newHit.noun_chunk);
                }
            });
        });
    }
}
function checkChildren(editor, lexicalNode, selection, hits, firstLevelNodeKey, firstLevelNodeContent, nodeSubtree) {
    lexicalNode.getChildren().forEach((child) => {
        if (!child.getParent())
            return;
        const childKey = child.getKey();
        let childContentSize = child.getTextContentSize();
        let childContent = child.getTextContent();
        if ($isTextNode(child)) {
            let allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(child);
            let startIdx = allPreviousSiblingsContentSizes;
            let endIdx = allPreviousSiblingsContentSizes + childContentSize;
            hits.forEach((hit) => {
                if (hit.start_idx >= endIdx)
                    return;
                let textNodeKeys = findTextKeys(nodeSubtree, hit.start_idx, hit.end_idx);
                if (!textNodeKeys.includes(childKey))
                    return;
                if (textNodeKeys.length > 1 && childKey === textNodeKeys[0]) {
                    const holylightNode = checkParentsIfHolylight(textNodeKeys);
                    if (holylightNode) {
                        child = extendHolylight(editor, firstLevelNodeContent, hit, startIdx, endIdx, textNodeKeys, holylightNode);
                    }
                    else {
                        child = convertMultipleToHoly(editor, lexicalNode, firstLevelNodeContent, child, childContentSize, hit, startIdx, endIdx, textNodeKeys);
                    }
                    childContentSize = child.getTextContentSize();
                    childContent = child.getTextContent();
                    allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(child);
                    startIdx = allPreviousSiblingsContentSizes;
                    endIdx = allPreviousSiblingsContentSizes + childContentSize;
                }
                else if (textNodeKeys.length > 1 && childKey !== textNodeKeys[0]) {
                    nodeSubtree = nodeSubtreeBuild(lexicalNode);
                    textNodeKeys = findTextKeys(nodeSubtree, hit.start_idx, hit.end_idx);
                    if (childKey === textNodeKeys[0]) {
                        child = convertMultipleToHoly(editor, lexicalNode, firstLevelNodeContent, child, childContentSize, hit, startIdx, endIdx, textNodeKeys);
                        childContentSize = child.getTextContentSize();
                        childContent = child.getTextContent();
                        allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(child);
                        startIdx = allPreviousSiblingsContentSizes;
                        endIdx = allPreviousSiblingsContentSizes + childContentSize;
                    }
                    else {
                        const previousSibling = child.getPreviousSibling();
                        if (previousSibling?.getKey() === textNodeKeys[0]) {
                            allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(previousSibling);
                            startIdx = allPreviousSiblingsContentSizes;
                            childContentSize = previousSibling.getTextContentSize();
                            endIdx = allPreviousSiblingsContentSizes + childContentSize;
                            child = convertMultipleToHoly(editor, lexicalNode, firstLevelNodeContent, previousSibling, childContentSize, hit, startIdx, endIdx, textNodeKeys);
                        }
                        childContentSize = child.getTextContentSize();
                        childContent = child.getTextContent();
                        allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(child);
                        startIdx = allPreviousSiblingsContentSizes;
                        endIdx = allPreviousSiblingsContentSizes + childContentSize;
                    }
                    return;
                }
                else {
                    if (childContent === ' ')
                        return;
                    if (!childContent.toLowerCase().includes(hit.word.toLowerCase()))
                        return;
                    if (!child.getParent())
                        return;
                    if (hit.block_key !== firstLevelNodeKey)
                        return;
                    if (hit.start_idx >= endIdx)
                        return;
                    if (hit.start_idx === startIdx ||
                        (hit.start_idx > 0 && hit.start_idx < endIdx && startIdx !== endIdx && startIdx <= hit.start_idx)) {
                        child = convertSingleToHoly(editor, firstLevelNodeContent, child, hit, endIdx);
                        childContentSize = child.getTextContentSize();
                        childContent = child.getTextContent();
                        allPreviousSiblingsContentSizes = calculatePreviousSiblingsContentSizes(child);
                        startIdx = allPreviousSiblingsContentSizes;
                        endIdx = allPreviousSiblingsContentSizes + childContentSize;
                        child.setTextContent(childContent);
                    }
                }
            });
        }
        else if ($isLinkNode(child) ||
            $isListItemNode(child) ||
            $isListNode(child) ||
            $isParagraphNode(child) ||
            $isTableCellNode(child) ||
            $isTableRowNode(child) ||
            $isTableNode(child)) {
            return checkChildren(editor, child, selection, hits, firstLevelNodeKey, firstLevelNodeContent, nodeSubtree);
        }
        else if ($isHolylightNode(child)) {
            if (!checkForHolyMatch(child, hits)) {
                // revert holylight
                let selectionAnchorOffset = selection?.anchor.offset || 0;
                let selectionFocusOffset = selection?.focus.offset || 0;
                const selectionAnchorKey = selection.anchor.key;
                const childChildren = child.getChildren();
                if (childChildren.length > 1) {
                    let lastChild;
                    const newTextNodes = [];
                    let selectionIndex = -1;
                    childChildren.forEach((childChild, index) => {
                        if (selectionAnchorKey === childChild.getKey()) {
                            selectionIndex = index;
                        }
                        const newTextNode = $createTextNode(childChild.getTextContent());
                        const format = childChild.getFormat();
                        if (format)
                            newTextNode.setFormat(format);
                        if (index === 0) {
                            child.replace(newTextNode);
                            lastChild = newTextNode;
                        }
                        else {
                            lastChild.insertAfter(newTextNode);
                        }
                        newTextNodes.push(newTextNode);
                    });
                    setCorrectSelectionAfterRevertHoly(editor, selection, child.getKey(), {
                        selectionAnchorKey,
                        selectionAnchorOffset,
                        selectionFocusOffset,
                    }, newTextNodes, selectionIndex);
                }
                else {
                    const newTextNode = $createTextNode(childContent);
                    const format = childChildren[0].getFormat();
                    newTextNode.setFormat(format);
                    child.replace(newTextNode);
                    if (selectionAnchorKey !== childChildren[0].getKey()) {
                        selectionAnchorOffset += childChildren[0].getTextContentSize();
                        selectionFocusOffset += childChildren[0].getTextContentSize();
                    }
                    setCorrectSelectionAfterRevertHoly(editor, selection, childChildren[0].getKey(), {
                        selectionAnchorKey,
                        selectionAnchorOffset,
                        selectionFocusOffset,
                    }, [newTextNode]);
                }
            }
            else {
                const childText = child.getText();
                // check, if content of child is same as text-value (property) of holylight
                // if content is different, we have to update content of current and next-node
                if (childText !== childContent) {
                    const selectionAnchorOffset = selection.anchor.offset;
                    const selectionFocusOffset = selection.focus.offset;
                    const selectionAnchorKey = selection.anchor.key;
                    const textOffset = childText.length - childContentSize;
                    const nextSibling = child.getNextSibling();
                    const nextSiblingContent = nextSibling?.getTextContent();
                    const newNextSiblingContent = nextSiblingContent?.substring(textOffset, nextSiblingContent.length);
                    nextSibling?.setTextContent(newNextSiblingContent);
                    const lastChild = child.getLastChild();
                    if (lastChild) {
                        const lastChildContent = lastChild.getTextContent();
                        const newChildContentEndIdx = childContentSize + textOffset;
                        const lastChildNewContent = lastChildContent +
                            childText.substring(childContentSize, newChildContentEndIdx);
                        lastChild.setTextContent(lastChildNewContent);
                        const textNode = child.getNextSibling();
                        setCorrectSelectionAfterConvertHoly(editor, selection, [selectionAnchorKey], {
                            selectionAnchorKey,
                            selectionAnchorOffset: selectionAnchorOffset -
                                (newChildContentEndIdx - childContentSize),
                            selectionFocusOffset: selectionFocusOffset - (newChildContentEndIdx - childContentSize),
                        }, [textNode || lastChild]);
                    }
                }
            }
        }
    });
}
function revertHolyFromChildren(editor, selection, selectionInfo, children, holylightNode) {
    const newChildren = [];
    const firstChild = $createTextNode(children[0].getTextContent());
    firstChild.setFormat(children[0].getFormat());
    holylightNode.replace(firstChild);
    let selectionIndex = 0;
    let lastNode = firstChild;
    let lastNodeFormat = firstChild.getFormat();
    let allPreviousSiblingsContentSizes = 0;
    let disableContentSizesCalculation = false;
    let newSelAnchorKey;
    children.forEach((child, index) => {
        if (index > 0) {
            const furtherNode = $createTextNode(child.getTextContent());
            furtherNode.setFormat(child.getFormat());
            newChildren.push(furtherNode);
            lastNodeFormat = lastNode.getFormat();
            lastNode.insertAfter(furtherNode);
            lastNode = furtherNode;
        }
        if (child.getKey() === selectionInfo.selectionAnchorKey) {
            selectionIndex = index - 1;
            allPreviousSiblingsContentSizes = 0;
            disableContentSizesCalculation = true;
        }
        else {
            if (!disableContentSizesCalculation && child.getFormat() === lastNodeFormat) {
                allPreviousSiblingsContentSizes += child.getTextContentSize();
            }
            else if (!disableContentSizesCalculation && child.getFormat() !== lastNodeFormat) {
                allPreviousSiblingsContentSizes = child.getTextContentSize();
            }
        }
    });
    selectionInfo.selectionAnchorKey = newSelAnchorKey || selectionInfo.selectionAnchorKey;
    selectionInfo.selectionAnchorOffset += allPreviousSiblingsContentSizes;
    selectionInfo.selectionFocusOffset += allPreviousSiblingsContentSizes;
    setCorrectSelectionAfterRevertHoly(editor, selection, holylightNode.getKey(), selectionInfo, newChildren, selectionIndex);
}
function revertHoly(editor, selection, lexicalNode) {
    const holylightNodes = getAllHolylightNodes(lexicalNode);
    if (holylightNodes.length > 0) {
        const selectionAnchorKey = selection.anchor.key;
        let selectionAnchorOffset = selection.anchor.offset;
        let selectionFocusOffset = selection.focus.offset;
        holylightNodes.forEach((holylightNode) => {
            const children = holylightNode.getChildren();
            if (children.length > 1) {
                revertHolyFromChildren(editor, selection, { selectionAnchorKey, selectionAnchorOffset, selectionFocusOffset }, children, holylightNode);
            }
            else if (children.length === 1) {
                holylightNode.replace(children[0]);
                if (selectionAnchorKey !== children[0].getKey()) {
                    selectionAnchorOffset += children[0].getTextContentSize();
                    selectionFocusOffset += children[0].getTextContentSize();
                }
                setCorrectSelectionAfterRevertHoly(editor, selection, holylightNode.getKey(), { selectionAnchorKey, selectionAnchorOffset, selectionFocusOffset }, children);
            }
            else {
                holylightNode.remove();
            }
        });
    }
}
/**
 * Main method, which handles the Holylight-Process
 */
export function makeItHoly(editor, lexicalNodes, nodesSubtrees, blocks) {
    lexicalNodes.forEach((lexicalNode) => {
        const lexicalNodeKey = lexicalNode.getKey();
        const nodeSubtree = nodesSubtrees[lexicalNodeKey];
        const block = findBlock(blocks, lexicalNodeKey);
        // check if we have hits in current LexicalNode
        const hits = block && block[0] && block[0].hits ? filterHits(block[0].hits, HitFilterEnum.IGNORE) : [];
        editor.update(() => {
            const lexicalNodeContent = lexicalNode.getTextContent();
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                if (hits.length > 0) {
                    // update existing HolylightNodes with updated hit-information
                    updatePreviousHits(lexicalNode, hits, nodeSubtree);
                    if ($isParagraphNode(lexicalNode) ||
                        $isHeadingNode(lexicalNode) ||
                        $isQuoteNode(lexicalNode) ||
                        $isListNode(lexicalNode) ||
                        $isListItemNode(lexicalNode) ||
                        $isTableNode(lexicalNode) ||
                        $isTableRowNode(lexicalNode) ||
                        $isTableCellNode(lexicalNode)) {
                        checkChildren(editor, lexicalNode, selection, hits, lexicalNodeKey, lexicalNodeContent, nodeSubtree);
                    }
                }
                else {
                    // if no hits for current LexicalNode, but we have HolylightNodes
                    // remove them (revert Holylight)
                    revertHoly(editor, selection, lexicalNode);
                }
            }
        });
    });
}
