import { FeedbackActionType } from '@state/types';
const initialState = {
    loading: false,
    error: null,
    data: null,
    done: false,
};
const feedbackReducer = (state = initialState, action = { type: FeedbackActionType.REQUEST }) => {
    switch (action.type) {
        case FeedbackActionType.REQUEST:
            return { loading: true, error: null, data: null, done: false };
        case FeedbackActionType.SUCCESS:
            return { loading: false, error: null, data: action.payload, done: false };
        case FeedbackActionType.ERROR:
            return { loading: false, error: action.payload, data: null, done: false };
        case FeedbackActionType.DONE:
            return { loading: false, error: null, data: null, done: true };
        default:
            return state;
    }
};
export default feedbackReducer;
