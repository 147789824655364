import React from 'react';
import FinnitDash from '@assets/icons/dash.svg';
import FinnitChecksUpsideDown from '@assets/icons/finnit_checks_upsidedown.svg';
import FinnitStar from '@assets/icons/star.svg';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import SimpleText from '@ui/simple_text/simple_text';
import SimpleTitle from '@ui/simple_title/simple_title';
import './gendern_mini.scss';
const GendernMini = () => {
    return (React.createElement("article", { className: "content gendern xl:px-0 lg:px-8 mb-16 xss:px-0" },
        React.createElement("div", { className: "flex mx-auto max-w-6xl lg:flex-row xss:flex-col" },
            React.createElement("div", { className: "left flex lg:w-[570px] xss:justify-center lg:justify-start \n\t\t\t\tflex-col lg:mt-28 xss:mt-8 xss:max-w-full lg:px-0 xss:px-8" },
                React.createElement("div", { className: "lg:max-w-[400px] xss:w-auto lg:mt-0 lg:mb-0 s:mt-10 s:mb-10" },
                    React.createElement(SimpleTitle, { sub: true, light: true, text: 'Gendern – Geschlechtersensible und -gerechte Sprache' }),
                    React.createElement(SimpleText, { text: 'Mittels geschlechtersensibler Sprache wird mit steigender Tendenz versucht, \
            alle Menschen unabhängig von ihrer Geschlechtsidentität in gleicher Weise \
						zu adressieren und zu repräsentieren. \
            Grundsätzlich existieren zwei unterschiedliche Art und Weisen, um geschlechtersensibel \
						zu formulieren.\
            Zum einen kann explizit zwischen den Geschlechtern differenziert werden, \
						was in der Regel mittels eines Schrägstriches, \
            Binnen-I oder auch immer öfter Gender-Sternchens erfolgt.\
            Zum anderen kann neutralisiert werden, indem kein Geschlecht angesprochen oder aufgezeigt wird.' }))),
            React.createElement("div", { className: "right lg:flex-row lg:justify-start xss:justify-center flex lg:p-0 xss:ml-0 xss:p-8" },
                React.createElement("div", { className: "symbol star mt-auto mb-20" },
                    React.createElement(FinnitStar, null)),
                React.createElement("div", { className: "finnit_square mt-5 sm:ml-16 s:ml-4 xss:ml-2 s:h-[602px] s:w-[172px]\n\t\t\t\t\txs:w-[140px] xs:h-[492px] xss:w-[110px] xss:h-[387px]" }),
                React.createElement("div", { className: "image s:mt-16 xss:mt-12 xs:ml-[-105px] xss:ml-[-80px] max-h-[602px] max-w-[172px]" },
                    React.createElement("img", { className: "s:w-auto s:h-auto xs:w-[140px] xs:h-[492px] xss:w-[110px] xss:h-[387px]", src: '/public/assets/img/aktentasche_zwei.jpg' })),
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "symbol two_points 2xl:ml-auto lg:ml-20 sm:ml-32 mt-32 md:mr-1.5 \n\t\t\t\t\t\tsm:block xss:hidden" },
                        React.createElement(FinnitTwoPoints, null)),
                    React.createElement("div", { className: "symbol dash xl:ml-auto lg:ml-10 xss:ml-auto mt-16 mr-[-1.75rem]\n\t\t\t\t\t\tsm:block xss:hidden" },
                        React.createElement(FinnitDash, null)),
                    React.createElement("div", { className: "symbol checks_upside_down mt-auto xl:ml-[-137px] s:ml-[-137px]\n\t\t\t\t\t\txs:ml-[-127px] xss:ml-[-75px] s:w-[288px] s:h-[259px] xs:w-[200px]\n\t\t\t\t\t\txs:h-[180px] xss:w-[160px] xss:h-[144px]" },
                        React.createElement(FinnitChecksUpsideDown, null)))))));
};
export default GendernMini;
