import React from 'react';
import parse from 'html-react-parser';
import './simple_title.scss';
/**
 * SimpleTitle-component
 *
 */
const SimpleTitle = (props) => {
    if (props.link) {
        if (props.sub) {
            return (React.createElement("a", { href: props.href, target: "blank" },
                React.createElement("h5", { className: 'md:text-[24px] xss:text-[22px] simple_subtitle' + (props.light ? ' light' : '') }, parse(props.text))));
        }
        else {
            return (React.createElement("a", { href: props.href, target: "blank" },
                React.createElement("h1", { className: 'md:text-[28px] xss:text-[22px] simple_title' + (props.light ? ' light' : '') }, parse(props.text))));
        }
    }
    else {
        if (props.sub) {
            return (React.createElement("h5", { className: 'md:text-[24px] xss:text-[22px] simple_subtitle' + (props.light ? ' light' : '') }, parse(props.text)));
        }
        else {
            return (React.createElement("h1", { className: 'md:text-[28px] xss:text-[22px] simple_title' + (props.light ? ' light' : '') }, parse(props.text)));
        }
    }
};
export default SimpleTitle;
