import { HitDetailMobileTypeEnum } from '@shared/enums';
import { DetailMobileActionType } from '@state/types';
const initialState = {
    pendingHitDetailMobile: false,
    dataHitDetailMobile: null,
};
const hitDetailMobileReducer = (state = initialState, action = {
    type: DetailMobileActionType.REQUEST,
    detailMobileType: HitDetailMobileTypeEnum.EMPTY,
    payload: null
}) => {
    switch (action.type) {
        case DetailMobileActionType.REQUEST:
            return {
                pendingHitDetailMobile: true,
                dataHitDetailMobile: action.payload,
                typeHitDetailMobile: action.detailMobileType,
            };
        case DetailMobileActionType.OPENED:
            return { pendingHitDetailMobile: false, dataHitDetailMobile: null };
        default:
            return state;
    }
};
export default hitDetailMobileReducer;
