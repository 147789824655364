import React, { useEffect, useState, useRef } from 'react';
import { useTypedSelector } from '@hooks/use_typed_selector';
import { useActions } from '@hooks/use_actions';
import { HolylightTooltipPlugin } from '@component/analyzer/editor/plugins/holylight/holylight_tooltip_plugin';
// eslint-disable-next-line
import { HolylightTooltipMobilePlugin } from '@component/analyzer/editor/plugins/holylight/holylight_tooltip_mobile_plugin';
import AnalyzerFooter from '@component/analyzer/footer/analyzer_footer';
import LeftSidebar from '@component/analyzer/left_sidebar/left_sidebar';
import RightSidebar from '@component/analyzer/right_sidebar/right_sidebar';
import TextArea from '@component/analyzer/editor/textarea';
import '@component/analyzer/analyzer.scss';
import AnalyzerFeedbackModal from '@modals/feedback_modal/analyzer_feedback_modal';
import { DeviceTypeEnum, SidebarTriggerTypeEnum, SidebarTypeEnum, ThemeEnum, TooltipTriggerEnum } from '@shared/enums';
import { useCurrentDimensions } from '@utils/dimensions';
import { getLocalStorage, setLocalStorage } from '@utils/storage';
import { useDebounce } from '@utils/use_debounce';
import { useForceUpdate } from '@utils/use_force_update';
import { useCurrentDeviceType } from '@utils/device_type';
import Caret from '@assets/icons/caret.svg';
import { LineBreakNode, ParagraphNode, TextNode } from 'lexical';
import { lexicalTheme } from '@component/analyzer/editor/theme';
import { LinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@component/analyzer/editor/nodes/horizontal_rule_node';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { MarkNode } from '@lexical/mark';
import { CodeNode } from '@lexical/code';
import { HolylightNode } from '@component/analyzer/editor/nodes/holylight_node';
import { ImageNode } from '@component/analyzer/editor/nodes/image_node';
const Analyzer = () => {
    const forceUpdate = useForceUpdate();
    const dimensions = useCurrentDimensions();
    const deviceType = useCurrentDeviceType();
    const [showRightSidebar, setShowRightSidebar] = useState(false);
    const [showLeftSidebar, setShowLeftSidebar] = useState(false);
    const [showTooltipMobile, setShowTooltipMobile] = useState();
    const [rightSidebarClasses, setRightSidebarClasses] = useState('');
    const [leftSidebarClasses, setLeftSidebarClasses] = useState('');
    const [tooltipHit, setTooltipHit] = useState();
    const [tooltipNodeKey, setTooltipNodeKey] = useState();
    const [tooltipDomElement, setTooltipDomElement] = useState();
    const [tooltipTrigger, setTooltipTrigger] = useState();
    const [tooltipEnabled, setTooltipEnabled] = useState(false);
    const [tooltipMobileHit, setTooltipMobileHit] = useState();
    const [tooltipMobileNodeKey, setTooltipMobileNodeKey] = useState();
    const [tooltipMobileTrigger, setTooltipMobileTrigger] = useState();
    const [tooltipMobileEnabled, setTooltipMobileEnabled] = useState(false);
    const [tooltipMobileTouch, setTooltipMobileTouch] = useState(false);
    const [closingEnabled, setClosingEnabled] = useState(true);
    const [themeMode, setThemeMode] = useState(getLocalStorage('theme_mode') ? getLocalStorage('theme_mode') : ThemeEnum.STANDARD);
    const rightSidebarRef = useRef(null);
    const leftSidebarRef = useRef(null);
    const tooltipMobileRef = useRef(null);
    const { toggledSidebar, toggleSidebar, toggledTheme, toggledTooltip } = useActions();
    const resetTooltip = (mobile = false) => {
        if (mobile) {
            setTooltipMobileTrigger(undefined);
            setTooltipMobileNodeKey(undefined);
            setTooltipMobileHit(undefined);
            setTooltipMobileEnabled(false);
            setTooltipMobileTouch(false);
        }
        else {
            setTooltipDomElement(undefined);
            setTooltipTrigger(undefined);
            setTooltipNodeKey(undefined);
            setTooltipHit(undefined);
            setTooltipEnabled(false);
        }
    };
    const { triggeredSidebar, typeSidebar, typeTrigger } = useTypedSelector((store) => store.sidebar);
    if (triggeredSidebar) {
        switch (typeSidebar) {
            case SidebarTypeEnum.LEFT:
                if ((typeTrigger === SidebarTriggerTypeEnum.CLOSE && showLeftSidebar) ||
                    typeTrigger === SidebarTriggerTypeEnum.OPEN) {
                    setShowLeftSidebar(!showLeftSidebar);
                    resetTooltip();
                    forceUpdate();
                }
                break;
            case SidebarTypeEnum.RIGHT:
                setShowRightSidebar(!showRightSidebar);
                resetTooltip();
                forceUpdate();
                break;
        }
        toggledSidebar();
    }
    const { triggeredTheme, typeTheme } = useTypedSelector((store) => store.theme);
    if (triggeredTheme && typeTheme) {
        document.documentElement.className = '';
        document.documentElement.classList.add(`theme-${typeTheme}`);
        setThemeMode(typeTheme);
        setLocalStorage('theme_mode', typeTheme);
        toggledTheme();
    }
    // listen from redux on tooltip.state
    const { triggeredTooltip, typeTooltipTrigger, dataTooltip, mobileTooltip, touchTooltip, } = useTypedSelector((store) => store.tooltip);
    if (triggeredTooltip && dataTooltip) {
        if (mobileTooltip) {
            if (typeTooltipTrigger === TooltipTriggerEnum.CLOSE && closingEnabled) {
                dataTooltip.domElement?.classList.remove('active');
                resetTooltip(mobileTooltip);
                setShowTooltipMobile(false);
            }
            else if (closingEnabled) {
                setClosingEnabled(false);
                dataTooltip.domElement?.classList.add('active');
                setTooltipMobileEnabled(true);
                setTooltipMobileTrigger(TooltipTriggerEnum.OPEN);
                setTooltipMobileHit(dataTooltip.hit);
                setTooltipMobileNodeKey(dataTooltip.nodeKey);
                setShowTooltipMobile(true);
                setTooltipMobileTouch(touchTooltip);
                // allow closing of tooltipMobile after timeout
                setTimeout(() => {
                    setClosingEnabled(true);
                }, 500);
            }
        }
        else {
            if (typeTooltipTrigger === TooltipTriggerEnum.CLOSE) {
                dataTooltip.domElement?.classList.remove('active');
                resetTooltip();
            }
            else {
                dataTooltip.domElement?.classList.add('active');
                if (dataTooltip.hit) {
                    setTooltipHit(dataTooltip.hit);
                    setTooltipTrigger(typeTooltipTrigger);
                    setTooltipNodeKey(dataTooltip.nodeKey);
                    setTooltipDomElement(dataTooltip.domElement);
                    setTooltipEnabled(true);
                }
            }
        }
        toggledTooltip();
    }
    if (showTooltipMobile === true) {
        if (dimensions.width < 1024) {
            leftSidebarRef.current?.style.setProperty('display', 'none', 'important');
        }
        tooltipMobileRef.current?.classList.add('loaded');
    }
    else if (showTooltipMobile === false) {
        tooltipMobileRef.current?.classList.remove('loaded');
        tooltipMobileRef.current?.classList.remove('really');
        if (dimensions.width < 1024) {
            leftSidebarRef.current?.style.setProperty('display', 'flex', 'important');
        }
    }
    const debounceThemeMode = useDebounce(themeMode, 0);
    useEffect(() => {
        document.documentElement.className = '';
        document.documentElement.classList.add(`theme-${debounceThemeMode}`);
        resetTooltip();
    }, []);
    if (showRightSidebar && dimensions.width < 768) {
        document.body.style.overflow = 'hidden';
    }
    else if (showRightSidebar && dimensions.width < 1280 && dimensions.width > 768) {
        document.body.style.overflowX = 'hidden';
    }
    else {
        document.body.style.overflow = 'auto';
    }
    if (rightSidebarRef.current && rightSidebarRef.current.classList.length > 0) {
        if (showRightSidebar) {
            rightSidebarRef.current.classList.add('loaded');
        }
        else {
            rightSidebarRef.current.classList.remove('loaded');
        }
    }
    const defaultRightSidebarClasses = `aside md:order-3 xl:max-w-xs xss:hidden xl:static 
		lg:absolute lg:block xl:top-0 lg:top-20 lg:right-0 xl:right-0 aside-right xl:flex`;
    const tempRightSidebarShowClasses = (!showRightSidebar ?
        defaultRightSidebarClasses :
        'z-10 w-[288px] h-auto bg-default absolute ml-auto flex');
    const tempRightSidebarDimensionsClasses = (dimensions.width < 1024 ?
        tempRightSidebarShowClasses :
        `fixed z-10 shadow_box bg-default h-full max-h-[60%] top-20 w-full max-w-xs 
		 items-center right-sidebar lg:aside-right`);
    const tempRightSidebarClasses = (dimensions.width < 1280 ?
        tempRightSidebarDimensionsClasses :
        defaultRightSidebarClasses);
    setRightSidebarClasses(tempRightSidebarClasses);
    if (leftSidebarRef.current && leftSidebarRef.current.classList.length > 0) {
        if (showLeftSidebar) {
            leftSidebarRef.current.classList.add('loaded');
        }
        else {
            leftSidebarRef.current.classList.remove('loaded');
        }
    }
    const defaultLeftSidebarClasses = `aside xss:shadow_box xss:bg-default lg:bg-transparent 
		h-full left-sidebar md:z-0 xss:z-20 lg:z-1 xss:fixed lg:static lg:order-1 
		ss:w-full xss:max-w-full lg:max-w-xs flex aside-left`;
    const tempLeftSidebarShowClasses = (!showLeftSidebar ?
        defaultLeftSidebarClasses :
        `lg:bg-transparent xss:bg-white aside h-full w-full flex-col z-10 lg:no_shadow 
		xss:shadow_box left-sidebar xss:fixed lg:static lg:order-1 xss:max-w-full 
		lg:max-w-xs flex aside-left`);
    const tempLeftSidebarDimensionsClasses = (dimensions.width >= 1024 ?
        tempLeftSidebarShowClasses :
        `xss:w-full px-4 bg-white border-rad-top aside h-full w-full 
		flex-col z-10 shadow_box left-sidebar xss:fixed lg:static lg:order-1 
		xss:max-w-full lg:max-w-xs flex aside-left mobile`);
    setLeftSidebarClasses(tempLeftSidebarDimensionsClasses);
    useEffect(() => {
        if (dimensions.width < 1024 || deviceType !== DeviceTypeEnum.DESKTOP) {
            resetTooltip();
            toggleSidebar(SidebarTypeEnum.LEFT);
        }
        else {
            if (tooltipMobileRef.current) {
                // eslint-disable-next-line
                tooltipMobileRef.current.style = 'display:none';
            }
        }
    }, []);
    const initialConfig = {
        namespace: 'Finnit',
        theme: lexicalTheme,
        nodes: [
            TextNode,
            LineBreakNode,
            ParagraphNode,
            LinkNode,
            ListNode,
            ListItemNode,
            HeadingNode,
            QuoteNode,
            HorizontalRuleNode,
            MarkNode,
            CodeNode,
            HolylightNode,
            ImageNode,
        ],
        onError: (error) => {
            throw error;
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor_page_div flex" },
            React.createElement("article", { className: "editor_content lg:mb-0 xss:mb-10 lg:flex xss:order-2 m:flex-col mx-auto\n\t\t\t\t\t\tmy-0 xl:max-w-5xl lg:max-w-4xl xs:max-w-full w-full lg:px-4 lg:pb-10 xss:pb-20 xss:max-w-full" },
                React.createElement(LexicalComposer, { initialConfig: initialConfig },
                    React.createElement("div", { className: 'editor_shell' +
                            (dimensions.width < 1024 ? ' mobile' : '') },
                        React.createElement(TextArea, { toolbar_enabled: true, text_info_enabled: true, spellcheck_enabled: false })))),
            React.createElement("aside", { id: "left_sidebar", ref: leftSidebarRef, className: leftSidebarClasses },
                React.createElement("button", { draggable: "true", onDragEnd: () => toggleSidebar(SidebarTypeEnum.LEFT), onClick: () => toggleSidebar(SidebarTypeEnum.LEFT), className: "lg:hidden z-10 button w-full h-16 p-0 click_panel subtitle xss:block" }),
                React.createElement(LeftSidebar, null)),
            React.createElement("aside", { ref: rightSidebarRef, className: rightSidebarClasses },
                React.createElement("button", { onClick: () => toggleSidebar(SidebarTypeEnum.RIGHT), className: "button base fixed right-0 z-50 small h-10 mt-[-70px]\n\t\t\t\t\t\t\toverflow-hidden mr-1 ml-[-10px] xss:hidden xl:hidden lg:block md:order-3" },
                    React.createElement("div", { className: "symbol caret xss:hidden lg:block xl:hidden w-3 h-3 mt-[-7px]" }, showRightSidebar ? React.createElement(Caret, { className: "open" }) : React.createElement(Caret, { className: "closed" }))),
                React.createElement(RightSidebar, null))),
        React.createElement("div", { id: "tooltip_mobile", ref: tooltipMobileRef, className: "tooltip_mobile shadow_box" },
            React.createElement(HolylightTooltipMobilePlugin, { enabled: tooltipMobileEnabled, hit: tooltipMobileHit, typeTrigger: tooltipMobileTrigger, nodeKey: tooltipMobileNodeKey, mobile: true, touch: tooltipMobileTouch })),
        React.createElement(AnalyzerFeedbackModal, null),
        React.createElement(AnalyzerFooter, null),
        React.createElement(HolylightTooltipPlugin, { enabled: tooltipEnabled, hit: tooltipHit, typeTrigger: tooltipTrigger, nodeKey: tooltipNodeKey, domElement: tooltipDomElement })));
};
export default Analyzer;
