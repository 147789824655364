import { useEffect, useState } from 'react';
function useDetectKeyboardOpen(defaultValue, minKeyboardHeight = 300) {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);
    useEffect(() => {
        const listener = () => {
            const visualViewportHeight = window.visualViewport?.height || 0;
            const newState = window.screen.height - minKeyboardHeight > visualViewportHeight;
            if (isKeyboardOpen != newState) {
                setIsKeyboardOpen(newState);
            }
        };
        window?.visualViewport?.addEventListener('resize', listener);
        return () => {
            window?.visualViewport?.removeEventListener('resize', listener);
        };
    }, []);
    return isKeyboardOpen;
}
export { useDetectKeyboardOpen };
