import { requestAnalyzation } from '@services/analyzer.service';
import { submitFeedback } from '@services/feedback.service';
import { SidebarTriggerTypeEnum } from '@shared/enums';
import { AnalyzerActionType, BlocksActionType, DemoActionType, DetailActionType, DetailMobileActionType, FeedbackActionType, HitsActionType, HolylightActionType, IgnoreActionType, PrepareActionType, ReplaceActionType, SidebarActionType, TextInfoActionType, ThemeActionType, TooltipActionType } from '@state/types';
import { setLocalStorage } from '@utils/storage';
export const requestAnalyzer = (analyzerRequest) => {
    // eslint-disable-next-line
    return async (dispatch, getState) => {
        if (getState().analyzer.controller) {
            const { controller } = getState().analyzer;
            controller.abort();
        }
        const newAbortController = new AbortController();
        const { signal } = newAbortController;
        dispatch({
            type: AnalyzerActionType.REQUEST,
            controller: newAbortController,
        });
        try {
            const response = await requestAnalyzation(analyzerRequest, signal);
            setLocalStorage('content_hash', response?.contentHash);
            dispatch({
                type: AnalyzerActionType.SUCCESS,
                payload: response,
            });
            // eslint-disable-next-line
        }
        catch (err) {
            dispatch({
                type: AnalyzerActionType.ERROR,
                payload: err.message,
            });
        }
    };
};
export const analyzerDone = () => {
    return async (dispatch) => {
        dispatch({
            type: AnalyzerActionType.DONE,
        });
    };
};
export const triggerDemoText = () => {
    return async (dispatch) => {
        dispatch({
            type: DemoActionType.TRIGGER,
        });
    };
};
export const triggeredDemoText = () => {
    return async (dispatch) => {
        dispatch({
            type: DemoActionType.TRIGGERED,
        });
    };
};
export const triggerReplacement = (trigger, replaceEntry) => {
    return async (dispatch) => {
        dispatch({
            trigger,
            type: ReplaceActionType.PENDING,
            payload: replaceEntry,
        });
    };
};
export const finishReplacement = () => {
    return async (dispatch) => {
        dispatch({
            type: ReplaceActionType.FINISHED,
            payload: null,
        });
    };
};
// eslint-disable-next-line
export const triggerPreparation = (prepareType, prepareData) => {
    return async (dispatch) => {
        dispatch({
            type: PrepareActionType.PENDING,
            trigger: prepareType,
            payload: prepareData,
        });
    };
};
export const finishPreparation = () => {
    return async (dispatch) => {
        dispatch({
            type: PrepareActionType.FINISHED,
            payload: null,
        });
    };
};
export const triggerHolylight = (holylightData) => {
    return async (dispatch) => {
        dispatch({
            type: HolylightActionType.STARTING,
            payload: holylightData,
        });
    };
};
export const triggeredHolylight = () => {
    return async (dispatch) => {
        dispatch({
            type: HolylightActionType.FINISHED,
        });
    };
};
export const addIgnore = (trigger, ignoreType, ignoreWord, ignoreWordId = 0, ignoreId = '', startIdx = 0, ignoreNodeKey = '') => {
    return async (dispatch) => {
        dispatch({
            trigger,
            type: IgnoreActionType.ADD,
            payload: {
                wordId: ignoreWordId,
                word: ignoreWord,
                hitId: ignoreId,
                ignoreType,
                startIdx: startIdx,
                nodeKey: ignoreNodeKey,
            }
        });
    };
};
export const addedIgnore = () => {
    return async (dispatch) => {
        dispatch({
            type: IgnoreActionType.ADDED,
            payload: undefined,
        });
    };
};
export const removeIgnored = (trigger, ignoreType, ignoreWord, ignoreId = '', ignoreNodeKey = '') => {
    return async (dispatch) => {
        dispatch({
            trigger,
            type: IgnoreActionType.REMOVE,
            payload: {
                word: ignoreWord,
                hitId: ignoreId,
                ignoreType,
                startIdx: 0,
                nodeKey: ignoreNodeKey,
            }
        });
    };
};
export const removedIgnored = () => {
    return async (dispatch) => {
        dispatch({
            type: IgnoreActionType.REMOVED,
            payload: undefined,
        });
    };
};
export const requestHitDetail = (hitDetailType, hit, trigger) => {
    return async (dispatch) => {
        if (trigger) {
            dispatch({
                type: DetailActionType.REQUEST,
                detailType: hitDetailType,
                payload: hit,
                trigger,
            });
        }
        else {
            dispatch({
                type: DetailActionType.REQUEST,
                detailType: hitDetailType,
                payload: hit,
            });
        }
    };
};
export const openedHitDetail = () => {
    return async (dispatch) => {
        dispatch({
            type: DetailActionType.OPENED,
        });
    };
};
export const requestHitDetailMobile = (hitDetailMobileType, hit) => {
    return async (dispatch) => {
        dispatch({
            type: DetailMobileActionType.REQUEST,
            detailMobileType: hitDetailMobileType,
            payload: hit,
        });
    };
};
export const openedHitDetailMobile = () => {
    return async (dispatch) => {
        dispatch({
            type: DetailMobileActionType.OPENED,
        });
    };
};
export const updateBlocks = (blocks = []) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: BlocksActionType.UPDATED,
                payload: blocks,
            });
            // eslint-disable-next-line
        }
        catch (err) {
            dispatch({
                type: BlocksActionType.ERROR,
                payload: err.message,
            });
        }
    };
};
export const triggerModal = (modal, type, hit) => {
    return async (dispatch) => {
        dispatch({ modal, type, payload: hit });
    };
};
export const updateHits = (hits = []) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: HitsActionType.UPDATED,
                payload: hits,
            });
            // eslint-disable-next-line
        }
        catch (err) {
            dispatch({
                type: HitsActionType.ERROR,
                payload: err.message,
            });
        }
    };
};
export const doneHits = () => {
    return async (dispatch) => {
        dispatch({ type: HitsActionType.DONE });
    };
};
export const updateTextInfo = (textInfo) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: TextInfoActionType.UPDATED,
                payload: textInfo,
            });
            // eslint-disable-next-line
        }
        catch (err) {
            dispatch({
                type: TextInfoActionType.ERROR,
                payload: err.message,
            });
        }
    };
};
export const triggerToolbar = (action) => {
    return async (dispatch) => {
        dispatch({
            type: action,
        });
    };
};
export const sendFeedback = (feedbackRequest) => {
    return async (dispatch) => {
        dispatch({
            type: FeedbackActionType.REQUEST,
        });
        try {
            const response = await submitFeedback(feedbackRequest);
            dispatch({
                type: FeedbackActionType.SUCCESS,
                payload: response,
            });
            // eslint-disable-next-line
        }
        catch (err) {
            dispatch({
                type: FeedbackActionType.ERROR,
                payload: err.message,
            });
        }
    };
};
export const sentFeedback = () => {
    return async (dispatch) => {
        dispatch({
            type: FeedbackActionType.DONE,
        });
    };
};
export const toggleSidebar = (sidebar, trigger = SidebarTriggerTypeEnum.OPEN) => {
    return async (dispatch) => {
        dispatch({
            type: SidebarActionType.TOGGLE,
            payload: {
                type: sidebar,
                trigger: trigger,
            }
        });
    };
};
export const toggledSidebar = () => {
    return async (dispatch) => {
        dispatch({
            type: SidebarActionType.TOGGLED,
        });
    };
};
export const toggleTheme = (theme) => {
    return async (dispatch) => {
        dispatch({
            type: ThemeActionType.TOGGLE,
            payload: theme,
        });
    };
};
export const toggledTheme = () => {
    return async (dispatch) => {
        dispatch({
            type: ThemeActionType.TOGGLED,
        });
    };
};
export const toggleTooltip = (tooltipTrigger, tooltipInfo, tooltipMobile = false, isTouchDevice = false) => {
    return async (dispatch) => {
        dispatch({
            type: TooltipActionType.TOGGLE,
            payload: {
                trigger: tooltipTrigger,
                data: tooltipInfo,
                mobile: tooltipMobile,
                touch: isTouchDevice,
            },
        });
    };
};
export const toggledTooltip = () => {
    return async (dispatch) => {
        dispatch({
            type: TooltipActionType.TOGGLED,
        });
    };
};
