import { HitLevel } from '@shared/interfaces/analyzer';
import { getSessionStorage } from '@utils/storage';
import { HitFilterEnum } from '@shared/enums';
export function updateBlocksAndHits(analyzerResponse, blocks = []) {
    const currentBlocks = [];
    const currentHits = [];
    const globalIgnoreEntries = getSessionStorage('global_ignore_entries') ?
        getSessionStorage('global_ignore_entries', true).map((globalIgnoreEntry) => globalIgnoreEntry.word) : [];
    const blocksEmpty = true;
    blocks.forEach((block) => {
        if (block.blockKey) {
            const blockKey = block.blockKey;
            const matchBlock = analyzerResponse.blocks.find((responseBlock) => {
                if (responseBlock.blockKey == blockKey) {
                    // pop responseBlock from responseBlocks
                    analyzerResponse.blocks = analyzerResponse.blocks.filter((remainingResponseBlock) => {
                        if (remainingResponseBlock.blockKey != responseBlock.blockKey) {
                            return remainingResponseBlock;
                        }
                    });
                    return responseBlock;
                }
            });
            currentBlocks.push(matchBlock ? matchBlock : block);
            // we don't need to extend hits with blockKey, because already done before (last iteration)
            if (globalIgnoreEntries.length > 0) {
                if (matchBlock) {
                    currentHits.push(...matchBlock.hits.map((hit) => {
                        hit.ignore = globalIgnoreEntries.includes(hit.word);
                        return hit;
                    }));
                }
                else {
                    currentHits.push(...block.hits.map((hit) => {
                        hit.ignore = globalIgnoreEntries.includes(hit.word);
                        return hit;
                    }));
                }
            }
            else {
                if (matchBlock) {
                    currentHits.push(...matchBlock.hits);
                }
                else {
                    currentHits.push(...block.hits);
                }
            }
        }
    });
    // if editor was empty before and without blocks (and hits)
    if (blocksEmpty) {
        analyzerResponse.blocks.forEach((responseBlock) => {
            currentBlocks.push(responseBlock);
            if (globalIgnoreEntries.length > 0) {
                currentHits.push(...responseBlock.hits.map((hit) => {
                    hit.ignore = globalIgnoreEntries.includes(hit.word);
                    return hit;
                }));
            }
            else {
                currentHits.push(...responseBlock.hits);
            }
        });
    }
    return { currentBlocks, currentHits };
}
export function checkExistingBlocks(blockKeys, blocks = []) {
    const existingBlocks = [];
    blocks.forEach((block) => {
        if (blockKeys.includes(block.blockKey)) {
            existingBlocks.push(block);
        }
    });
    return existingBlocks;
}
export function filterHits(hits, filter) {
    switch (filter) {
        case HitFilterEnum.IGNORE:
            return hits.filter((hit) => {
                return hit;
            });
        case HitFilterEnum.NEW:
            return hits.filter((hit) => {
                if (hit.level !== HitLevel.NEW)
                    return hit;
            });
        case HitFilterEnum.IGNORE_NEW:
            return hits.filter((hit) => {
                if (hit.level !== HitLevel.NEW && hit.ignore !== true)
                    return hit;
            });
    }
}
export function findHit(hits, by, value) {
    let found_index = 0;
    const foundHit = hits.find((hit, index) => {
        if (hit.hit_id === value) {
            found_index = index;
            return hit;
        }
    });
    return [foundHit, found_index];
}
