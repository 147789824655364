import { SidebarActionType } from '@state/types';
import { SidebarTypeEnum, SidebarTriggerTypeEnum } from '@shared/enums';
const initialState = {
    triggeredSidebar: false,
};
const sidebarReducer = (state = initialState, action = {
    type: SidebarActionType.TOGGLE,
    payload: {
        trigger: SidebarTriggerTypeEnum.OPEN,
        type: SidebarTypeEnum.LEFT
    }
}) => {
    switch (action.type) {
        case SidebarActionType.TOGGLE:
            return { triggeredSidebar: true, typeSidebar: action.payload.type, typeTrigger: action.payload.trigger };
        case SidebarActionType.TOGGLED:
            return { triggeredSidebar: false };
        default:
            return state;
    }
};
export default sidebarReducer;
