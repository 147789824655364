import { createEditor, DecoratorNode } from 'lexical';
function LazyImage({ altText, className, src, width, height, maxWidth, }) {
    const image = new Image();
    image.src = src;
    image.alt = altText;
    if (className)
        image.classList.add(className);
    image.style.width = typeof width === 'number' ? width + 'px' : width;
    image.style.height = typeof height === 'number' ? height + 'px' : height;
    image.style.maxWidth = typeof maxWidth === 'number' ? maxWidth + 'px' : maxWidth;
    image.draggable = true;
    return image;
}
function ImageComponent({ altText, 
// caption,
height, maxWidth, 
// nodeKey,
// resizable,
// showCaption,
src, width, }) {
    const imageFrame = document.createElement('div');
    const draggableImageContainer = document.createElement('div');
    const image = LazyImage({
        altText: altText,
        className: '',
        height: height,
        maxWidth: maxWidth,
        src: src,
        width: width,
    });
    const captionSpan = document.createElement('span');
    captionSpan.style.width = 'auto';
    captionSpan.style.maxWidth = typeof maxWidth === 'number' ? maxWidth + 'px' : maxWidth;
    captionSpan.style.height = '50px';
    captionSpan.style.textAlign = 'center';
    captionSpan.textContent = altText;
    captionSpan.style.marginLeft = '10px';
    captionSpan.style.backgroundColor = 'grey';
    captionSpan.style.fontWeight = 'bold';
    captionSpan.style.color = '#fff';
    captionSpan.style.opacity = '0.8';
    captionSpan.style.position = 'absolute';
    captionSpan.style.left = '0px';
    captionSpan.style.lineHeight = '50px';
    captionSpan.style.padding = '0px 10px';
    draggableImageContainer.appendChild(image);
    draggableImageContainer.appendChild(captionSpan);
    draggableImageContainer.draggable = true;
    imageFrame.appendChild(draggableImageContainer);
    return imageFrame;
}
export class ImageNode extends DecoratorNode {
    __src;
    __altText;
    __width;
    __height;
    __maxWidth;
    __showCaption;
    __caption;
    static getType() {
        return 'image';
    }
    static clone(node) {
        return new ImageNode(node.__src, node.__altText, node.__maxWidth, node.__width, node.__height, node.__showCaption, node.__caption, node.__key);
    }
    static importJSON(serializedNode) {
        const { altText, height, width, maxWidth, caption, src, showCaption } = serializedNode;
        const node = $createImageNode({
            altText,
            height,
            maxWidth,
            showCaption,
            src,
            width,
        });
        const nestedEditor = node.__caption;
        const editorState = nestedEditor.parseEditorState(caption.editorState);
        if (!editorState.isEmpty()) {
            nestedEditor.setEditorState(editorState);
        }
        return node;
    }
    constructor(src, altText, maxWidth, width, height, showCaption, caption, key) {
        super(key);
        this.__src = src;
        this.__altText = altText;
        this.__maxWidth = maxWidth;
        this.__width = width || 'inherit';
        this.__height = height || 'inherit';
        this.__showCaption = showCaption || false;
        this.__caption = caption || createEditor();
    }
    exportJSON() {
        return {
            altText: this.getAltText(),
            caption: this.__caption.toJSON(),
            height: this.__height === 'inherit' ? 0 : this.__height,
            maxWidth: this.__maxWidth,
            showCaption: this.__showCaption,
            src: this.getSrc(),
            type: 'image',
            version: 1,
            width: this.__width === 'inherit' ? 0 : this.__width,
        };
    }
    setWidthAndHeight(width, height) {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }
    setShowCaption(showCaption) {
        const writable = this.getWritable();
        writable.__showCaption = showCaption;
    }
    // View
    createDOM(config) {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        span.appendChild(this.decorate());
        return span;
    }
    updateDOM() {
        return false;
    }
    getSrc() {
        return this.__src;
    }
    getAltText() {
        return this.__altText;
    }
    decorate() {
        return ImageComponent({
            altText: this.__altText,
            caption: this.__caption,
            height: this.__height,
            maxWidth: this.__maxWidth,
            nodeKey: this.getKey(),
            resizable: true,
            showCaption: this.__showCaption,
            src: this.__src,
            width: this.__width,
        });
    }
}
export function $createImageNode({ altText, height, maxWidth = 500, src, width, showCaption, caption, key, }) {
    return new ImageNode(src, altText, maxWidth, width, height, showCaption, caption, key);
}
export function $isImageNode(node) {
    return node instanceof ImageNode;
}
