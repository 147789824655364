import React, { useRef, useState } from 'react';
import { requestContact } from '@utils/contact';
import Caret from '@assets/icons/caret.svg';
import SimpleHeader from '@ui/simple_header/simple_header';
import SimpleTitle from '@ui/simple_title/simple_title';
import SimpleText from '@ui/simple_text/simple_text';
import { useDebounce } from '@utils/use_debounce';
import FinnitWhiteBackground from '@assets/finnit_white_background.svg';
import FinnitDash from '@assets/icons/dash.svg';
import FinnitStar from '@assets/icons/star.svg';
import FinnitTwoPoints from '@assets/icons/two_points.svg';
import './contact.scss';
const Contact = () => {
    const buttonRef = useRef(null);
    const dataPrivacyRef = useRef(null);
    const selectRef = useRef(null);
    const nameRef = useRef(null);
    const mailRef = useRef(null);
    const phoneRef = useRef(null);
    const companyRef = useRef(null);
    const messageRef = useRef(null);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [company, setCompany] = useState('');
    const [dataPrivacy, setDataPrivacy] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [selectActive, setSelectActive] = useState(false);
    const resetContact = () => {
        setMessage('');
        setTitle('');
        setName('');
        setPhone('');
        setMail('');
        setCompany('');
        setDataPrivacy(false);
    };
    const checkRequiredFilled = () => {
        if (title.length > 0 &&
            name.length > 0 &&
            mail.length > 0 &&
            message.length > 0 &&
            dataPrivacy) {
            setSubmitEnabled(true);
            return true;
        }
        else {
            setSubmitEnabled(false);
            return false;
        }
    };
    // eslint-disable-next-line
    const submitContact = (event) => {
        event.preventDefault();
        if (checkRequiredFilled()) {
            requestContact({
                title, name, mail, phone, company, message,
            });
            resetContact();
        }
    };
    // eslint-disable-next-line
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };
    const handleDataPrivacyChange = () => {
        setDataPrivacy(!dataPrivacy);
    };
    const handleKeyUp = (event) => {
        const eventTarget = event.target;
        const eventTargetValue = eventTarget.value;
        switch (eventTarget.name) {
            case 'nachricht':
                setMessage(eventTargetValue);
                break;
            case 'email':
                setMail(eventTargetValue);
                break;
            case 'telefon':
                setPhone(eventTargetValue);
                break;
            case 'firma':
                setCompany(eventTargetValue);
                break;
            case 'name':
                setName(eventTargetValue);
                break;
        }
        checkRequiredFilled();
    };
    const debounceDataPrivacy = useDebounce(dataPrivacy, 50);
    if (debounceDataPrivacy) {
        checkRequiredFilled();
    }
    const debounceTitle = useDebounce(title, 50);
    if (debounceTitle.length > 0) {
        checkRequiredFilled();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "contact_page_div" },
            React.createElement(SimpleHeader, { headingText: "Kontakt" }),
            React.createElement("div", { className: "background xl:pb-32 xss:pb-10" },
                React.createElement("article", { className: "max-w-6xl mx-auto mt-20 content \n\t\t\t\t\t\tflex xl:flex-row xss:flex-col justify-center xl:pb-0 xss:pb-0" },
                    React.createElement("div", { className: "left flex xl:mr-0 \n\t\t\t\t\t\t\txss:justify-center lg:justify-start flex-col\n\t\t\t\t\t\t\t3xl:mt-40 xl:mt-40 lg:mt-20 xss:mt-8 xl:px-0 xss:px-8" },
                        React.createElement("div", { className: "symbol dash 3xl:block xss:hidden mt-[-20px] ml-[-450px]" },
                            React.createElement(FinnitDash, null)),
                        React.createElement(SimpleTitle, { sub: false, text: 'Feedback oder Fragen zu Finnit?', light: true }),
                        React.createElement(SimpleTitle, { sub: false, text: 'Nehmen Sie Kontakt auf' }),
                        React.createElement("div", { className: "xl:max-w-[466px] xss:max-w-full mt-5" },
                            React.createElement(SimpleText, { text: 'Auf Anregungen, Fragen, Feedback oder Meinungen zu Finnit freuen \
									wir uns sehr! Sie können einfach über das Kontaktformular Ihre Nachricht \
									formulieren und verschicken.' })),
                        React.createElement("div", { className: "symbol two_points xss:hidden 3xl:block ml-[-254px] mt-[-25px]" },
                            React.createElement(FinnitTwoPoints, null)),
                        React.createElement("div", { className: "white_background 2xl:block xss:hidden ml-[-141px] 3xl:mt-[-53px] 2xl:mt-10" },
                            React.createElement(FinnitWhiteBackground, null)),
                        React.createElement("div", { className: "symbol dash ml-[-168px] mt-[-130px] mb-5 2xl:block xss:hidden" },
                            React.createElement(FinnitDash, null)),
                        React.createElement("div", { className: "symbol star xss:hidden 3xl:block ml-[-355px]" },
                            React.createElement(FinnitStar, null))),
                    React.createElement("div", { className: "right flex-col xl:max-w-[900px] xss:max-w-full xl:px-0 xss:px-8 flex" },
                        React.createElement("div", { className: "symbol star mt-10 ml-auto mr-[-180px] xss:hidden 2xl:block" },
                            React.createElement(FinnitStar, null)),
                        React.createElement("div", { className: "symbol dash 3xl:block\n\t\t\t\t\t\t\t\t\txss:hidden ml-auto mt-[56px] mr-[-350px]" },
                            React.createElement(FinnitDash, null)),
                        React.createElement("div", { className: "xl:max-w-[564px] xss:max-w-full 2xl:mt-[-35px] xss:mt-10" },
                            React.createElement("form", { id: "contact-form", onKeyUp: handleKeyUp, className: "xs:px-0 xss:px-2" },
                                React.createElement("div", { className: "flex flex-row xl:justify-between" },
                                    React.createElement("label", { ref: selectRef, className: "xl:mr-0 xss:mr-6" },
                                        "Anrede*",
                                        React.createElement("br", null),
                                        React.createElement("div", { className: "contact_select_div flex flex-row" },
                                            React.createElement("select", { onMouseDown: () => setSelectActive(true), onMouseUp: () => setSelectActive(false), onChange: handleTitleChange, value: title, required: true, className: "contact_select w-full" },
                                                React.createElement("option", { value: "" }, "Bitte w\u00E4hlen"),
                                                React.createElement("option", { value: "Frau" }, "Frau"),
                                                React.createElement("option", { value: "Herr" }, "Herr"),
                                                React.createElement("option", { value: "Divers" }, "Divers")),
                                            React.createElement("div", { className: "symbol caret ml-[-20px]" }, selectActive ?
                                                React.createElement(Caret, { className: "open" }) : React.createElement(Caret, { className: "closed" })))),
                                    React.createElement("label", { ref: nameRef, className: "md:w-[416px] xss:w-full" },
                                        "Name*",
                                        React.createElement("br", null),
                                        React.createElement("input", { name: "name", className: "contact_input text-left w-full", type: "text", required: true }))),
                                React.createElement("div", { className: "flex flex-row xl:justify-between" },
                                    React.createElement("label", { ref: mailRef, className: "xl:mr-0 xss:mr-6" },
                                        "E-Mail*",
                                        React.createElement("br", null),
                                        React.createElement("input", { name: "email", className: "contact_input text-left md:w-[272px] xss:w-full", type: "email", 
                                            // eslint-disable-next-line
                                            pattern: "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$/", required: true })),
                                    React.createElement("label", { ref: phoneRef },
                                        "Telefon",
                                        React.createElement("br", null),
                                        React.createElement("input", { name: "telefon", className: "contact_input text-left md:w-[272px] xss:w-full", type: "phone" }))),
                                React.createElement("div", { className: "flex flex-row" },
                                    React.createElement("label", { ref: companyRef },
                                        "Firma/Organisation",
                                        React.createElement("br", null),
                                        React.createElement("input", { name: "firma", className: "contact_input text-left md:w-[564px] xss:w-full", type: "text" }))),
                                React.createElement("label", { ref: messageRef },
                                    "Ihre Nachricht*",
                                    React.createElement("br", null),
                                    React.createElement("textarea", { name: "nachricht", className: "message text-left w-full", placeholder: "Ihre Nachricht ...", required: true })),
                                React.createElement("div", { className: "flex flex-row mt-3 w-full" },
                                    React.createElement("label", { ref: dataPrivacyRef },
                                        React.createElement("input", { type: "checkbox", name: "datenschutz", checked: dataPrivacy, onChange: handleDataPrivacyChange, required: true }),
                                        React.createElement("div", { className: "ml-10 mt-[-26px]" },
                                            "Die Informationen zum\u00A0",
                                            React.createElement("a", { className: "underline", href: "/data_privacy", target: "blank" }, "Datenschutz"),
                                            "\u00A0und zur Verarbeitung meiner personenbezogenen Daten",
                                            React.createElement("br", null),
                                            "habe ich gelesen und bin damit einverstanden."))),
                                React.createElement("div", { className: "flex justify-end mt-10 xl:w-sm xss:w-full" }, submitEnabled ?
                                    React.createElement("button", { ref: buttonRef, onClick: submitContact, className: "button white w-full" }, "Nachricht verschicken")
                                    :
                                        React.createElement("button", { ref: buttonRef, 
                                            // eslint-disable-next-line
                                            onClick: (event) => event.preventDefault(), className: "button white disabled w-full" }, "Nachricht verschicken")))),
                        React.createElement("div", { className: "symbol two_points 3xl:block xss:hidden mt-[-120px] ml-auto mr-[-250px]" },
                            React.createElement(FinnitTwoPoints, null))))))));
};
export default Contact;
